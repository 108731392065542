import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import { Backdrop, Modal, Paper, Zoom } from "@material-ui/core"
import DocumentSection from "../SidePanel/DocumentSection"
import SnackBar from "../SnackBar/SnackBar"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",

    "& .MuiFilledInput-root": {
      borderRadius: "4px",
      height: 45,
      backgroundColor: "#f4f4f4",
    },
    "& .MuiButton-root": {
      height: 45,
      color: "#707070",
      textTransform: "none",
    },
    "& .MuiSelect-root": {
      height: 45,
      color: "#707070",
    },
    "& .MuiInput-root": {
      height: 45,
      margin: "5px 0",
    },
    "& .MuiToggleButtonGroup-root": {
      marginLeft: 20,
    },
    "& .MuiToggleButton-root": {
      border: 0,
      height: "32px",
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: "15px",
      marginRight: "15px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#fff",
      borderBottom: "1px solid",
    },
    "& .MuiTextField-root": {
      // width: '435px'
    },
    "& .MuiButton-containedPrimary": {
      color: "#fff",
    },
    "& .MuiFilledInput-multiline": {
      height: 200,
      display: "flex",
      flexDirection: "column",
      borderRadius: "4px",
      padding: "15px 20px 20px 20px",
      fontSize: "14px",
      // width: '95%'
    },
    "& .MuiInputLabel-filled": {
      "& .Mui-focused": {
        transform: "translate(12px, 15px) scale(1)",
      },
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(12px, 5px) scale(0.75)",
    },
    "& .navIcon": {
      marginTop: "5px",
    },
    "& .MuiContainer-root": {
      padding: 0,
      overflow: "auto",
      // height: "100vh",
      height: "88vh",
      paddingBottom: "50px",
    },
    // Activities Tab list
    "& .MuiList-padding": {
      padding: "8px 40px",
    },
    "& .MuiListItemText-multiline": {
      marginLeft: 10,
      marginTop: 10,
    },
    "& .MuiListItemSecondaryAction-root": {
      top: 39,
      "& .MuiTypography-body1": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    "& .MuiListItem-root": {
      paddingBottom: 15,
      paddingTop: 10,
    },
  },
  paper: {
    width: "50%",
    height: "90%",
    backgroundColor: "#FFF",
    padding: 24,
    // overflow: "scroll",
    "&:focus": {
      outline: "none",
    },
  },
})

class AddRevisionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      message: {
        open: false,
        message: "",
        type: "success",
      },
    }
  }

  setMessage = (message) => {
    // console.log(message)
    // this.handleClose(message.type === "success")
    this.setState({ open: false }, () => {
      this.setState({ message })
    })
  }

  handleCloseSnackbar = (event, reason) => {
    const { message } = this.state
    if (reason === "clickaway") {
      return
    }
    this.setState({
      message: {
        ...message,
        open: false,
      },
    })
  }

  renderSnackBar = () => {
    const { message } = this.state
    return <SnackBar message={message} afterClose={this.handleCloseSnackbar} />
  }

  handleClose = (success = false) => {
    this.setState({ open: false }, () => {
      const { onClose } = this.props
      if (_.isFunction(onClose)) {
        onClose(success)
      }
    })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const { classes, onSuccess, data } = this.props
    const { open } = this.state
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Zoom in={open} exit={!open}>
            <Paper className={classes.paper}>
              {/* <Container> */}
              <DocumentSection
                ref={(o) => {
                  this.DocumentSection = o
                }}
                setMessage={this.setMessage}
                addActivityApi={() => {}}
                setDefaultView={() => {}}
                documentData={data}
                onSuccess={onSuccess}
              />
              {/* </Container> */}
            </Paper>
          </Zoom>
        </Modal>
        {this.renderSnackBar()}
      </>
    )
  }
}

AddRevisionModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

AddRevisionModal.defaultProps = {
  classes: {},
  data: {},
  onClose: () => {},
  onSuccess: () => {},
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AddRevisionModal
  )
)
