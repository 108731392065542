/* eslint-disable no-console */
import React, { useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
// import { useAuth0 } from "@auth0/auth0-react"
import { connect } from "react-redux"
import { useAuth0 } from "../../common/react-auth0-spa"
import { canAccess } from "../../common/authorization"

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, path, roleLevel, ...rest }) => {
  const {
    isLoading,
    // loading,
    isAuthenticated,
    loginWithRedirect,
    // loginWithPopup,
    user,
  } = useAuth0()

  let userCanAccess = false

  if (isAuthenticated && user) {
    try {
      const userRoles = rest.auth.userdata["https://saffronios.com//roles"]
      // Check current user has access based on user role level and level required for component
      // const userRoleLevel = getUserRoleLevel(userRoles[0])
      if (userRoles && userRoles[0]) {
        userCanAccess = canAccess(roleLevel, userRoles[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log("isLoading", isLoading)
    console.log("isAuthenticated", isAuthenticated)
    if (isLoading || isAuthenticated) {
      return
    }
    const fn = async () => {
      console.log("fn called", path)
      console.log("fn called window", window.location.pathname)
      try {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        })
      } catch (error) {
        console.log("fn error", error)
      }
    }
    fn()
  }, [isLoading, isAuthenticated, loginWithRedirect, path])

  const render = (props) => {
    // console.log("_role_render_props", Component)
    const authenticated =
      isAuthenticated === true ? <Component {...props} /> : null
    // if (authenticated === null) {
    //   return null
    // }
    if (userCanAccess) {
      return authenticated
    }
    // console.log("render private route method")
    return <Redirect to="/denied" />
  }
  console.log("user", user)
  console.log("isAuthenticated", isAuthenticated)
  return <Route path={path} render={render} {...rest} />
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
