export const getUserRoleLevel = (userRole) => {
  switch (userRole) {
    case "super-admin":
      return 1
    // break
    case "admin":
      return 2
    // break
    case "user-admin":
      return 3
    // break
    case "user":
      return 4
    // break
    case "read-only-user":
    case "client-user":
      return 5
    // break
    case "client-admin":
      return 6
    // break
    default:
      return 5
    // code block
  }
}

export const canAccess = (sectionLevel, userRole) => {
  const userRoleLevel = getUserRoleLevel(userRole)
  if (userRoleLevel <= sectionLevel) {
    return true
  }
  return false
}
