import React, { Component } from "react"
import PropTypes from "prop-types"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Checkbox from "@material-ui/core/Checkbox"

class EnhancedTableHead extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props
    onRequestSort(event, property)
  }

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      headCells,
      checkboxes,
    } = this.props
    return (
      <TableHead style={{ boxShadow: "0px 0px 6px #00000029" }}>
        <TableRow>
          {checkboxes ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell>
          ) : null}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                headCell.width ? { width: `${headCell.width}%` } : undefined
              }
              // hideSortIcon={!headCell.label || headCell.label === ""}
            >
              {headCell.label && headCell.label !== "" ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  // active
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={this.createSortHandler(headCell.id)}
                  style={{
                    textTransform: "uppercase",
                    fontSize: 12,
                    color: "#747474",
                    whiteSpace: "nowrap",
                  }}
                  hideSortIcon={!headCell.label || headCell.label === ""}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkboxes: PropTypes.bool.isRequired,
}

export default EnhancedTableHead
