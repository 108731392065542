const styles = (theme) => ({
  alertRoot: {
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 16px #00000033",
    backgroundColor: "#FFF",
    fontSize: 18,
    color: "#2F2F2F",
  },
  snackbar: {
    // width: "80%",
    left: 262,
  },
  typeIcon: {
    height: 40,
    width: 40,
  },
  success: {
    color: "#6DA952",
  },
  warning: {
    color: "#EEC04B",
  },
  info: {
    color: "#02F",
  },
  error: {
    color: "#F00",
  },
})

export default styles
