import { lazy } from "react"
// import { PUBLIC_ROUTE } from "./route.constants"
// import DashBoardIcon from "../../components/NavIcon/DashBoardIcon"

// Features Configuration
const isAdmin = process.env.REACT_APP_ADMIN_ACTIVE === "true"
const isClientPortal = process.env.REACT_APP_CLIENT_PORTAL_ACTIVE === "true"

// export const publicRoutes = [
//   {
//     path: PUBLIC_ROUTE.LANDING,
//     exact: true,
//     component: lazy(() => import("../Dashboard/Dashboard")),
//     name: "Landing",
//   },
// ]

export const dashboardRoutes = [
  {
    id: 1,
    path: "",
    component: lazy(() => import("../Dashboard/DashboardView")),
    exact: true,
    drawer: true,
    name: "Dashboards",
    roleLevel: 6,
    icon: "dashboard",
    parentId: null,
    expand: true,
  },
  {
    id: 2,
    path: "dashboard/programmes",
    exact: true,
    component: lazy(() => import("../ProgramDashboard/index")),
    name: "Programmes",
    drawer: true,
    sub: true,
    roleLevel: 6,
    icon: "programmeDashboardIconSub",
    parentId: 1,
  },
  {
    id: 3,
    path: "dashboard",
    component: lazy(() => import("../Dashboard/DashboardView")),
    exact: true,
    drawer: false,
    name: "Dashboards",
    roleLevel: 6,
    icon: "nav",
    parentId: null,
  },
  {
    id: 4,
    path: "dashboard/programmes",
    exact: true,
    component: lazy(() => import("../ProgramDashboard/index")),
    name: "Programmes",
    drawer: false,
    roleLevel: 6,
    icon: "programmeDashboardIconSub",
    parentId: 1,
  },
  {
    id: 5,
    path: "dashboard/projects",
    component: lazy(() => import("../Dashboard/ProjectDashboard")),
    exact: true,
    drawer: true,
    sub: true,
    name: "Projects",
    roleLevel: 6,
    icon: "projectIconSub",
    parentId: 1,
  },
  {
    id: 6,
    path: "notifications",
    exact: true,
    drawer: true,
    component: lazy(() => import("../Notifications/index")),
    name: "Notifications",
    roleLevel: 6,
    icon: "notifications",
    parentId: null,
  },
  {
    id: 7,
    path: "my-tasks",
    exact: true,
    drawer: true,
    component: lazy(() => import("../MyTasks/MyTasks")),
    name: "My Tasks",
    roleLevel: 6,
    icon: "tasks",
    parentId: null,
  },
  {
    id: 8,
    path: "dashboard/project/:projectId/task/:taskId?",
    exact: true,
    component: lazy(() => import("../ProjectDetail/ProjectDetail")),
    name: "project task",
    roleLevel: 6,
    icon: "nav",
    parentId: 1,
  },
]

export const adminRoutes = [
  {
    id: 9,
    path: "manage",
    // component: lazy(() => import("../Programs/index")),
    exact: true,
    drawer: true,
    name: "Admin",
    roleLevel: 2,
    icon: "settings",
    set: true,
    disable: true,
    parentId: null,
  },
  {
    id: 10,
    path: "manage/programmes",
    component: lazy(() => import("../Programs/index")),
    exact: true,
    drawer: true,
    name: "Programmes",
    roleLevel: 2,
    sub: true,
    icon: "programmeDashboardIconSub",
    parentId: 9,
  },
  {
    id: 11,
    path: "programme/:programmeId/projects",
    component: lazy(() => import("../ProgramProjectList/index")),
    exact: true,
    drawer: false,
    name: "program_name",
    roleLevel: 2,
    icon: "nav",
    parentId: 9,
  },
  {
    id: 12,
    path: "manage/projects",
    exact: true,
    drawer: true,
    // component: lazy(() => import("../Projects/Projects")),
    component: lazy(() => import("../SiteAdmin/Projects/index")),
    name: "Projects",
    roleLevel: 2,
    sub: true,
    icon: "projectIconSub",
    parentId: 9,
  },
  {
    id: 13,
    path: "dashboard/project/:projectId",
    exact: true,
    component: lazy(() => import("../ProjectDetail/ProjectDetail")),
    name: "project_name",
    roleLevel: 2,
    icon: "nav",
    parentId: 9,
  },
  {
    id: 14,
    path: "dashboard/programmes/:programId",
    exact: true,
    component: lazy(() => import("../ProgramDashboard/ProjectDashboard")),
    name: "program_name",
    drawer: false,
    roleLevel: 2,
    icon: "nav",
    parentId: 9,
  },
  {
    id: 15,
    path: "programme/create",
    component: lazy(() => import("../Programs/index")),
    exact: true,
    drawer: false,
    name: "Programmes",
    roleLevel: 2,
    icon: "nav",
    parentId: 9,
  },
]

export const clientPortalRoutes = [
  {
    id: 16,
    path: "client-portal",
    exact: true,
    drawer: true,
    component: lazy(() => import("./PRRedirect")),
    name: "Client Portal",
    admin: true,
    roleLevel: 3,
    icon: "portal",
    parentId: null,
  },
]

export const extraRoutes = [
  {
    id: 17,
    path: "admin",
    exact: true,
    component: lazy(() => import("../SiteAdmin/Projects/index")),
    name: "Project list",
    roleLevel: 2,
    icon: "nav",
    parentId: null,
  },
  {
    id: 18,
    path: "admin/create-project",
    exact: true,
    component: lazy(() => import("../SiteAdmin/Projects/Create")),
    name: "Create project",
    roleLevel: 2,
    icon: "nav",
    parentId: null,
  },
  {
    id: 19,
    path: "admin/project/:projectId",
    exact: true,
    component: lazy(() => import("../Tasks/index")),
    name: "Task management",
    roleLevel: 2,
    icon: "nav",
    parentId: null,
  },
  {
    id: 20,
    path: "ganttpoc",
    exact: true,
    component: lazy(() => import("../Gantt/DashboardView")),
    name: "Gantt POC",
    icon: "nav",
    parentId: null,
  },
  {
    id: 21,
    path: "docs",
    exact: true,
    component: lazy(() =>
      import("../../components/DocumentViewer/DocumentViewer")
    ),
    name: "Doc Viewer",
    icon: "nav",
    parentId: null,
  },
]

let routes = [...dashboardRoutes]

if (isAdmin) {
  routes = routes.concat(adminRoutes)
}

if (isClientPortal) {
  routes = routes.concat(clientPortalRoutes)
}

if (process.env.NODE_ENV === "development") {
  routes = routes.concat(extraRoutes)
}

export const privateRoutes = routes
