import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
// import FileIcon from "react-file-icon"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"
// import moment from "moment"
import { ListItem, ListItemText, Tooltip } from "@material-ui/core"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import documentActions from "../../redux/reducers/document/actions"
import styles from "./SidePanel.styles"
import Table from "../TableComponent/Table"
// import { apiCall } from "../../common/apiHelper"
// import settings from "../../config/settings"
import TimeAgo from "../TimeAgo/TimeAgo"
import FileIcon from "../FileIcon/FileIcon"
import DocumentActions from "../DocumentActions/DocumentActions"
import VersionMenu from "../VersionMenu/VersionMenu"
import DocumentStatus from "../StatusChip/DocumentStatus"
import { getDocumentName } from "../../common/utils"

class DocumentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // loading: true,
      // documents: [],
      columns: [
        {
          id: "path",
          numeric: false,
          disablePadding: true,
          label: "",
          sidebar: true,
        },
        {
          id: "name",
          numeric: false,
          disablePadding: true,
          label: "NAME",
          sidebar: true,
        },
        {
          id: "type",
          numeric: false,
          disablePadding: true,
          label: "DOCUMENT TYPE",
          sidebar: false,
        },
        // {
        //   id: "version",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "Version",
        //   sidebar: false,
        // },
        {
          id: "updated_at",
          numeric: false,
          disablePadding: false,
          label: "UPDATED",
          sidebar: false,
        },
        {
          id: "status",
          numeric: false,
          disablePadding: false,
          label: "STATUS",
          sidebar: false,
        },
        // {
        //   id: "action",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "Action",
        //   sidebar: true,
        // },
      ],
    }
  }

  componentDidMount() {
    this.getDocuments()
    this.getDocumentStatusList()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (!_.isEqual(task.task, nextTask.task) && nextTask.open) {
      if (nextTask.task) {
        setTimeout(() => {
          this.getDocuments()
        }, 100)
      }
    }
  }

  getDocumentStatusList = () => {
    const {
      taskActions: { getDocumentStatusList },
    } = this.props
    getDocumentStatusList()
  }

  getDocuments = async () => {
    const {
      // auth,
      // apiActions: { apiCall },
      documentActions: { getTaskDocuments },
      // task: { task },
    } = this.props
    getTaskDocuments()
    // this.setState({ loading: true })
    // try {
    //   // const headers = {
    //   //   "content-type": "application/json",
    //   //   Authorization: `Bearer ${auth.token}`,
    //   // }
    //   const reqdata = {
    //     item_id: task ? task.id : 0,
    //     skip: 0,
    //     limit: 0,
    //   }
    //   const responseJson = await apiCall(
    //     settings.endpoints.getDocumentByTask(task ? task.id : 0),
    //     "post",
    //     reqdata
    //     // headers
    //   )
    //   let documents = []
    //   if (responseJson && responseJson.success) {
    //     documents = responseJson.data
    //   }
    //   this.setState({ loading: false, documents })
    // } catch (error) {
    //   // eslint-disable-next-line no-console
    //   console.log(error)
    //   this.setState({ loading: false })
    // }
  }

  renderCell = (data) => {
    const {
      row,
      column: { id },
    } = data
    const {
      setMessage,
      task: { documentTypes },
    } = this.props
    if (id === "path") {
      // return
      return <FileIcon fileData={row} />
    }

    if (id === "action") {
      return (
        <DocumentActions
          key={`version_info_${row.id}`}
          data={row}
          onSuccess={this.getDocuments}
          setMessage={setMessage}
        />
      )
    }

    if (id === "status") {
      // console.log('id === "status"', row)
      let status = 0
      // console.log("row.revisions", row.revisions)
      if (_.isArray(row.revisions)) {
        const sObj = _.find(
          row.revisions,
          (i) => i.document_id === row.document_id
        )
        // console.log("sObj", sObj)
        if (sObj) {
          status = sObj.status_id
        }
      }
      return <DocumentStatus status={status} />
    }

    if (id === "version") {
      return <VersionMenu key={`version_info_${row.id}`} data={row} />
    }

    if (id === "name") {
      // let extension = row[id].substring(row[id].lastIndexOf(".") + 1)
      // if (extension === "jpeg" || extension === "JPEG") {
      //   extension = "jpg"
      // }
      const documentName = getDocumentName(row)
      // path
      return (
        <ListItem disableGutters>
          {/* <ListItemAvatar>
            <FileIcon extension={extension} size={50} />
          </ListItemAvatar> */}
          <ListItemText
            // primary={documentName}
            primary={
              <Tooltip title={row[id]}>
                <Typography style={{ display: "inline-flex" }}>
                  {documentName}
                </Typography>
              </Tooltip>
            }
            secondary={
              <>
                <Typography
                  variant="body2"
                  style={{ fontSize: 12 }}
                  color="textSecondary"
                >
                  {row.description}
                </Typography>
              </>
            }
          />
        </ListItem>
      )
    }

    if (id === "updated_at") {
      return <TimeAgo time={row[id]} style={{ fontSize: 12 }} />
    }

    if (id === "type") {
      const typeObj = _.find(documentTypes, (i) => i.id === row.type)
      if (typeObj) {
        return typeObj.name
      }
      return "Unknown"
    }
    return row[id]
  }

  onRowClick = (event, row) => {
    // window.open(row.path, "_blank")
    const { onRowClick } = this.props
    if (onRowClick) {
      onRowClick(row)
    }
  }

  render() {
    const {
      classes,
      document: { taskDocumentLoad, taskDocuments, taskDocumentsError },
    } = this.props
    const { columns } = this.state
    return (
      <Box display="flex" flexDirection="column">
        <Table
          classes={{ root: classes.documentListTable }}
          checkboxes={false}
          filter={false}
          rows={taskDocuments}
          columns={columns}
          dense
          panel
          loadingError={
            taskDocumentsError && taskDocumentsError !== ""
              ? taskDocumentsError
              : "No Documents found"
          }
          loading={taskDocumentLoad}
          renderCell={this.renderCell}
          onRowClick={this.onRowClick}
        />
      </Box>
    )
  }
}

DocumentList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  // apiActions: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func,
  onRowClick: PropTypes.func,
}

DocumentList.defaultProps = {
  classes: {},
  task: {},
  document: {},
  // auth: {},
  // authActions: {},
  taskActions: {},
  // apiActions: {},
  documentActions: {},
  setMessage: () => {},
  onRowClick: () => {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
  document: state.document,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DocumentList
  )
)
