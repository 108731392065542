import actions from "./actions"
import { defaultFilters } from "../../../common/utils"

const initialState = {
  open: false,
  task: null,
  project: {},
  taskLoad: false,
  taskList: [],
  taskError: "",
  statusList: [],
  assigneeList: [],
  parentTaskList: [],
  clientAssigneeList: [],
  documentTypes: [],
  dependencyTypeList: [],
  filterVisible: false,
  filterObj: defaultFilters,
  myTasksLoad: false,
  myTasks: [],
  myTasksError: "",
  ragStatusList: [],
  taskPriorityList: [],

  overdueTaskLoad: false,
  overdueTaskList: [],
  overdueTaskError: "",

  projectStatusList: [],
  documentStatusList: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_OPEN:
      return {
        ...state,
        open: action.open,
      }

    case actions.SET_TASK:
      return {
        ...state,
        task: action.task,
      }

    case actions.SET_PROJECT:
      return {
        ...state,
        project: action.project,
      }

    case actions.SET_ASSIGNEE_LIST:
      return {
        ...state,
        assigneeList: action.assigneeList,
      }

    case actions.SET_STATUS_LIST:
      return {
        ...state,
        statusList: action.statusList,
      }

    case actions.SET_PROJECT_STATUS_LIST:
      return {
        ...state,
        projectStatusList: action.projectStatusList,
      }

    case actions.SET_PARENT_TASK_LIST:
      return {
        ...state,
        parentTaskList: action.parentTaskList,
      }

    case actions.SET_TASK_LIST:
      return {
        ...state,
        taskList: action.taskList,
      }

    case actions.SET_TASK_ERROR:
      return {
        ...state,
        taskError: action.taskError,
      }

    case actions.SET_TASK_LOADING:
      return {
        ...state,
        taskLoad: action.taskLoad,
      }

    case actions.SET_OVERDUE_TASK_LIST:
      return {
        ...state,
        overdueTaskList: action.overdueTaskList,
      }

    case actions.SET_OVERDUE_TASK_ERROR:
      return {
        ...state,
        overdueTaskError: action.overdueTaskError,
      }

    case actions.SET_OVERDUE_TASK_LOADING:
      return {
        ...state,
        overdueTaskLoad: action.overdueTaskLoad,
      }

    case actions.SET_FILTER_VISIBLE:
      return {
        ...state,
        filterVisible: action.filterVisible,
      }

    case actions.SET_FILTER_DATA:
      return {
        ...state,
        filterObj: action.filterObj,
      }

    case actions.SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.documentTypes,
      }

    case actions.SET_DEPENDENCY_TYPE_LIST:
      return {
        ...state,
        dependencyTypeList: action.dependencyTypeList,
      }

    case actions.SET_MY_TASKS_LIST:
      return {
        ...state,
        myTasks: action.myTasks,
      }

    case actions.SET_MY_TASKS_ERROR:
      return {
        ...state,
        myTasksError: action.myTasksError,
      }

    case actions.SET_MY_TASKS_LOADING:
      return {
        ...state,
        myTasksLoad: action.myTasksLoad,
      }

    case actions.SET_CLIENT_ASSIGNEE_LIST:
      return {
        ...state,
        clientAssigneeList: action.clientAssigneeList,
      }

    case actions.SET_PROJECT_RAG_STATUS_LIST:
      return {
        ...state,
        ragStatusList: action.ragStatusList,
      }

    case actions.SET_TASK_PRIORITY_LIST:
      return {
        ...state,
        taskPriorityList: action.taskPriorityList,
      }

    case actions.SET_DOCUMENT_STATUS_LIST:
      return {
        ...state,
        documentStatusList: action.documentStatusList,
      }

    default:
      return state
  }
}
