const styles = (theme) => ({
  container: {
    position: "absolute",
    left: 20,
    bottom: 20,
  },
  light: {
    fontSize: 12,
    color: "#2F2F2F",
    opacity: 0.6,
  },
  main: {
    fontSize: 20,
    color: "#2F2F2F",
  },
})

export default styles
