import actions from "./actions"

const initialState = {
  document: null,
  taskDocumentLoad: false,
  taskDocuments: [],
  taskDocumentsError: "",
  projectDocumentLoad: false,
  projectDocuments: [],
  projectDocumentsError: "",
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      }

    case actions.GET_TASK_DOCUMENTS:
      return {
        ...state,
        taskDocumentLoad: true,
        taskDocuments: [],
        taskDocumentsError: "",
      }

    case actions.GET_TASK_DOCUMENTS_SUCCESS:
      return {
        ...state,
        taskDocumentLoad: false,
        taskDocuments: action.payload,
        taskDocumentsError: "",
      }

    case actions.GET_TASK_DOCUMENTS_FAIL:
      return {
        ...state,
        taskDocumentLoad: false,
        taskDocuments: [],
        taskDocumentsError: action.payload,
      }

    case actions.GET_PROJECT_DOCUMENTS:
      return {
        ...state,
        projectDocumentLoad: true,
        projectDocuments: [],
        projectDocumentsError: "",
      }

    case actions.GET_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        projectDocumentLoad: false,
        projectDocuments: action.payload,
        projectDocumentsError: "",
      }

    case actions.GET_PROJECT_DOCUMENTS_FAIL:
      return {
        ...state,
        projectDocumentLoad: false,
        projectDocuments: [],
        projectDocumentsError: action.payload,
      }

    default:
      return state
  }
}
