import React, { Component } from "react"
import { Document, Page } from "react-pdf"
import _ from "lodash"
import PropTypes from "prop-types"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"

const styles = (theme) => ({
  page: {
    boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.25)",
    marginBottom: theme.spacing(5),
  },
  loadingView: {
    height: "80vh",
    width: "100%",
  },
})

class AllPages extends Component {
  constructor(props) {
    super(props)
    this.contView = React.createRef()
    this.documentContainer = React.createRef()
    this.state = {
      tWidth: 0,
      numPages: null,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { pdf } = this.props
    if (!_.isEqual(nextProps.pdf, pdf)) {
      return true
    }
    if (!_.isEqual(nextState, this.state)) {
      return true
    }
    return false
  }

  updateSize = () => {
    const { tWidth } = this.state
    // if (this.contView && this.contView.current) {
    //   // eslint-disable-next-line react/no-find-dom-node
    //   const node = ReactDOM.findDOMNode(this.contView.current)
    //   if (!node) return
    //   const nWidth = node.getBoundingClientRect().width
    //   if (nWidth !== tWidth) {
    //     this.setState({ tWidth: nWidth })
    //   }
    // }

    if (this.documentContainer && this.documentContainer.current) {
      const nWidth = this.documentContainer.current.getBoundingClientRect()
        .width
      if (nWidth !== tWidth) {
        this.setState({ tWidth: nWidth })
      }
    }
  }

  renderLoading = () => {
    const { classes } = this.props
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loadingView}
      >
        <CircularProgress size={50} />
      </Grid>
    )
  }

  renderError = () => {
    const { classes } = this.props
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loadingView}
      >
        <Typography variant="body1">Failed to load Document</Typography>
      </Grid>
    )
  }

  onDocumentLoadSuccess = ({ numPages: v }) => {
    // this.setNumPages(v)
    this.setState({ numPages: v })
    this.updateSize()
  }

  render() {
    const { classes } = this.props
    const { pdf } = this.props
    const { numPages, tWidth } = this.state
    // console.log("render called")
    return (
      <Document
        file={pdf}
        ref={this.contView}
        inputRef={this.documentContainer}
        loading={this.renderLoading}
        error={this.renderError}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={this.onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => {
          return (
            <Page
              width={tWidth}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className={classes.page}
            />
          )
        })}
      </Document>
    )
  }
}

AllPages.propTypes = {
  pdf: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any),
}

AllPages.defaultProps = {
  classes: {},
}

export default withStyles(styles)(AllPages)
