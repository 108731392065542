import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import MomentUtils from "@date-io/moment"
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import authActions from "../../redux/reducers/auth/actions"
import styles from "./DateTimePicker.styles"

class DateTimePickerComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { classes, ...rest } = this.props
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDateTimePicker
          {...rest}
          disableMinutes
          autoOk
          InputProps={{
            disableUnderline: true,
            fullWidth: true,
            classes,
          }}
          minutesStep={60}
          keyboardIcon={
            <CalendarTodayIcon style={{ fill: "", height: 18, width: 18 }} />
          }
          ToolbarComponent={() => null}
          // disableToolbar
          // hideTabs
          variant="inline"
          PopoverProps={{
            className: classes.popover,
          }}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

DateTimePickerComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  authActions: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
}

DateTimePickerComponent.defaultProps = {
  classes: {},
  authActions: {},
  auth: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DateTimePickerComponent
  )
)
