import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import CircularProgress from "@material-ui/core/CircularProgress"
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import withStyles from "@material-ui/styles/withStyles"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
import settings from "../../config/settings"
import TimeAgo from "../TimeAgo/TimeAgo"
import { getLocalMoment } from "../../common/utils"

class CommentSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      perpage: 5,
      page: 1,
      comments: [],
      more: true,
    }
  }

  componentDidMount() {
    this.getAllComment()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (!_.isEqual(task.task, nextTask.task) && nextTask.open) {
      if (nextTask.task) {
        this.getAllComment(nextTask.task)
      }
    }
  }

  onLoadMore = () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, this.getAllComment)
  }

  getAllComment = async (nextTask = null) => {
    const {
      // auth,
      apiActions: { apiCall },
      task: { task: currentTask },
    } = this.props
    const { perpage, page } = this.state
    let task = currentTask
    if (nextTask) {
      task = nextTask
    }
    if (!task || !task.id) {
      return
    }
    this.setState({ loading: true })
    try {
      const reqdata = {
        project_task_id: task.id,
        type: "comment",
        skip: (page - 1) * perpage,
        limit: perpage,
      }
      const responseJson = await apiCall(
        settings.endpoints.getAllComment(task.id),
        "post",
        reqdata
      )
      let { more, comments } = this.state
      if (responseJson && responseJson.success) {
        comments = comments.concat(responseJson.data)
        const sorted = _.reverse(
          _.sortBy(comments, (i) => getLocalMoment(i.created_at).unix())
        )
        comments = sorted
        more = responseJson.data && responseJson.data.length
      }
      this.setState({ loading: false, comments, more })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      this.setState({ loading: false })
    }
  }

  splitStringByUsername = (metadata) => {
    let message = ""
    const parts = []
    if (metadata) {
      if (metadata.palainText) {
        message = metadata.palainText
      }
      if (_.isArray(metadata.mentions)) {
        // eslint-disable-next-line array-callback-return
        metadata.mentions.map((ele, ii) => {
          const username = ele.display
          const nextItemIndex = metadata.mentions[ii + 1]
            ? metadata.mentions[ii + 1].plainTextIndex
            : message.length
          const text = message.substring(
            ele.plainTextIndex + username.length,
            nextItemIndex
          )
          parts.push({ text: `${username}`, color: "#436EE0" })
          parts.push({ text })
        })
      }
    }
    return parts
  }

  render() {
    const { classes } = this.props
    const { comments, loading, more } = this.state

    // if (loading) {
    //   return (
    //     <Grid
    //       container
    //       alignItems="center"
    //       justify="center"
    //       style={{ minHeight: 250 }}
    //     >
    //       <CircularProgress size={50} />
    //     </Grid>
    //   )
    // }

    if (!comments || comments.length === 0) {
      return (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: 300 }}
        >
          <Typography style={{ fontSize: 14 }}>No comments yet</Typography>
        </Grid>
      )
    }
    return (
      <List key="comment_section_list">
        {comments.length > 0 &&
          comments.map((ele, index) => {
            const userName = ele.updated_by_name || ""
            const avatarInitials = userName
              .split(/\s/)
              // eslint-disable-next-line no-return-assign
              .reduce((response, word) => (response += word.slice(0, 1)), "")
            // console.log("comments ele", ele)
            const parts = this.splitStringByUsername(ele.metadata)
            let { message } = ele
            if (ele.metadata && ele.metadata.palainText) {
              message = ele.metadata.palainText
            }
            // console.log("comments ele", parts)
            return [
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${ele.id}_${index}`}
                alignItems="flex-start"
                disableGutters
              >
                <ListItemAvatar className={classes.CommentListItemAvatar}>
                  <Avatar
                    alt={ele.updated_by_name || ""}
                    src="/static/images/avatar/1.jpg"
                    className={classes.CommentUserAvatar}
                  >
                    {avatarInitials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // primary={ele.updated_by_name || ""}
                  primary={
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.CommentTextPrimaryGrid}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.CommentTextPrimary}
                        color="textPrimary"
                      >
                        {ele.updated_by_name || ""}
                      </Typography>
                      <TimeAgo
                        time={ele.created_at}
                        format="DD/MM/YYYY hh:mm A"
                        style={{ fontSize: 12, marginLeft: 10 }}
                      />
                    </Grid>
                  }
                  classes={{
                    multiline: classes.CommentTextMultiline,
                    primary: classes.CommentTextPrimary,
                    root: classes.CommentTextRoot,
                    secondary: classes.CommentTextSecondary,
                  }}
                  secondary={
                    <>
                      {parts.length ? (
                        parts.map((ee, o) => {
                          const colorStyle = ee.color ? { color: ee.color } : {}
                          return (
                            <Typography
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${ee.text}_${o}`}
                              component="span"
                              variant="body2"
                              className={classes.block}
                              color="textSecondary"
                              style={colorStyle}
                            >
                              {`${ee.text || ""}`}
                            </Typography>
                          )
                        })
                      ) : (
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.block}
                          color="textSecondary"
                        >
                          {`${message || ""}`}
                        </Typography>
                      )}
                    </>
                  }
                />
                {/* <ListItemSecondaryAction
                  className={classes.CommentListItemSecondary}
                >
                  <TimeAgo time={ele.created_at} style={{ fontSize: 12 }} />
                </ListItemSecondaryAction> */}
              </ListItem>,
              <Divider component="li" />,
            ]
          })}
        {loading && (
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: 300 }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        {!loading && more && comments && comments.length > 0 ? (
          <Grid container alignItems="center" justify="center">
            <Button
              color="inherit"
              href="#"
              onClick={this.onLoadMore}
              className={classes.loadMoreText}
            >
              Load More Comments
            </Button>
          </Grid>
        ) : null}
      </List>
    )
  }
}

CommentSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
}

CommentSection.defaultProps = {
  classes: {},
  task: {},
  // auth: {},
  // authActions: {},
  // taskActions: {},
  apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CommentSection)
)
