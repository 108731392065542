import types from './actions';

const initialState = {
  token: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_JWT_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    default:
      return state;
  }
}
