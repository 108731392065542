import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Typography } from "@material-ui/core"
import { fromNow, formatTime } from "../../common/utils"

class TimeAgo extends PureComponent {
  render() {
    const { time, format, ...rest } = this.props
    if (format) {
      return <Typography {...rest}>{formatTime(time, format)}</Typography>
    }
    return <Typography {...rest}>{fromNow(time)}</Typography>
  }
}

TimeAgo.propTypes = {
  time: PropTypes.string.isRequired,
  format: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

TimeAgo.defaultProps = {
  format: false,
}

export default TimeAgo
