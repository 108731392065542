import React from "react"

const PortalIcon = ({
  style = {},
  fill = "rgba(0, 0, 0, 0.45)",
  width = "100%",
  className = "navIcon",
  height = "100%",
  viewBox = "0 0 100 100",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 110 95"
      xmlSpace="preserve"
      style={{ height: 40, width: 40, ...style }}
      fill={fill}
    >
      <path d="M50.1787109,22.6738281c3.4951172,0,6.3476562-2.8203125,6.3662109-6.3037109  c0.0087891-1.6933594-0.6455078-3.2880859-1.8432617-4.4921875c-1.1958008-1.2011719-2.7905273-1.8681641-4.4897461-1.8769531  c-0.012207,0-0.0244141,0-0.0366211,0c-1.6855469,0-3.2729492,0.6484375-4.4731445,1.8291016  c-1.2099609,1.1914062-1.8813477,2.7792969-1.8901367,4.4736328c-0.0087891,1.6933594,0.6455078,3.2890625,1.8427734,4.4921875  c1.1958008,1.2021484,2.7905273,1.8691406,4.4897461,1.8779297C50.15625,22.6738281,50.1674805,22.6738281,50.1787109,22.6738281z   M45.8120117,16.3134766c0.0063477-1.1572266,0.465332-2.2431641,1.2929688-3.0576172  c0.8232422-0.8095703,1.9125977-1.2548828,3.0708008-1.2548828c0.0087891,0,0.0170898,0,0.0253906,0  c1.1674805,0.0058594,2.2626953,0.4638672,3.0830078,1.2880859c0.8193359,0.8232422,1.2670898,1.9140625,1.2607422,3.0712891  c-0.012207,2.390625-2.0019531,4.3544922-4.3896484,4.3134766c-1.1674805-0.0058594-2.262207-0.4638672-3.0825195-1.2880859  C46.253418,18.5625,45.8056641,17.4716797,45.8120117,16.3134766z" />
      <path d="M29.3012695,29.2880859c0.1694336,0,0.3417969-0.0429688,0.4990234-0.1337891l12.6821289-7.3222656  c0.4785156-0.2763672,0.6420898-0.8876953,0.3662109-1.3662109c-0.2773438-0.4785156-0.8886719-0.6435547-1.3662109-0.3662109  L28.800293,27.421875c-0.4785156,0.2763672-0.6420898,0.8876953-0.3662109,1.3662109  C28.6196289,29.109375,28.9555664,29.2880859,29.3012695,29.2880859z" />
      <path d="M15.465332,29.8164062c-1.7714844,3.03125-0.7578125,6.9316406,2.2583008,8.6943359  c0.9794922,0.5722656,2.0698242,0.8652344,3.1757812,0.8652344c0.5454102,0,1.0947266-0.0712891,1.6367188-0.2148438  c1.6386719-0.4345703,3.0131836-1.4824219,3.8706055-2.9492188c1.7709961-3.03125,0.7583008-6.9316406-2.2578125-8.6943359  C21.1303711,25.7529297,17.2363281,26.7851562,15.465332,29.8164062z M20.9697266,28.65625  c0.7392578,0,1.487793,0.1894531,2.1694336,0.5878906c2.0644531,1.2070312,2.7553711,3.8798828,1.5410156,5.9580078  c-0.5888672,1.0078125-1.5327148,1.7275391-2.6572266,2.0253906c-1.1191406,0.2988281-2.2895508,0.140625-3.2900391-0.4433594  c-2.0644531-1.2070312-2.7558594-3.8798828-1.5410156-5.9580078C18.0058594,29.4335938,19.4702148,28.65625,20.9697266,28.65625z" />
      <path d="M19.847168,42.4267578v14.6435547c0,0.5527344,0.4477539,1,1,1s1-0.4472656,1-1V42.4267578c0-0.5527344-0.4477539-1-1-1  S19.847168,41.8740234,19.847168,42.4267578z" />
      <path d="M15.253418,69.8154297c0.8417969,1.4765625,2.2055664,2.5390625,3.8398438,2.9912109  c0.5625,0.1552734,1.1333008,0.2324219,1.7006836,0.2324219c1.0830078,0,2.1523438-0.28125,3.1181641-0.8310547  c1.4707031-0.8398438,2.5253906-2.2041016,2.96875-3.8427734c0.4428711-1.6367188,0.2231445-3.3515625-0.6186523-4.8271484  C25.4204102,62.0615234,24.0566406,61,22.4228516,60.5478516c-1.6376953-0.4550781-3.3486328-0.2402344-4.8193359,0.5986328  c-1.4707031,0.8398438-2.5253906,2.2041016-2.96875,3.8427734C14.1918945,66.625,14.4116211,68.3398438,15.253418,69.8154297z   M16.5654297,65.5117188c0.3032227-1.1210938,1.0239258-2.0537109,2.0292969-2.6279297  c0.659668-0.3759766,1.3901367-0.5683594,2.1308594-0.5683594c0.3881836,0,0.7788086,0.0527344,1.1635742,0.1591797  c1.1210938,0.3105469,2.0571289,1.0400391,2.6357422,2.0537109c0.578125,1.0146484,0.7294922,2.1914062,0.425293,3.3144531  c-0.3032227,1.1210938-1.0239258,2.0537109-2.0292969,2.6269531c-1.0043945,0.5742188-2.1757812,0.71875-3.2944336,0.4091797  c-1.1210938-0.3105469-2.0576172-1.0400391-2.6357422-2.0537109C16.4125977,67.8105469,16.2612305,66.6337891,16.5654297,65.5117188  z" />
      <path d="M27.9985352,70.9609375c-0.2758789,0.4785156-0.1118164,1.0898438,0.3662109,1.3662109l12.6821289,7.3212891  c0.1577148,0.0908203,0.3295898,0.1337891,0.4990234,0.1337891c0.3457031,0,0.6816406-0.1796875,0.8671875-0.5  c0.2758789-0.4785156,0.1118164-1.0898438-0.3662109-1.3662109l-12.6821289-7.3212891  C28.8842773,70.3173828,28.2744141,70.4833984,27.9985352,70.9609375z" />
      <path d="M49.8212891,77.3261719c-3.4951172,0-6.3476562,2.8203125-6.3662109,6.3037109  c-0.0087891,1.6933594,0.6455078,3.2880859,1.8432617,4.4921875c1.1958008,1.2011719,2.7905273,1.8681641,4.4897461,1.8769531  c0.012207,0,0.0244141,0,0.0366211,0c1.6855469,0,3.2729492-0.6484375,4.4731445-1.8291016  c1.2099609-1.1914062,1.8813477-2.7792969,1.8901367-4.4736328c0.0087891-1.6933594-0.6455078-3.2890625-1.8427734-4.4921875  c-1.1958008-1.2021484-2.7905273-1.8691406-4.4897461-1.8779297C49.84375,77.3261719,49.8325195,77.3261719,49.8212891,77.3261719z   M54.1879883,83.6865234c-0.0063477,1.1572266-0.465332,2.2431641-1.2929688,3.0576172  c-0.8232422,0.8095703-1.9125977,1.2548828-3.0708008,1.2548828c-0.0087891,0-0.0170898,0-0.0253906,0  c-1.1674805-0.0058594-2.2626953-0.4638672-3.0830078-1.2880859c-0.8193359-0.8232422-1.2670898-1.9140625-1.2607422-3.0712891  c0.0126953-2.3828125,1.96875-4.3134766,4.3662109-4.3134766c0.0078125,0,0.015625,0,0.0234375,0  c1.1674805,0.0058594,2.262207,0.4638672,3.0825195,1.2880859C53.746582,81.4375,54.1943359,82.5283203,54.1879883,83.6865234z" />
      <path d="M58.0185547,80.0341797c0.1694336,0,0.3417969-0.0429688,0.4990234-0.1337891L71.199707,72.578125  c0.4785156-0.2763672,0.6420898-0.8876953,0.3662109-1.3662109c-0.2763672-0.4794922-0.8886719-0.6435547-1.3662109-0.3662109  l-12.6821289,7.3222656c-0.4785156,0.2763672-0.6420898,0.8876953-0.3662109,1.3662109  C57.3369141,79.8554688,57.6728516,80.0341797,58.0185547,80.0341797z" />
      <path d="M73.5932617,63.7880859c-1.7709961,3.03125-0.7583008,6.9316406,2.2578125,8.6943359  c0.9990234,0.5839844,2.09375,0.8613281,3.1757812,0.8613281c2.1870117,0,4.3227539-1.1328125,5.5078125-3.1601562  c1.7714844-3.03125,0.7578125-6.9316406-2.2583008-8.6943359c-1.4628906-0.8554688-3.1713867-1.0859375-4.8125-0.6503906  C75.8251953,61.2734375,74.4506836,62.3212891,73.5932617,63.7880859z M81.2670898,63.2158203  c2.0644531,1.2070312,2.7558594,3.8798828,1.5410156,5.9580078c-1.2148438,2.0791016-3.8818359,2.7890625-5.9472656,1.5820312  c-2.0644531-1.2070312-2.7553711-3.8798828-1.5410156-5.9580078c0.5888672-1.0078125,1.5327148-1.7275391,2.6572266-2.0253906  C79.0976562,62.4755859,80.2680664,62.6318359,81.2670898,63.2158203z" />
      <path d="M80.152832,57.5732422V42.9296875c0-0.5527344-0.4477539-1-1-1s-1,0.4472656-1,1v14.6435547c0,0.5527344,0.4477539,1,1,1  S80.152832,58.1259766,80.152832,57.5732422z" />
      <path d="M84.746582,30.1845703c-0.8417969-1.4765625-2.2055664-2.5390625-3.8398438-2.9912109  c-1.6352539-0.4511719-3.3461914-0.2382812-4.8188477,0.5986328c-1.4707031,0.8398438-2.5253906,2.2041016-2.96875,3.8427734  c-0.4428711,1.6367188-0.2231445,3.3515625,0.6186523,4.8271484C74.5795898,37.9384766,75.9433594,39,77.5771484,39.4521484  c0.5625,0.15625,1.1333008,0.2333984,1.7006836,0.2333984c1.0834961,0,2.152832-0.28125,3.1186523-0.8320312  c1.4707031-0.8398438,2.5253906-2.2041016,2.96875-3.8427734C85.8081055,33.375,85.5883789,31.6601562,84.746582,30.1845703z   M83.4345703,34.4882812c-0.3032227,1.1210938-1.0239258,2.0537109-2.0292969,2.6279297  c-1.0053711,0.5732422-2.175293,0.71875-3.2944336,0.4091797c-1.1210938-0.3105469-2.0571289-1.0400391-2.6357422-2.0537109  c-0.578125-1.0146484-0.7294922-2.1914062-0.425293-3.3144531c0.3032227-1.1210938,1.0239258-2.0537109,2.0292969-2.6269531  c0.6591797-0.3769531,1.3896484-0.5683594,2.1303711-0.5683594c0.3881836,0,0.7792969,0.0527344,1.1640625,0.1591797  c1.1210938,0.3105469,2.0576172,1.0400391,2.6357422,2.0537109C83.5874023,32.1894531,83.7387695,33.3662109,83.4345703,34.4882812z  " />
      <path d="M72.0014648,29.0390625c0.2758789-0.4785156,0.1118164-1.0898438-0.3662109-1.3662109L58.953125,20.3515625  c-0.4804688-0.2773438-1.0908203-0.1113281-1.3662109,0.3662109c-0.2758789,0.4785156-0.1118164,1.0898438,0.3662109,1.3662109  l12.6821289,7.3212891c0.1577148,0.0908203,0.3295898,0.1337891,0.4990234,0.1337891  C71.4799805,29.5390625,71.815918,29.359375,72.0014648,29.0390625z" />
      <path d="M66.5444336,50.0654297c0-9.1230469-7.421875-16.5449219-16.5444336-16.5449219s-16.5444336,7.421875-16.5444336,16.5449219  S40.8774414,66.6103516,50,66.6103516S66.5444336,59.1884766,66.5444336,50.0654297z M35.4555664,50.0654297  c0-8.0205078,6.5244141-14.5449219,14.5444336-14.5449219s14.5444336,6.5244141,14.5444336,14.5449219  c0,3.8195801-1.4911499,7.2891235-3.9073486,9.8870239c-0.578125-3.2463379-2.5910645-6.0595093-5.4979248-7.6233521  c1.5424805-1.3808594,2.4487305-3.3583984,2.4487305-5.4931641c0-4.0771484-3.3164062-7.3935547-7.3930664-7.3935547  s-7.3935547,3.3164062-7.3935547,7.3935547c0,2.0546875,0.8510742,3.9824219,2.3012695,5.3603516  c-3.0448608,1.5308838-5.1522827,4.3973389-5.7424927,7.7530518C36.9455566,57.3518066,35.4555664,53.8834839,35.4555664,50.0654297  z M41.1851196,61.6078491c0.1033936-3.8081665,2.5838623-7.0839844,6.2474976-8.1967163  c0.3857422-0.1171875,0.6630859-0.4541016,0.7041016-0.8554688c0.0410156-0.4003906-0.1625977-0.7871094-0.5161133-0.9794922  c-1.7392578-0.9472656-2.8193359-2.7636719-2.8193359-4.7402344c0-2.9736328,2.4194336-5.3935547,5.3935547-5.3935547  c2.9736328,0,5.3930664,2.4199219,5.3930664,5.3935547c0,2.0361328-1.1318359,3.8769531-2.953125,4.8037109  c-0.3598633,0.1835938-0.5742188,0.5644531-0.543457,0.9667969c0.0302734,0.4023438,0.2998047,0.7470703,0.6826172,0.8740234  c3.5407104,1.1732178,5.9384155,4.4208984,6.0410156,8.1273193c-2.4490967,1.875-5.4992676,3.0025635-8.8149414,3.0025635  S43.6341553,63.4828491,41.1851196,61.6078491z" />
    </svg>
  )
}

export default PortalIcon
