import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core"
import { ArrowDropDown, CloudDownload } from "@material-ui/icons"
import { withStyles } from "@material-ui/styles"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  container: {},
  button: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  menu: {
    // backgroundColor: "#f5f5f5",
    ".MuiMenuItem-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-selected": {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#F1F3F9",
      },
    },
  },
  menuItem: {
    minWidth: 250,
    display: "flex",
    justifyContent: "space-between",
  },
  menuItemIcon: {
    minWidth: "unset",
  },
})

class VersionMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
  }

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget)
  }

  handleClose = () => {
    this.setAnchorEl(null)
  }

  setAnchorEl = (anchorEl) => {
    this.setState({ anchorEl })
  }

  // eslint-disable-next-line no-unused-vars
  download = (revision) => (event) => {
    const { onChangeVersion } = this.props
    this.setAnchorEl(null)
    if (onChangeVersion) {
      onChangeVersion(revision)
    } else {
      window.open(revision.path, "_blank")
    }
  }

  render() {
    const { anchorEl } = this.state
    const { classes, data, selectedVersion } = this.props

    let revisions = []
    if (_.isArray(data.revisions) && data.revisions.length) {
      // revisions = _.filter(data.revisions, (i) => i.version !== data.version)
      revisions = data.revisions
    }

    let dV = "NA"
    if (selectedVersion) {
      dV = selectedVersion.version
    } else if (data) {
      dV = data.version
    }
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          disableElevation
          onClick={this.handleClick}
          className={classes.button}
          endIcon={<ArrowDropDown />}
        >
          {dV}
        </Button>
        <Menu
          // elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            // vertical: "top",
            horizontal: "center",
          }}
          id={`simple-menu-version-info-${data.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          className={classes.menu}
          onClose={this.handleClose}
        >
          {_.isArray(revisions) && revisions.length ? (
            revisions.map((revision) => {
              return (
                <MenuItem
                  key={`document_version_${revision.id}_${data.id}`}
                  className={classes.menuItem}
                  onClick={this.download(revision)}
                >
                  <ListItemText
                    primary={`Version ${revision.version}`}
                    secondary={<>{revision.description || ""}</>}
                  />
                  <ListItemIcon className={classes.menuItemIcon}>
                    <CloudDownload fontSize="small" color="primary" />
                  </ListItemIcon>
                </MenuItem>
              )
            })
          ) : (
            <MenuItem
              key={`document_version_no_version_${data.id}`}
              className={classes.menuItem}
              // onClick={this.handleClose}
            >
              No Versions available
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }
}

VersionMenu.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.any),
  onChangeVersion: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  selectedVersion: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
}

VersionMenu.defaultProps = {
  data: {},
  classes: {},
  onChangeVersion: null,
  selectedVersion: null,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    VersionMenu
  )
)
