const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // paddingBottom: 70,
  },
  cardView: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  loadingView: {
    minHeight: 500,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    // alignItems: "center",
    // justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    // minWidth: "35%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderColor: "#E5E5E5",
    borderRadius: 6,
    borderWidth: 1,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 0, 0),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    // borderRadius: 5,
    // minWidth: "35%",
    width: "818px",
    minHeight: "680px",
    "&:focus": {
      outline: "none",
    },
  },
  addLogo: {
    height: 120,
    width: 120,
    borderRadius: 60,
    backgroundColor: "#436EE032",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addLogoText: {
    fontSize: 15,
  },
  form: {
    // marginTop: 70,
    paddingBottom: 20,
  },
  editForm: {
    paddingBottom: 50,
  },
  formWidth: {
    width: 350,
    minHeight: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  formTop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formTitle: {
    fontSize: 18,
  },
  inputRow: {
    margin: "5px 0",
  },
  alertRoot: {
    width: "100%",
  },
  snackbar: {
    width: "80%",
  },
  closeBadge: {
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  closeIconButton: {
    padding: 3,
  },
  indicator: {
    backgroundColor: "#278BED",
    // marginLeft: 10,
  },
  tabButton: {
    padding: "0px 10px 0px 0px",
    fontSize: 16,
    marginRight: 20,
  },
  footerView: {
    borderTop: "1px solid #D9D9D9",
    padding: "27.5px 49px",
  },
  okButton: {
    height: 50,
    backgroundColor: "#436EE0",
    borderRadius: "4px",
    fontSize: 15,
    color: "#FFFFFF",
    fontWeight: "normal",
    textTransform: "none",
  },
  cancelButton: {
    // backgroundColor: "#436EE0",
    height: 50,
    borderRadius: "4px",
    fontSize: 15,
    color: "#707070",
    fontWeight: "normal",
    textTransform: "none",
  },
  autocompletePopper: {
    top: "10px !important",
  },
  autocompletePaper: {
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 20px #00000033",
    borderRadius: "4px",
  },
  autocompleteOption: {
    padding: 0,
    height: 40,
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#F1F3F9",
    },
    // Selected
    '&[aria-selected="true"]': {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
  },
  searchFormControl: {
    margin: "0 8px",
    minWidth: 200,
    maxWidth: 200,
    "& .MuiSelect-select": {
      "&:focus": {
        background: "none",
      },
    },
    "label + &": {
      marginLeft: 20,
    },
    "& .MuiInputLabel-root": {
      marginLeft: 10,
    },
    "& .MuiFormLabel-root": {
      "&.Mui-focused": {
        color: "#2F2F2F80",
      },
    },
  },
  searchInputInternal: {
    background: "#0000",
    height: 36,
  },
  searchInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      borderWidth: 1,
      // borderRadius: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      // color: "#2F2F2F80",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: 1,
      // borderRadius: 0,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      // color: "#2F2F2F80",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: 1,
      // borderRadius: 0,
    },
    "& .MuiInputLabel-outlined": {
      // z-index: 1;
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiInputLabel-shrink": {
      // z-index: 1;
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
})

export default styles
