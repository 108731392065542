import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"

// import { bindActionCreators } from "redux"
// import { connect, useDispatch } from "react-redux"
import { useDispatch } from "react-redux"
import authActions from "../redux/reducers/auth/actions"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  // const {authActions: { setUserData }} = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        console.log("initAuth0 => 0")
        const auth0FromHook = await createAuth0Client(initOptions)
        setAuth0(auth0FromHook)
        console.log("initAuth0 => 1")
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          console.log("initAuth0 => 2")
          const { appState } = await auth0FromHook.handleRedirectCallback()
          console.log("initAuth0 => appState", appState)
          onRedirectCallback(appState)
        }
        console.log("initAuth0 => 3")
        // eslint-disable-next-line no-shadow
        const isAuthenticated = await auth0FromHook.isAuthenticated()
        console.log("initAuth0 => 4", isAuthenticated)
        setIsAuthenticated(isAuthenticated)

        if (isAuthenticated) {
          console.log("initAuth0 => 5")
          // eslint-disable-next-line no-shadow
          const user = await auth0FromHook.getUser()
          console.log("initAuth0 => 6")
          // saffronios.com/app_metadata.id
          setUser(user)
          console.log("initAuth0 => 7")
          const token = await auth0FromHook.getTokenSilently()
          console.log("initAuth0 => 8")
          const idToken = await auth0FromHook.getIdTokenClaims()
          console.log("initAuth0 => 9")
          // console.log("_USER_ROLES ID TOKEN", idToken)
          // console.log("_USER_ROLES TOKEN", token)
          // Set User id
          const authUserId = idToken["https://saffronios.com/app_metadata"].id
          localStorage.setItem("saffron-token", token)
          //   setUserData(token, user);
          user.auth_user_id = authUserId

          // Zend desk token
          const zendDeskToken =
            idToken["https://saffronios.com/zendDeskToken"].token
          localStorage.setItem("saffron-zd-token", zendDeskToken)

          // TODO - Add id token to user data
          dispatch(authActions.setUserData(token, user))
          // console.log("_TOKEN_SET_")
          // console.log(token)

          // console.log("_TOKEN_ID_")
          // console.log(idToken)
          // return;
        }
        console.log("initAuth0 => 10")
        setLoading(false)
      } catch (error) {
        console.log("error", error)
        localStorage.removeItem("saffron-token")
        localStorage.removeItem("saffron-zd-token")
        window.location.href = "/error"
        // setLoading(false)
      }
      // return;
    }
    initAuth0()
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    // eslint-disable-next-line no-shadow
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    // eslint-disable-next-line no-shadow
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  // console.log("auth context")
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        isLoading: loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// })
// const mapDispatchToProps = (dispatch) => {
//   return {
//     authActions: bindActionCreators(authActions, dispatch),
//   }
// }

export default Auth0Provider

// export default connect(mapStateToProps, mapDispatchToProps)(Auth0Provider)
