import React, { Component } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { Button } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import Box from "@material-ui/core/Box"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import programmeActions from "../../redux/reducers/programme/actions"
import templateActions from "../../redux/reducers/template/actions"
import styles from "./Header.styles"
// import { privateRoutes } from "../../containers/AppContainer/Routes"
import TaskFilters from "../TaskFilters/TaskFilters"
import NewTabIcon from "../NavIcon/NewTabIcon"
import DropdownButton from "../DropdownButton/DropdownButton"
import CreateProgramModal from "../../containers/Programs/CreateProgramModal"
import SnackBar from "../SnackBar/SnackBar"
import AddExistingProjectModal from "../../containers/ProgramProjectList/AddExistingProjectModal"
import CreateTemplateModal from "../../containers/SiteAdmin/Projects/CreateTemplateModal"
import { getTenantData } from "../../common/utils"
// import Notifications from "../Notifications"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // value: 0,
      message: {
        open: false,
        message: "",
        type: "success",
      },
      buttonDropdownOptions: [
        { id: 1, label: "Create New", onClick: this.addNewProject },
        { id: 2, label: "Add Existing", onClick: this.addExistingProject },
      ],
    }
  }

  addNewProject = () => {
    const { history } = this.props
    // history.push("/projects")
    // const { match: { params: { programmeId } = {} } = {} } = this.props
    // console.log("addNewProject", this.props.history)
    let programmeId = ""
    let path = ""
    if (history && history.location && history.location.pathname) {
      const { pathname } = history.location
      path = pathname
      if (pathname.includes("dashboard")) {
        programmeId = pathname
          .replace("/dashboard/", "")
          .replace("programmes/", "")
      } else {
        programmeId = pathname
          .replace("/programme/", "")
          .replace("/projects", "")
      }
    }
    history.push({
      pathname: "/manage/projects",
      state: {
        programmeId,
        path,
      },
    })
  }

  addExistingProject = () => {
    if (this.AddExistingProjectModal) {
      this.AddExistingProjectModal.handleOpen()
    }
  }

  onStartNewProjectClick = () => {
    const { history, match } = this.props
    const { url, path } = match
    let furl = url
    if (url && url.includes(path)) {
      furl = url.replace(path, "")
    }
    history.replace(`${furl}/manage/projects`)
    // history.push("manage/projects")
  }

  onStartNewProgramme = () => {
    if (this.CreateProgramModal) {
      this.CreateProgramModal.handleOpen()
    }
    // const { history } = this.props
    // history.push("/programme/create")
  }

  onCreateNewTemplate = () => {
    if (this.CreateTemplateModal) {
      this.CreateTemplateModal.handleOpen()
    }
  }

  openPortal = (id) => () => {
    const tenantData = getTenantData()
    let tenantDomain = ""
    if (tenantData) {
      tenantDomain = `${tenantData.domain}.`

      if (tenantDomain === "localhost.") {
        tenantDomain = ""
      }
    }

    const clientPortalUrl = `${process.env.REACT_APP_CLIENT_PORTAL_BASE}${tenantDomain}${process.env.REACT_APP_CLIENT_PORTAL_URL}`
    const url = `${clientPortalUrl}/edit-corporate-entity/${id}`
    window.open(url, "_blank")
  }

  setMessage = (message) => {
    this.setState({ message })
  }

  // eslint-disable-next-line no-unused-vars
  setTab = (value) => {
    // this.setState({ value })
  }

  handleClose = (event, reason) => {
    const { message } = this.state
    if (reason === "clickaway") {
      return
    }
    this.setState({
      message: {
        ...message,
        open: false,
      },
    })
  }

  onClose = (success) => {
    if (success) {
      window.location.reload()
    }
  }

  onCloseTemplate = (success) => {
    if (success) {
      const {
        // apiActions: { apiCall },
        templateActions: { getTemplates },
      } = this.props
      getTemplates()
    }
  }

  renderSnackBar = () => {
    const { message } = this.state
    return <SnackBar message={message} afterClose={this.handleClose} />
  }

  renderCreateProgramModal = () => {
    // const { edit, program } = this.state
    return (
      <CreateProgramModal
        onClose={this.onClose}
        setMessage={this.setMessage}
        setTab={this.setTab}
        // template={template}
        ref={(o) => {
          this.CreateProgramModal = o
        }}
      />
    )
  }

  renderCreateTemplateModal = () => {
    // const { edit, program } = this.state
    return (
      <CreateTemplateModal
        onClose={this.onCloseTemplate}
        setMessage={this.setMessage}
        // setTab={this.setTab}
        // template={template}
        ref={(o) => {
          this.CreateTemplateModal = o
        }}
      />
    )
  }

  renderAddExistingProjectModal = () => {
    // const { edit, program } = this.state
    return (
      <AddExistingProjectModal
        onClose={this.onClose}
        setMessage={this.setMessage}
        ref={(o) => {
          this.AddExistingProjectModal = o
        }}
      />
    )
  }

  renderButton = () => {
    const {
      classes,
      history,
      task: { filterVisible, open: taskOpen },
    } = this.props
    const { buttonDropdownOptions } = this.state

    if (history.location.pathname === "/manage/projects") {
      return (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          disableFocusRipple
          disableTouchRipple
          startIcon={<Add />}
          className={classes.menuButtonLabel}
          onClick={this.onCreateNewTemplate}
        >
          Create Template
        </Button>
      )
    }

    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/dashboard/projects"
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          disableFocusRipple
          disableTouchRipple
          startIcon={<Add />}
          className={classes.menuButtonLabel}
          onClick={this.onStartNewProjectClick}
        >
          Start New Project
        </Button>
      )
    }

    if (history.location.pathname.includes("/dashboard/programmes/")) {
      return <DropdownButton options={buttonDropdownOptions} />
    }

    if (
      history.location.pathname.includes("/programme") &&
      !history.location.pathname.includes("/projects")
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          disableFocusRipple
          disableTouchRipple
          startIcon={<Add />}
          className={classes.menuButtonLabel}
          onClick={this.onStartNewProgramme}
        >
          Start New Programme
        </Button>
      )
    }

    if (
      history.location.pathname.includes("/programme") &&
      history.location.pathname.includes("/projects")
    ) {
      return <DropdownButton options={buttonDropdownOptions} />
    }

    if (
      history.location.pathname.includes("/dashboard/project") &&
      filterVisible
    ) {
      return (
        <Box className={clsx(taskOpen && classes.filterShift)}>
          <TaskFilters />
        </Box>
      )
    }
    return null
  }

  // renderNotifications = () => {
  //   return <Notifications />
  // }

  render() {
    const {
      classes,
      handleDrawerOpen,
      open,
      history,
      auth: { routeName },
      task: { project, open: taskOpen },
    } = this.props
    const icon = (
      <NewTabIcon style={{ height: 20, width: 20, fill: "rgb(0, 0, 0,.54)" }} />
    )
    return (
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          open && classes.appBarShift,
          taskOpen && classes.panelShift
        )}
        color="default"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {routeName || ""}
            {routeName &&
              routeName !== "" &&
              history.location.pathname.includes("/dashboard/project/") &&
              project.company_id !== "" && (
                <IconButton
                  onClick={this.openPortal(project.company_id)}
                  color="primary"
                  aria-label="View Organisation in client portal"
                  style={{ marginLeft: "5px", marginBottom: "2px" }}
                >
                  {icon}
                </IconButton>
              )}
          </Typography>

          {this.renderButton()}
          {this.renderCreateProgramModal()}
          {this.renderCreateTemplateModal()}
          {this.renderAddExistingProjectModal()}
          {this.renderSnackBar()}
          {/* {this.renderNotifications()} */}
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  // match: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  handleDrawerOpen: PropTypes.func,
  open: PropTypes.bool,
  templateActions: PropTypes.objectOf(PropTypes.any),
  // template: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  // programmeActions: PropTypes.objectOf(PropTypes.any),
  // openPortal: PropTypes.func,
}

Header.defaultProps = {
  classes: {},
  history: {},
  // match: {},
  auth: {},
  task: {},
  handleDrawerOpen: () => {},
  // openPortal: () => {},
  open: true,
  templateActions: {},
  // template: {},
  // authActions: {},
  // taskActions: {},
  // programmeActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
  programme: state.programme,
  template: state.template,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    programmeActions: bindActionCreators(programmeActions, dispatch),
    templateActions: bindActionCreators(templateActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Header)
)
