const drawerWidth = 200

const rightDrawerWidth = 800

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: 138,
    height: 40,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: "solid 1px #70707032",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: rightDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: rightDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerDC: {
    // width: rightDrawerWidth,
    flexShrink: 0,
  },
  drawerPaperCloseDC: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(7),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      // width: theme.spacing(9),
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#F9FAFC",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -rightDrawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // paddingBottom: 70,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "unset",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  userAvatar: {
    backgroundColor: "#0000",
    border: "solid 1px #278BED",
    color: "#278BED",
  },
  userName: {},
  userRole: {},
  focused: {
    backgroundColor: "#F1F3F9",
  },
  grid: {
    padding: "0 15px !important",
  },
  gridCont: {
    margin: "0 -15px !important",
    width: "unset",
  },
  mb10: {
    marginBottom: "5px",
    fontSize: "14px",
  },
  cardBodyText: {
    fontSize: "16px",
    fontWeight: 400,
  },
  cardBodyTextSelected: {
    color: theme.palette.primary.main,
  },
  listItemLeft: {
    minWidth: "32px",
  },
  liGutters: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    margin: "5px 5px 5px 0",
  },
  cardView: {
    height: "150px",
    marginBottom: "30px",
    cursor: "pointer",
  },
  cardViewSelected: {
    backgroundColor: "#f9f6f6",
  },
  badge: {
    height: 26,
    width: 26,
    borderRadius: 13,
    backgroundColor: "#64ED27",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    fontSize: 12,
  },
  redBadge: {
    backgroundColor: "#F00",
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  panelList: {
    paddingBottom: 0,
  },
  liSecondaryAction: {
    paddingRight: 32,
  },
  liSelected: {
    borderRadius: 5,
  },
  dashboardsCardHeader: {
    marginBottom: "5px",
    fontSize: "21px",
  },
  viewButton: {
    height: 54,
    backgroundColor: "#C7CBD3",
    borderRadius: 6,
    fontSize: 18,
    color: "#FFF",
    fontWeight: "normal",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5BB37E",
    },
  },
})

export default styles
