export default {
  width: "100%",
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
    width: "100%",
  },

  "&multiLine": {
    control: {
      // fontFamily: "monospace",
      minHeight: 150,
    },
    highlighter: {
      padding: 9,
      border: "none",
      //   border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "none",
      //   border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "none",
      //   border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "none",
      //   border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      // padding: "5px 15px",
      borderRadius: 5,
      maxHeight: 300,
      overflow: "scroll",
    },
    item: {
      //   padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        // backgroundColor: "#cee4e5",
        backgroundColor: "#F1F3F9",
      },
      // Selected
      "&$selected": {
        borderLeft: "2px solid #436EE0",
        // backgroundColor: "#fff",
        backgroundColor: "#7B87C616",
      },
    },
  },
}
