import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Collapse from "@material-ui/core/Collapse"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import Select from "../Select/ActionSelect"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"

// const typeData = [
//   {
//     value: "Start to Finish",
//     label: "Start to Finish",
//   },
//   {
//     value: "Start to Start",
//     label: "Start to Start",
//   },
//   {
//     value: "Finish to Start",
//     label: "Finish to Start",
//   },
//   {
//     value: "Finish to Finish",
//     label: "Finish to Finish",
//   },
// ]

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  boxContainer: {
    padding: 0,
    marginTop: 0,
  },
  mDivide: {
    // marginBottom: "10px",
    // paddingBottom: "10px",
  },
  headText: {
    fontSize: 14,
    // marginTop: 15,
    // marginLeft: 10,
    marginBottom: 8,
  },
  addIcon: {
    fill: "#000",
    fontSize: 35,
    height: 25,
    position: "absolute",
    top: 8,
    right: 0,
    zIndex: 11,
    cursor: "pointer",
  },
  inputRow: {
    width: 220,
    "& .MuiSelect-select": {
      "&:focus": {
        background: "none",
      },
    },
  },
  dependantInputRow: {
    margin: "5px 0",
  },
  dependantInputRowTitle: {
    marginTop: 43,
  },
  arrowIcon: {
    position: "absolute",
    bottom: -5,
    right: 15,
    zIndex: 20,
  },
  taskName: {
    // color: "#2F2F2FB3",
    color: "#2F2F2F",
    fontSize: 14,
  },
  selectLabel: {
    color: "#2F2F2F",
    fontSize: 16,
    marginBottom: 5,
  },
  selectCont: {
    height: "27px !important",
  },
  closeIconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  closeIcon: {
    height: 20,
    width: 20,
    color: "#2f2f2f",
  },
  dTypeView: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
  },
  autocompletePopper: {
    top: "10px !important",
    // width: "300px !important",
  },
  autocompletePaper: {
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 20px #00000033",
    borderRadius: "4px",
    width: 350,
  },
  autocompleteOption: {
    padding: "0 10px",
    height: 40,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#F1F3F9",
    },
    // Selected
    '&[aria-selected="true"]': {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
  },
})

const addDependencyObj = {
  // new: true,
  // task: "",
  // type_id: "",

  // "id": 197,
  // "project_task_id": 244,
  predecessor_id: 0,
  successor_id: 0,
  type_id: 1,
}

// const CMenuItem = ({ classes, children }) => (
//   <MenuItem
//     classes={{
//       root: classes.menuItem,
//       // gutters,
//       selected: classes.menuItemSelected,
//       // dense,
//     }}
//   >
//     {children}
//   </MenuItem>
// )

// const CustomMenuItem = withStyles(styles)(CMenuItem)

class DependencySection extends Component {
  constructor(props) {
    super(props)
    const {
      task: { task },
    } = props
    const dependencyList =
      task && _.isArray(task.project_task_dependencies)
        ? task.project_task_dependencies
        : []
    this.state = {
      open: false,
      dependencyList: [
        // addDependencyObj,
        ...dependencyList,
      ],
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.onChange) {
  //     if ( ! _.isEqual(prevState.dependencyList, this.state.dependencyList)) {
  //       this.props.onChange(this.state);
  //     }
  //   }
  // }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (nextTask.open && !_.isEqual(task.task, nextTask.task)) {
      if (nextTask.task) {
        this.getTaskInfo(nextTask.task)
      }
    }
  }

  getTaskInfo = (task) => {
    if (!task) {
      return
    }
    // const { project_task_dependencies: dependencyList } = task
    const dependencyList = _.isArray(task.project_task_dependencies)
      ? task.project_task_dependencies
      : []
    this.setState({
      dependencyList: [
        // addDependencyObj,
        ...dependencyList,
      ],
    })
  }

  toggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  remove = (item, index) => () => {
    const { dependencyList } = this.state
    dependencyList.splice(index, 1)
    this.setState({ dependencyList })
  }

  validate = () => {
    const { dependencyList } = this.state
    const {
      task: { task },
    } = this.props
    if (!task) {
      return null
    }
    const ary = []
    // eslint-disable-next-line array-callback-return
    dependencyList.map((ele) => {
      if (ele.id) {
        ary.push({
          id: ele.id,
          from_task_id: ele.from_task_id,
          to_task_id: ele.to_task_id,
          project_task_id: ele.project_task_id,
          predecessor_id: ele.predecessor_id,
          successor_id: ele.successor_id,
          type_id: ele.type_id,
          /* send queue if needed by server */
          // queue: ele.queue,
        })
      } else if (
        !ele.id &&
        ele.task &&
        ele.task.id &&
        _.isNumber(ele.type_id)
      ) {
        ary.push({
          id: 0,
          from_task_id: task.id,
          to_task_id: ele.task.id,
          project_task_id: task.id,
          predecessor_id: ele.task.id,
          successor_id: 0,
          type_id: ele.type_id,
        })
      }
    })
    return { dependencies: ary }
  }

  renderHeader = () => {
    const { open } = this.state
    return (
      <ListItem button divider onClick={this.toggle}>
        <ListItemIcon>
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </ListItemIcon>
        <ListItemText primary="Dependencies:" />
      </ListItem>
    )
  }

  onChangeValue = (key, index) => (e) => {
    const { dependencyList } = this.state
    // eslint-disable-next-line array-callback-return
    dependencyList.map((item, dI) => {
      if (dI === index) {
        // console.log('e.target.value', e.target.value)
        // console.log('onChangeValue key', key)
        // eslint-disable-next-line no-param-reassign
        item[key] = e.target.value
      }
    })
    this.setState({ dependencyList })
  }

  onChangeTaskName = (key, index) => (event, newValue) => {
    const { dependencyList } = this.state
    // eslint-disable-next-line array-callback-return
    dependencyList.map((item, dI) => {
      if (dI === index) {
        // eslint-disable-next-line no-param-reassign
        item[key] = newValue
      }
    })
    this.setState({ dependencyList })
  }

  addDependency = () => {
    const { dependencyList } = this.state
    const {
      task: { task },
    } = this.props
    // const nObj = _.find(dependencyList, (i) => i.new === true)
    // if (nObj) {
    //   const obj = {
    //     // created_at: "2020-06-19 11:55:05",
    //     // id: 88,
    //     predecessor_id: nObj.task.id,
    //     project_task_id: task.id,
    //     // project_task_name: "Sign Off Business Case",
    //     // queue: null,
    //     // successor_id: null,
    //     // type_id: 1,
    //     // type_name: "Finish to Start",
    //     // updated_at: "2020-06-19 11:55:05",
    //     project_task_name: nObj.task.task_name,
    //     type_name: nObj.type_name,
    //   }
    //   dependencyList.push(obj)
    // }
    // const nDependencyList = _.filter(dependencyList, (i) => i.new !== true)
    const dList = [
      {
        ...addDependencyObj,
        project_task_id: task.id,
      },
      ...dependencyList,
    ]
    this.setState({ dependencyList: dList })
  }

  renderDependencyHeader = () => {
    const { classes } = this.props
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={2}
        className={classes.dependantInputRowTitle}
      >
        <Grid item xs={10} sm={10} md={5} lg={5} xl={5}>
          <FormControl fullWidth className={classes.inputRow}>
            <Typography className={classes.selectLabel}>Task Name</Typography>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={6}
          xl={6}
          className={classes.dTypeView}
        >
          <FormControl fullWidth className={classes.inputRow}>
            <Typography className={classes.selectLabel}>
              Dependency Type
            </Typography>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          className={classes.closeIconView}
        >
          {/* <CloseIcon /> */}
        </Grid>
      </Grid>
    )
  }

  forbiddenIds = (index) => {
    const { dependencyList } = this.state
    const {
      task: { task },
    } = this.props
    const taskId = task && task.id ? task.id : ""
    const ary = [taskId]
    // eslint-disable-next-line array-callback-return
    dependencyList.map((ele, ii) => {
      if (ele.id && index !== ii) {
        ary.push(ele.project_task_id)
      } else if (!ele.id && index !== ii && ele.task && ele.task.id) {
        ary.push(ele.task.id)
      }
    })

    return ary
  }

  getTaskList = ({ index, isParent }) => {
    const {
      task: { taskList },
    } = this.props
    const forbiddenIds = this.forbiddenIds(index)
    const retAry = []
    // eslint-disable-next-line array-callback-return
    taskList.map((taskItem) => {
      const allowed = forbiddenIds.indexOf(taskItem.id) < 0
      if (allowed && isParent) {
        retAry.push(taskItem)
      }
      if (
        !isParent &&
        _.isArray(taskItem.childrens) &&
        taskItem.childrens.length
      ) {
        // eslint-disable-next-line array-callback-return
        taskItem.childrens.map((cI) => {
          const callowed = forbiddenIds.indexOf(cI.id) < 0
          if (callowed) {
            retAry.push(cI)
          }
        })
      }
    })
    return _.sortBy(retAry, "id")
  }

  renderTaskDropdown = ({ row, index, isParent }) => {
    const { classes } = this.props
    // const {
    //   task: { task, taskList, dependencyTypeList },
    // } = this.props
    const taskList = this.getTaskList({ index, isParent })
    return (
      <FormControl
        fullWidth
        className={classes.inputRow}
        // error={!!errors.company}
      >
        <Autocomplete
          id={`dependency_row_${row.id}_${index}`}
          options={taskList}
          // groupBy={(option) => option.first}
          getOptionLabel={(option) => `${option.id} - ${option.task_name}`}
          value={row.task}
          onChange={this.onChangeTaskName("task", index)}
          classes={{
            popper: classes.autocompletePopper,
            paper: classes.autocompletePaper,
            option: classes.autocompleteOption,
          }}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              // error={!!errors.company}
              InputProps={{
                ...params.InputProps,
                className: classes.selectCont,
                disableUnderline: true,
                fullWidth: true,
              }}
              id={`dependency_row_input_${row.id}_${index}`}
              type="Name"
              placeholder="Task Name"
            />
          )}
        />
      </FormControl>
    )
  }

  renderNewDependencyRow = (row, index) => {
    // const { open } = this.state
    const { classes } = this.props
    const {
      task: { task, dependencyTypeList },
    } = this.props
    // const taskId = task && task.id ? task.id : ""
    // console.log("renderNewDependencyRow", row)

    // const forbiddenIds = this.forbiddenIds(index)
    // console.log("forbiddenIds", forbiddenIds, index)
    // eslint-disable-next-line eqeqeq
    const isParent = task && task.parent_id == 0
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={2}
        key="new_dependency_row"
        className={classes.dependantInputRow}
      >
        <Grid item xs={10} sm={10} md={5} lg={5} xl={5}>
          {/* <FormControl fullWidth className={classes.inputRow}>
            <Typography className={classes.selectLabel}>Task Name</Typography>
            <Select
              disableUnderline
              value={row.task}
              defaultValue={row.task}
              onChange={this.onChangeValue("task", index)}
              displayEmpty
              fullWidth
              inputProps={{
                className: classes.selectCont,
              }}
            >
              <MenuItem value="">Select Task</MenuItem>
              {taskList.map((taskItem) => {
                const allowed = forbiddenIds.indexOf(taskItem.id) < 0
                return [
                  allowed && isParent && (
                    <MenuItem
                      value={taskItem}
                    >{`${taskItem.id} - ${taskItem.task_name}`}</MenuItem>
                  ),
                  !isParent &&
                    _.isArray(taskItem.childrens) &&
                    taskItem.childrens.length &&
                    taskItem.childrens.map((cI) => {
                      const callowed = forbiddenIds.indexOf(cI.id) < 0
                      return (
                        callowed && (
                          <MenuItem
                            value={cI}
                          >{`${cI.id} - ${cI.task_name}`}</MenuItem>
                        )
                      )
                    }),
                ]
              })}
            </Select>
          </FormControl> */}
          {this.renderTaskDropdown({ row, index, isParent })}
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={6}
          xl={6}
          className={classes.dTypeView}
        >
          <FormControl fullWidth className={classes.inputRow}>
            {/* <Typography className={classes.selectLabel}>
              Dependency Type
            </Typography> */}
            <Select
              disableUnderline
              value={row.type_id}
              defaultValue={row.type_id}
              onChange={this.onChangeValue("type_id", index)}
              displayEmpty
              fullWidth
              inputProps={{
                className: classes.selectCont,
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {dependencyTypeList.map((ele, i) => {
                return (
                  <MenuItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`dependency_type_${ele.id}_${i}`}
                    value={ele.id}
                  >
                    {ele.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          className={classes.closeIconView}
        >
          <Tooltip title="Remove">
            <CloseIcon
              className={classes.closeIcon}
              onClick={this.remove(row, index)}
            />
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  renderDependencyRow = (row, index) => {
    // console.log("renderDependencyRow", row)
    const {
      classes,
      task: { dependencyTypeList },
    } = this.props
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={2}
        wrap="wrap"
        key={`dependency_row_${row.id}_${index}`}
        className={classes.dependantInputRow}
      >
        <Grid
          item
          // xs
          xs={10}
          sm={10}
          md={5}
          lg={5}
          xl={5}
          style={{
            flexShrink: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {/* <Typography className={classes.taskName}>
            
          </Typography> */}
          {row.project_task_name}
          {/* <Typography className={classes.projectName}>test task</Typography> */}
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={6}
          xl={6}
          className={classes.dTypeView}
        >
          <FormControl fullWidth className={classes.inputRow}>
            <Select
              disableUnderline
              value={row.type_id}
              defaultValue={row.type_id}
              onChange={this.onChangeValue("type_id", index)}
              displayEmpty
              fullWidth
              inputProps={{
                className: classes.selectCont,
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {dependencyTypeList.map((ele, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={`${ele.id}_${index}_${i}`} value={ele.id}>
                    {ele.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          className={classes.closeIconView}
        >
          <CloseIcon
            className={classes.closeIcon}
            onClick={this.remove(row, index)}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { open, dependencyList } = this.state
    const { classes } = this.props
    const condition = _.isArray(dependencyList) && dependencyList.length
    // const condition = false
    return (
      <Box className={classes.boxContainer}>
        <Divider className={classes.mDivide} />
        <List disablePadding>
          {this.renderHeader()}
          <Collapse in={open} timeout="auto">
            <Box paddingY={1} paddingX={2} style={{ position: "relative" }}>
              <Tooltip title="Add Dependency">
                <AddIcon
                  className={classes.addIcon}
                  onClick={this.addDependency}
                />
              </Tooltip>
              {condition ? this.renderDependencyHeader() : null}
              {condition ? (
                dependencyList.map((tD, tI) => {
                  if (!tD.id) {
                    return this.renderNewDependencyRow(tD, tI)
                  }
                  return this.renderDependencyRow(tD, tI)
                })
              ) : (
                <Typography style={{ fontSize: 14, textAlign: "center" }}>
                  No dependencies for this task
                </Typography>
              )}
            </Box>
            <Divider className={classes.mDivide} />
          </Collapse>
        </List>
      </Box>
    )
  }
}

DependencySection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  //   auth: PropTypes.objectOf(PropTypes.any),
  //   taskActions: PropTypes.objectOf(PropTypes.any),
  //   apiActions: PropTypes.objectOf(PropTypes.any),
}

DependencySection.defaultProps = {
  classes: {},
  task: {},
  //   auth: {},
  //   taskActions: {},
  //   apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DependencySection
  )
)
