import React from "react"

const NavIcon = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "navIcon",
  height = "100%",
  viewBox = "0 0 100 100",
}) => {
  return (
    <svg
      // height="100"
      // width="100"
      width={width}
      style={{ height: 30, width: 30, ...style }}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="black"
        strokeWidth="1"
        fill="none"
      />
    </svg>
  )
}

export default NavIcon
