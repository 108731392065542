import React, { useState, useEffect } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import Axios from "axios"
import { PusherProvider } from "react-pusher-hoc"
import Pusher from "pusher-js"
import { SnackbarProvider } from "notistack"
import { useGA4React } from "ga-4-react"
// import Typography from "@material-ui/core/Typography"
import Slide from "@material-ui/core/Slide"
import makeStyles from "@material-ui/styles/makeStyles"
// import { Auth0Provider } from "@auth0/auth0-react"
import { store, persistor } from "../../redux/store"
import theme from "../../config/theme"
import history from "../../common/history"
import Routes from "./router"
import Loader from "../../components/Loader/Loader"
import Error from "../Error/Error"
import appConfigActions from "../../redux/reducers/appConfig/actions"
import { Auth0Provider } from "../../common/react-auth0-spa"
import { getTenantHeaders } from "../../common/utils"

const useStyles = makeStyles({
  snackbarRoot: {
    "& > div": {
      color: "#2F2F2F",
      backgroundColor: "#FFFFFF",
    },
  },
})

const getAuthUrl = () => {
  const {
    auth: { userdata },
  } = store.getState()
  const requestUserId = userdata.auth_user_id
  return `${
    process.env.REACT_APP_API_BASEURL
  }/api/pusher/auth?request_user_id=${requestUserId || ""}`
}

// app_id: "1193543"
// key = "b9642e58a61c0babd114"
// secret = "d15efa17d202fae31c66"
// cluster = "eu"

const onRedirectCallback = (appState) => {
  // eslint-disable-next-line no-console
  console.log("onRedirectCallback", appState)
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const renderLoading = () => {
  return <Loader />
}

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split("&")
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

const App = () => {
  const classes = useStyles()
  const loadingComponent = renderLoading()
  const [load, setLoad] = useState(false)
  const [loadError, setLoadError] = useState(null)
  const [myConnection, setConnection] = useState("")
  // eslint-disable-next-line no-unused-vars
  const ga = useGA4React()

  // const [myConnection, setConnection] = useState({
  //   id: "8dcde928-cad2-4f75-9e13-6caf1d74ab02",
  //   domain: "local-tenant1",
  //   client_name: "Local Tenant",
  //   connection: "ac-local-tenant",
  // })

  // setConnection()
  const renderError = () => {
    // return (
    //   <Typography variant="h4">
    //     {loadError?.message || "Unknown Error While loading"}
    //   </Typography>
    // )
    return (
      <ThemeProvider theme={theme}>
        <Error />
      </ThemeProvider>
    )
  }

  const loadAuthTenant = async () => {
    // eslint-disable-next-line no-console
    // console.log("loadAuthTenant")
    try {
      setLoad(true)
      const domain = window.location.hostname
      if (process.env.NODE_ENV === "development") {
        // domain = process.env.REACT_APP_BASE_DOMAIN
      }
      // console.log("domain", domain)
      const url = `${process.env.REACT_APP_CORE_API_BASEURL}tenant/domain/${domain}`
      const response = await Axios.get(url)

      // console.log("response", response)

      if (response.status === 200 && response.data) {
        // console.log("response", JSON.stringify(response.data))
        setConnection(response.data)
        //   console.log("setConnection:", response.data)
        localStorage.setItem("tenantData", JSON.stringify(response.data))
      } else {
        setLoadError({ message: "Error Loading Tenant" })
      }
      setLoad(false)
    } catch (error) {
      // console.log(error)
      setLoad(false)
      setLoadError(error)
    }
  }

  const loadQueryParam = () => {
    const error = getQueryVariable("error")
    if (error !== false) {
      setLoadError({ message: error })
    }
  }

  const getAppSettings = () => {
    store.dispatch(appConfigActions.getAppSettings())
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (myConnection === "") {
      loadAuthTenant()
    }
  }, [myConnection])

  useEffect(() => {
    // eslint-disable-next-line
    getAppSettings()
    loadQueryParam()
  }, [])

  const renderApp = () => {
    if (!myConnection || !myConnection.connection) {
      return null
    }
    const authEndpoint = getAuthUrl()
    const tenantHeader = getTenantHeaders()
    const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint,
      auth: {
        headers: tenantHeader,
      },
    })
    const anchorOrigin = {
      vertical: "top",
      horizontal: "right",
    }

    // console.log("setConnection1:", myConnection.connection)
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        // clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirect_uri={window.location.origin}
        // redirectUri={window.location.origin}
        connection={
          myConnection.connection !== "" ? myConnection.connection : "none"
        }
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={anchorOrigin}
          TransitionComponent={Slide}
          classes={{
            root: classes.snackbarRoot,
          }}
        >
          <PusherProvider value={pusherClient}>
            <ThemeProvider theme={theme}>
              <Routes history={history} />
            </ThemeProvider>
          </PusherProvider>
        </SnackbarProvider>
      </Auth0Provider>
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={loadingComponent} persistor={persistor}>
        {load && loadingComponent}
        {!load && loadError && renderError()}
        {!load && !loadError && renderApp()}
      </PersistGate>
    </Provider>
  )
}

export default App
