import React, { Component } from "react"
// import { connect } from "react-redux"
// import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
// import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
// import authActions from "../../redux/reducers/auth/actions"

class TabPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { children, value, index, ...other } = this.props
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box paddingTop={1} paddingBottom={1} paddingRight={1}>
            {/* {children} */}
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </Typography>
    )
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.any]).isRequired,
  value: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

TabPanel.defaultProps = {
  children: [],
}

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// })

// const mapDispatchToProps = (dispatch) => {
//   return {
//     authActions: bindActionCreators(authActions, dispatch),
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TabPanel)

export default TabPanel
