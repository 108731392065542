const drawerWidth = 200
export const sidePanelWidth = 730

const styles = (theme) => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0, // keep right padding when drawer closed
    borderBottom: "solid 1px #70707032",
    margin: "0 22px",
    backgroundColor: "#F9FAFC",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  panelShift: {
    marginRight: sidePanelWidth + 44,
    width: `calc(100% - ${drawerWidth}px - ${sidePanelWidth}px - ${44}px) !important`,
  },
  filterShift: {
    // marginRight: sidePanelWidth,
    width: 350,
  },
  filterShiftForm: {
    maxWidth: 150,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonLabel: {
    textTransform: "none",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  ".SelectComponentformControl": {},
  root: {},
})

export default styles
