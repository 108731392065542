import React, { Component } from "react"
import _ from "lodash"
import clsx from "clsx"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Drawer from "@material-ui/core/Drawer"
import Typography from "@material-ui/core/Typography"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import CircularProgress from "@material-ui/core/CircularProgress"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ArrowForward from "@material-ui/icons/ArrowForward"
// import ArrowBack from "@material-ui/icons/ArrowBack"
import moment from "moment"
import FadeC from "@material-ui/core/Fade"
import Select from "../Select/ActionSelect"
import styles from "./SidePanel.styles"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import documentActions from "../../redux/reducers/document/actions"
import settings from "../../config/settings"
import ActivitySection from "./ActivitySection"
import CommentSection from "./CommentSection"
import AddComment from "./AddComment"
import DueDateSection from "./DueDateSection"
import Reason from "./Reason"
import DocumentSection from "./DocumentSection"
import DocumentList from "./DocumentList"
import DialogAlert from "../DialogAlert/DialogAlert"
import SnackBar from "../SnackBar/SnackBar"
import TitleSection from "./TitleSection"
import DescriptionSection from "./DescriptionSection"
import DependencySection from "./DependencySection"
import Can from "../Can"
import DocumentPanel from "../DocumentPanel/DocumentPanel"
import SubTaskSection from "./SubTaskSection"
import { getErrorMessage } from "../../common/utils"

const viewTypes = [
  "default",
  // "due-date",
  "add-document",
  "add-comment",
  "reason-required",
]

const reasonRequired = ["blocked", "rejection", "cancelled", "complete"]

const startEnable = ["pending", "blocked"]
const completeEnable = ["inprogress"]

/* eslint-disable */
const ButtonIcon = ({
  style = {},
  fill = "#fff",
  stroke = "#707070",
  width = "100%",
  className = "",
  height = "100%",
  viewBox = "0 0 100 100",
}) => (
  <svg
    // height="100"
    // width="100"
    width={width}
    style={{ height: 30, width: 30 }}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke={stroke}
      strokeWidth="1"
      fill="none"
    />
  </svg>
)
/* eslint-enable */

const Fade = React.forwardRef(function Transition(props, ref) {
  // console.log("Fade in", props.in)
  return <FadeC ref={ref} {...props} />
})

class SidePanel extends Component {
  constructor(props) {
    super(props)
    this.confirmSaveTask = React.createRef()
    this.confirmDeleteTask = React.createRef()
    this.confirmStatus = React.createRef()
    this.confirmAssign = React.createRef()
    this.confirmClientAssign = React.createRef()
    this.confirmTaskPriority = React.createRef()
    this.AddComment = React.createRef()
    this.DocumentSection = React.createRef()
    this.SidePanelContainer = React.createRef()

    this.TitleSection = React.createRef()
    this.DescriptionSection = React.createRef()
    this.DependencySection = React.createRef()
    this.DueDateSection = React.createRef()
    this.SubTaskSection = React.createRef()
    this.state = {
      status: "",
      assignee: "0",
      prevAssignee: "",
      clientAssignee: "0",
      prevClientAssignee: "",
      taskPriority: "",
      prevTaskPriority: "",
      prevStatus: "",
      view: "default",
      reason: "",
      displayA: "comments",
      message: {
        open: false,
        message: "",
        type: "success",
      },
      defaultStartDate: moment(),
      defaultEndDate: moment(),
      defaultDuration: 1,
      defaultDurationType: "days",
      errors: {},
      parentId: "0",
      mainLoad: false,
      // childTasks: [],
      // documentOpen: null,
    }
  }

  componentDidMount() {
    this.setDefaultView()
    this.getDocumentTypesList()
    this.getDependencyTypeList()
    this.getProjectTasksPriorityList()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (
      (!_.isEqual(task.open, nextTask.open) ||
        !_.isEqual(task.task, nextTask.task)) &&
      nextTask.open
    ) {
      if (nextTask.task) {
        this.setDefaultView()
        this.getTaskData(nextTask.task)
        this.setInitialData(nextTask.task)
        this.scrollToTop()
      }
    }
  }

  getDependencyTypeList = () => {
    const {
      taskActions: { getDependencyTypeList },
    } = this.props
    getDependencyTypeList()
  }

  getProjectTasksPriorityList = () => {
    const {
      taskActions: { getProjectTasksPriorityList },
    } = this.props
    getProjectTasksPriorityList()
  }

  getTaskData = async (nextTask = null, refresh = false) => {
    const {
      task: { task: currentTask },
      taskActions: { setTask },
      apiActions: { apiCall },
    } = this.props
    let task = currentTask
    if (nextTask) {
      task = nextTask
    }

    if (!task || !task.id) {
      return
    }
    this.setState({ mainLoad: true })
    try {
      const responseJson = await apiCall(
        settings.endpoints.getTask(task.id),
        "get",
        {}
      )
      if (responseJson && responseJson.success) {
        if (!nextTask) {
          setTask(
            {
              ...responseJson.data,
              isDraft: task && task.isDraft,
            },
            refresh
          )
        }
      }
      this.setState({ mainLoad: false })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      this.setState({ mainLoad: false })
    }
  }

  getDocumentTypesList = () => {
    const {
      taskActions: { getDocumentTypesList },
    } = this.props
    getDocumentTypesList()
  }

  setDefaultView = (displayA = null) => {
    // "comments"
    // "documents"
    this.setState({
      view: "default",
      reason: "",
      displayA: displayA || "comments",
    })
  }

  setInitialData = (task) => {
    // const { statusList } = this.state
    const {
      task: { statusList },
    } = this.props
    let status = ""
    let assignee = ""
    let clientAssignee = ""
    let defaultStartDate = moment()
    let defaultEndDate = moment()
    let defaultDuration = 1
    let defaultDurationType = "days"
    let taskPriority = ""
    let { parentId } = this.state
    // console.log("setInitialData", task)
    if (task && _.isNumber(task.status)) {
      const sobj = _.find(statusList, (i) => i.id === task.status)
      if (sobj) {
        status = sobj.status_code
      }
    }
    if (task && task.assign_to) {
      // eslint-disable-next-line eqeqeq
      if (task.assign_to == 0) {
        assignee = "0"
      } else {
        assignee = task.assign_to
      }
    }

    if (task && task.client_assignee_id) {
      // eslint-disable-next-line eqeqeq
      if (task.client_assignee_id == 0) {
        clientAssignee = "0"
      } else {
        clientAssignee = task.client_assignee_id
      }
    }

    if (task && task.start_date) {
      defaultStartDate = moment(task.start_date)
    }
    if (task && task.end_date) {
      defaultEndDate = moment(task.end_date)
    }

    if (task && task.duration) {
      defaultDuration = task.duration
    }

    if (task && task.duration_type) {
      if (task.duration_type === "D" || task.duration_type === "d") {
        defaultDurationType = "days"
      }

      if (task.duration_type === "H" || task.duration_type === "h") {
        defaultDurationType = "hours"
      }
    }
    const isDraft = task && task.isDraft
    if (isDraft) {
      parentId = "0"
    }

    if (_.isNumber(task.priority_id) && task.priority_id > 0) {
      taskPriority = task.priority_id
    }

    this.setState({
      status,
      assignee,
      clientAssignee,
      defaultStartDate,
      defaultEndDate,
      defaultDuration,
      defaultDurationType,
      parentId,
      taskPriority,
    })
  }

  handleClose = () => {
    const {
      taskActions: { setOpen, setTask },
    } = this.props
    // this.setState({ documentOpen: null })
    this.setDocument(null)
    setOpen(false)
    setTask(null)
  }

  cancel = () => {
    // const {
    // task: { task },
    // classes,
    // } = this.props
    // const isDraft = task && task.isDraft
    this.setState({ view: "default" }, () => {
      // if (isDraft || true) {
      this.handleClose()
      // }
    })
  }

  completeTask = async () => {
    this.onChangeStatus({ target: { value: "complete" } })
  }

  getTaskDataFromId = (id) => {
    const {
      apiActions: { apiCall },
    } = this.props
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      // let task = currentTask
      try {
        const responseJson = await apiCall(
          settings.endpoints.getTask(id),
          "get",
          {}
        )
        if (responseJson && responseJson.success) {
          // if (!nextTask) {
          resolve({
            ...responseJson.data,
            // isDraft: task && task.isDraft,
          })
          // }
        } else {
          resolve(null)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log(error)
        resolve(null)
      }
    })
  }

  afterFinalStartTask = () => {
    this.onChangeStatus({ target: { value: "inprogress" } })
  }

  startTask = async () => {
    const {
      task: { task },
      // classes,
    } = this.props
    if (task) {
      try {
        // eslint-disable-next-line camelcase
        const { project_task_dependencies } = task
        let allowed = true
        let sMessage = ""
        if (
          _.isArray(project_task_dependencies) &&
          project_task_dependencies.length
        ) {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ starting: true })
          const promises = project_task_dependencies.map((ele) => {
            return this.getTaskDataFromId(ele.predecessor_id)
          })
          const results = await Promise.all(promises)
          // console.log(results)
          if (results.length) {
            // eslint-disable-next-line array-callback-return
            results.map((ele) => {
              allowed = allowed && ele && ele.status === 5
              if (ele && ele.status !== 5) {
                if (sMessage !== "") {
                  sMessage += " and "
                }
                sMessage += ele.task_name
              }
            })
          }
          // console.log(project_task_dependencies)
        }
        if (sMessage !== "") {
          sMessage += " needs to be completed before starting this task"
        }

        // eslint-disable-next-line react/no-unused-state
        this.setState({ starting: false, sMessage }, () => {
          if (!allowed) {
            if (this.confirmStartTask) {
              this.confirmStartTask.open()
            }
          } else {
            this.afterFinalStartTask()
          }
        })
      } catch (error) {
        // console.log(error)
        // eslint-disable-next-line react/no-unused-state
        this.setState({ starting: false, sMessage: "" }, () => {
          this.setMessage({
            message: "Unable to process Due to Network / Server Error",
            type: "error",
            open: true,
          })
        })
      }
    }
  }

  setMessage = (message) => {
    this.setState({ message })
  }

  setDocument = (v) => {
    const {
      documentActions: { setDocument },
    } = this.props
    setDocument(v)
  }

  handleCloseSnackbar = (event, reason) => {
    const { message } = this.state
    // console.log(reason);
    if (reason === "clickaway") {
      this.refreshafterMessage()
      return
    }
    this.setState(
      {
        message: {
          ...message,
          open: false,
        },
      },
      this.refreshafterMessage
    )
  }

  refreshafterMessage = () => {
    const { message } = this.state
    if (message.type === "success") {
      // console.log("refreshafterMessage")
      // this.getTaskData()
    }
  }

  renderError = (key) => {
    const { errors } = this.state
    return (
      <Typography color="error" style={{ fontSize: 12 }}>
        {errors[key] || ""}
      </Typography>
    )
  }

  renderComments = () => {
    // const { classes } = this.props
    const { displayA } = this.state
    if (displayA === "comments") {
      return <CommentSection key="comments_list" />
    }

    if (displayA === "documents") {
      return (
        <DocumentList
          ref={(o) => {
            this.DocumentList = o
          }}
          key="documents_list"
          setMessage={this.setMessage}
          onRowClick={(item) => {
            // this.setState({ documentOpen: item })
            this.setDocument(item)
          }}
        />
      )
    }
    return <ActivitySection />
  }

  renderCommentsWrapper = () => {
    return (
      <Container
        key="comments_list_box"
        style={{ padding: 0, height: "unset" }}
      >
        {this.renderComments()}
      </Container>
    )
  }

  renderActivityButtons = () => {
    // const { classes } = this.props
    const { displayA } = this.state
    return (
      <Box paddingY={1} key="activity_buttons_box">
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <ToggleButtonGroup
            size="small"
            value={displayA}
            exclusive
            color="primary"
            onChange={(event, newAlignment) =>
              this.setState({ displayA: newAlignment, view: "default" })
            }
          >
            <ToggleButton key="comments_toggle_button" value="comments">
              Comments
            </ToggleButton>
            <ToggleButton key="activities_toggle_button" value="activities">
              Activities
            </ToggleButton>
            <ToggleButton key="documents_toggle_button" value="documents">
              Documents
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Box>
    )
  }

  renderToolabr = () => {
    const {
      classes,
      task: { task },
    } = this.props
    return (
      <AppBar
        color="transparent"
        position="relative"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar disableGutters>
          <IconButton onClick={this.handleClose}>
            <ArrowForward />
          </IconButton>
          <Typography
            component="h6"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {task && task.task_name ? task.task_name : ""}
          </Typography>
        </Toolbar>
      </AppBar>
    )
  }

  onChange = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    })
  }

  updateStatusApi = async () => {
    const {
      // auth: { userdata },
      task: { clientAssigneeList, statusList, task, assigneeList },
      apiActions: { apiCall },
    } = this.props
    const { status, reason, prevStatus, assignee, clientAssignee } = this.state
    const dObj = { id: "0", name: "" }
    // eslint-disable-next-line eqeqeq
    const sAObj = _.find(assigneeList, (e) => e.id == assignee)
    const sCObj =
      _.find(clientAssigneeList, (e) => e.id === clientAssignee) || dObj
    const sObj = _.find(statusList, (e) => e.status_code === status)
    const pObj = _.find(statusList, (e) => e.status_code === prevStatus)
    try {
      const data = {
        update_type: "status",
        status: sObj ? sObj.id : 1,
        client_assignee_id: sCObj.id,
        assign_to: sAObj && sAObj.id ? sAObj.id : "0",
        reason,
      }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "put",
        data
      )
      if (responseJson && responseJson.success) {
        this.addActivityApi(
          `Updated status ${
            pObj && pObj.status_desc ? `from ${pObj.status_desc}` : ""
          } to ${status}`
        )
      }
      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  addActivityApi = async (message, setDefault = false) => {
    const {
      auth: { userdata },
      task: { task },
      apiActions: { apiCall },
    } = this.props

    if (!task || !task.id) {
      return
    }
    try {
      const data = {
        project_task_id: task.id,
        type: "activity",
        message,
        /* to be updated with logged in user */
        // updated_by: userdata.id,
        updated_by: userdata.auth_user_id,
      }
      // eslint-disable-next-line no-unused-vars
      const responseJson = await apiCall(
        settings.endpoints.addActivity,
        "post",
        data
      )
      if (setDefault) {
        this.setDefaultView(setDefault)
      }
      this.getTaskData(null, true)
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
    }
  }

  assignApi = async () => {
    const {
      auth: { userdata },
      task: { task, assigneeList },
      apiActions: { apiCall },
    } = this.props
    const { assignee, prevAssignee } = this.state
    // const { status, reason, prevStatus } = this.state
    const sObj = _.find(assigneeList, (e) => e.id === assignee)
    const pObj = _.find(assigneeList, (e) => e.id === prevAssignee)
    try {
      const data = {
        update_type: "assignee",
        previous_assign_to: pObj && pObj.id ? pObj.id : "0",
        assign_to: sObj && sObj.id ? sObj.id : "0",
        reason: "",
      }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "put",
        data
      )
      if (responseJson && responseJson.success) {
        this.addActivityApi(
          `${userdata.name || ""} Updated assignee from ${
            pObj && pObj.name ? pObj.name : ""
          } to ${sObj && sObj.name ? sObj.name : ""}`
        )
      }
      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  afterChangeAssignee = () => {
    const {
      task: { task },
    } = this.props
    const { assignee } = this.state
    const isDraft = task && task.isDraft
    if (!isDraft) {
      if (assignee !== "") {
        if (this.confirmAssign) {
          this.confirmAssign.open()
        }
      }
    }
  }

  afterChangeClientAssignee = () => {
    const {
      task: { task },
    } = this.props
    const { clientAssignee } = this.state
    const isDraft = task && task.isDraft
    if (!isDraft) {
      if (clientAssignee !== "") {
        if (this.confirmClientAssign) {
          this.confirmClientAssign.open()
        }
      }
    }
  }

  afterChangeTaskPriority = () => {
    const {
      task: { task },
    } = this.props
    const { taskPriority } = this.state
    const isDraft = task && task.isDraft
    if (!isDraft) {
      if (taskPriority !== "") {
        if (this.confirmTaskPriority) {
          this.confirmTaskPriority.open()
        }
      }
    }
  }

  onChangeAssignee = (e) => {
    const { assignee, errors } = this.state
    this.setState(
      {
        assignee: e.target.value,
        prevAssignee: assignee,
        errors: {
          ...errors,
          assignee: undefined,
        },
      },
      this.afterChangeAssignee
    )
  }

  onChangeClientAssignee = (e) => {
    const { clientAssignee, errors } = this.state
    this.setState(
      {
        clientAssignee: e.target.value,
        prevClientAssignee: clientAssignee,
        errors: {
          ...errors,
          clientAssignee: undefined,
        },
      },
      this.afterChangeClientAssignee
    )
  }

  onChangeTaskPriority = (e) => {
    const { taskPriority, errors } = this.state
    this.setState(
      {
        taskPriority: e.target.value,
        prevTaskPriority: taskPriority,
        errors: {
          ...errors,
          taskPriority: undefined,
        },
      },
      this.afterChangeTaskPriority
    )
  }

  onClientAssigneeConfirm = async () => {
    const {
      auth: { userdata },
      task: { task, clientAssigneeList, assigneeList },
      apiActions: { apiCall },
    } = this.props
    if (!task || !task.id) {
      return
    }
    const { clientAssignee, prevClientAssignee } = this.state
    const { assignee } = this.state
    const dObj = { id: "0", name: "" }
    // eslint-disable-next-line eqeqeq
    const sAObj = _.find(assigneeList, (e) => e.id == assignee)
    const sObj =
      _.find(clientAssigneeList, (e) => e.id === clientAssignee) || dObj
    const pObj =
      _.find(clientAssigneeList, (e) => e.id === prevClientAssignee) || dObj
    try {
      const data = {
        update_type: "client_assignee_id",
        previous_client_assignee_id: pObj.id,
        client_assignee_id: sObj.id,
        assign_to: sAObj && sAObj.id ? sAObj.id : "0",
        reason: "",
      }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task.id),
        "put",
        data
      )
      if (responseJson && responseJson.success) {
        this.addActivityApi(
          `${userdata.name || ""} Updated client assignee from ${
            pObj && pObj.name ? pObj.name : ""
          } to ${sObj && sObj.name ? sObj.name : ""}`
        )
      }
      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  onTaskPriorityConfirm = async () => {
    // do api call here
    const {
      auth: { userdata },
      task: { task, taskPriorityList },
      apiActions: { apiCall },
    } = this.props
    if (!task || !task.id) {
      return
    }
    const { taskPriority, prevTaskPriority } = this.state
    const dObj = { id: "0", name: "" }
    const sObj = _.find(taskPriorityList, (e) => e.id === taskPriority) || dObj
    const pObj =
      _.find(taskPriorityList, (e) => e.id === prevTaskPriority) || dObj
    try {
      const data = {
        update_type: "priority_id",
        previous_priority_id: pObj.id,
        priority_id: sObj.id,
        reason: "",
      }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task.id),
        "put",
        data
      )
      if (responseJson && responseJson.success) {
        this.addActivityApi(
          `${userdata.name || ""} Updated task priority from ${
            pObj && pObj.name ? pObj.name : ""
          } to ${sObj && sObj.name ? sObj.name : ""}`
        )
      }
      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  onAssigneeConfirm = () => {
    this.assignApi()
  }

  createTaskApi = async () => {
    const {
      // auth: { userdata },
      task: { project, statusList },
      apiActions: { apiCall },
      taskActions: { setTask, getTasks, getParentTaskList },
    } = this.props
    const { status, assignee, parentId } = this.state
    try {
      const sObj = _.find(statusList, (e) => e.status_code === status)

      let titleState = {}
      if (this.TitleSection) {
        // console.log(this.TitleSection)
        const tS = this.TitleSection.validate()
        if (tS) {
          titleState = tS
        }
      }

      let descState = {}
      if (this.DescriptionSection) {
        // console.log(this.DescriptionSection)
        const tS = this.DescriptionSection.validate()
        if (tS) {
          descState = tS
        }
      }
      let dependencyState = {}
      if (this.DependencySection) {
        // console.log(this.DependencySection)
        const tS = this.DependencySection.validate()
        if (tS) {
          dependencyState = tS
        }
      }
      let dueDateState = {}
      if (this.DueDateSection) {
        // console.log(this.DueDateSection)
        const tS = this.DueDateSection.validate()
        if (tS) {
          dueDateState = tS
        }
      }
      const reqdata = {
        project_id: project.id,
        parent_id: parentId,
        legal_entity: "legal_entity",
        department: "department",
        assign_to: assignee,
        task_type: 0,
        status: sObj && sObj.id ? sObj.id : 0,
        is_active: 1,
        ref_template_task: 0,
        ...titleState,
        ...descState,
        ...dependencyState,
        ...dueDateState,
      }
      const endpoint = settings.endpoints.createTask
      const responseJson = await apiCall(endpoint, "post", reqdata)

      if (responseJson && responseJson.success) {
        getParentTaskList()
        if (responseJson.data) {
          // console.log("set new task and refresh data")
          setTask({ ...responseJson.data })
          getTasks(0, true)
        }
      }

      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson && responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      const message = getErrorMessage(error)
      this.setMessage({
        message:
          message !== ""
            ? message
            : "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  /*
  Save task

  */
  saveTaskApi = async () => {
    const {
      auth: { userdata },
      task: { task },
      apiActions: { apiCall },
      // taskActions: { getParentTaskList },
    } = this.props

    // console.log("_SAVE_TASK_DATA", task)
    // console.log("_SAVE_TASK_USER_DATA", userdata)
    // console.log("_SAVE_TASK_STATE", this.state)

    try {
      let titleState = {}
      if (this.TitleSection) {
        // console.log(this.TitleSection)
        const tS = this.TitleSection.validate()
        if (tS) {
          titleState = tS
        }
      }

      let descState = {}
      if (this.DescriptionSection) {
        // console.log(this.DescriptionSection)
        const tS = this.DescriptionSection.validate()
        if (tS) {
          descState = tS
        }
      }
      let dependencyState = {}
      if (this.DependencySection) {
        // console.log(this.DependencySection)
        const tS = this.DependencySection.validate()
        if (tS) {
          dependencyState = tS
        }
      }
      let dueDateState = {}
      if (this.DueDateSection) {
        // console.log(this.DueDateSection)
        const tS = this.DueDateSection.validate()
        if (tS) {
          dueDateState = tS
        }
      }

      const data = {
        project_id: task.project_id,
        parent_id: task.parent_id,
        task_name: task.task_name,
        task_desc: task.task_desc,
        start_date: task.start_date,
        end_date: task.end_date,
        legal_entity: task.legal_entity,
        department: task.department,
        assign_to: task.assign_to,
        duration: task.duration,
        duration_type: task.duration_type,
        task_type: task.task_type,
        status: task.status,
        is_active: task.is_active,
        ...titleState,
        ...descState,
        ...dependencyState,
        ...dueDateState,
        project_task_dependencies: undefined,
        isDraft: undefined,
        // assign_to: undefined,
        // status: undefined,
      }

      // eslint-disable-next-line no-console
      // console.log("data", data)

      // const reqdata = {
      //   "project_id":1,
      //   "parent_id":2,
      //   "task_name":"Test Name",
      //   "task_desc":"Task Description Goes Here",
      //   "start_date":"2020-04-21",
      //   "end_date":"2020-04-21",
      //   "legal_entity":"Investment Manager",
      //   "department":"Investment Committee",
      //   "assign_to":"Mukhtiar Singh",
      //   "duration":2,
      //   "duration_type":"D",
      //   "task_type":0,
      //   "status":0,
      //   "is_active":1,
      //   "dependencies":[
      //     {
      //                 "id": 197,
      //                 "project_task_id": 244,
      //                 "predecessor_id": 0,
      //                 "successor_id": 2,
      //                 "type_id": 1
      //             },
      //             {
      //                 "id": 198,
      //                 "project_task_id": 244,
      //                 "predecessor_id": 5,
      //                 "successor_id": 0,
      //                 "type_id": 2
      //             }
      //   ]
      // }

      // if (this.state.taskName) {
      //   data.task_name = this.state.taskName
      // }

      // if (this.state.taskDesc) {
      //   data.task_desc = this.state.taskDesc
      // }

      // data.update_type = "task"
      // console.log("_SAVE_TASK_SUBMITTED_DATA", data)

      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "put",
        data
      )
      if (responseJson && responseJson.success) {
        this.addActivityApi(`${userdata.name || ""} Updated task info`)
        // here update parent tasks
        // getParentTaskList()
      }

      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  saveTask = () => {
    let valid = true
    const errors = {}
    // const { parentId } = this.state
    // const {
    // task: { task },
    // } = this.props
    // const isDraft = task && task.isDraft
    // const { status, assignee } = this.state
    // if (status === "") {
    //   valid = false
    //   errors.status = "Please select status"
    // }
    // if (assignee === "") {
    //   valid = false
    //   errors.assignee = "Please select assignee"
    // }
    // if (parentId == "0" && isDraft) {
    //   valid = false
    //   errors.parentId = "Please select parent task"
    // }
    if (this.TitleSection) {
      const tS = this.TitleSection.validate()
      if (!tS) {
        valid = false
      }
    }

    if (this.DescriptionSection) {
      const tS = this.DescriptionSection.validate()
      if (!tS) {
        valid = false
      }
    }
    if (this.DependencySection) {
      const tS = this.DependencySection.validate()
      if (!tS) {
        valid = false
      }
    }
    if (this.DueDateSection) {
      const tS = this.DueDateSection.validate()
      if (!tS) {
        valid = false
      }
    }
    if (valid) {
      if (this.confirmSaveTask) {
        this.confirmSaveTask.open()
      }
    } else {
      this.setState({ errors })
    }
  }

  onSaveTaskConfirm = () => {
    const {
      task: { task },
    } = this.props
    const isDraft = task && task.isDraft
    if (isDraft) {
      this.createTaskApi()
    } else {
      this.saveTaskApi()
    }
  }

  onStatusConfirm = () => {
    const { view, status } = this.state
    const askReason = false
    if (askReason && reasonRequired.indexOf(status) > -1) {
      this.setState({ view: "reason-required" })
    } else {
      if (view === "reason-required") {
        this.setState({ view: "default" })
      }
      this.updateStatusApi()
    }
  }

  afterChangeStatus = () => {
    const {
      task: { task },
    } = this.props
    const isDraft = task && task.isDraft
    const { status } = this.state
    if (!isDraft) {
      if (status !== "") {
        if (this.confirmStatus) {
          this.confirmStatus.open()
        }
      }
    }
  }

  onChangeStatus = (e) => {
    const { status, errors } = this.state
    this.setState(
      {
        status: e.target.value,
        prevStatus: status,
        errors: {
          ...errors,
          status: undefined,
        },
      },
      this.afterChangeStatus
    )
  }

  scrollToTop = () => {
    if (this.SidePanelContainer && this.SidePanelContainer.current) {
      this.SidePanelContainer.current.scrollTop = 0
    }
  }

  scrollToInput = () => {
    if (this.AddComment) {
      if (this.AddComment.input && this.AddComment.input.current) {
        const target = this.AddComment.input.current
        this.SidePanelContainer.current.scrollTop = target.offsetTop
      }
    }
  }

  scrollToDocument = () => {
    if (this.DocumentSection) {
      // if (this.DocumentSection.input && this.DocumentSection.input.current) {
      //   const target = this.DocumentSection.input.current
      //   this.SidePanelContainer.current.scrollTop = target.offsetTop
      // }
      if (
        this.DocumentSection.cardRef &&
        this.DocumentSection.cardRef.current
      ) {
        const target = this.DocumentSection.cardRef.current
        this.SidePanelContainer.current.scrollTop = target.offsetTop
      }
    }
  }

  setView = (view) => () => {
    const obj = {
      view,
    }
    if (view === "add-comment") {
      obj.displayA = "comments"
    }
    this.setState(obj, () => {
      if (view === "add-comment") {
        // this.AddComment.scrollToInputAndFocus()
        setTimeout(this.scrollToInput, 500)
      } else if (view === "add-document") {
        if (this.DocumentSection) {
          // console.log("this.DocumentSection", this.DocumentSection)
          this.DocumentSection.reset()
        }
        setTimeout(this.scrollToDocument, 500)
      }
    })
  }

  deleteTask = () => {
    // console.log(this.confirmDeleteTask)
    if (this.confirmDeleteTask) {
      this.confirmDeleteTask.open()
    }
  }

  deleteTaskApi = async () => {
    const {
      // auth: { userdata },
      task: { task },
      apiActions: { apiCall },
      taskActions: { getTasks },
    } = this.props
    try {
      const data = {}
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "delete",
        data
      )
      if (responseJson.success) {
        this.handleClose()
        getTasks(0, true)
      }
      this.setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      this.setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  renderTItem = (value) => {
    const { view } = this.state
    // const { classes } = this.props
    if (value === "default" || value === "reason-required") {
      return null
    }
    // const labelId = `checkbox-list-label-${value}`
    const checked = view === value
    // const { task } = this.props
    const {
      task: { task },
    } = this.props
    const isDraft = task && task.isDraft

    if (isDraft) {
      return null
    }
    return (
      <Button
        key={`${value}_tab_button`}
        fullWidth
        variant="outlined"
        // color="primary"
        disableElevation
        // disabled={isDraft}
        onClick={this.setView(value)}
        style={{
          marginTop: 5,
          marginBottom: 5,
          justifyContent: "flex-start",
          backgroundColor: checked ? "#436EE0" : "#0000",
        }}
        startIcon={
          checked ? (
            <ButtonIcon stroke="#FFF" />
          ) : (
            <ButtonIcon stroke="#707070" />
          )
        }
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "normal",
            textTransform: "capitalize",
            color: checked ? "#FFF" : "#707070",
          }}
        >
          {value.replace("-", " ")}
        </Typography>
      </Button>
    )
  }

  renderTypeItems = () => {
    // const { isDraft, classes, status, starting } = this.props
    const { classes } = this.props
    return (
      <Box pt={0.5}>
        <Typography variant="body2" className={classes.actionPanelHeader}>
          ACTIONS:
        </Typography>
        <Box p={0} display="flex" flexDirection="column">
          {this.renderTaskStatus()}
          {this.renderAssignee()}
          {this.renderClientAssigneeList()}
          {this.renderTaskPriorityList()}
          {viewTypes.map((value) => {
            return this.renderTItem(value)
          })}
        </Box>
      </Box>
    )
  }

  renderLoading = () => {
    const { classes } = this.props
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loadingView}
      >
        <CircularProgress size={50} />
      </Grid>
    )
  }

  renderMainLoading = () => {
    const { classes } = this.props
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.mainView}
      >
        <CircularProgress size={50} />
      </Grid>
    )
  }

  renderContent = () => {}

  renderTaskStatus = () => {
    const {
      classes,
      task: { task, statusList },
    } = this.props
    const { status } = this.state
    const isDraft = task && task.isDraft
    return (
      <Box>
        {/* //TODO - Move to left hand side of right panel
        <Typography variant="body2">{`ID :#${
          task && task.id ? task.id : ""
        }`}</Typography> */}

        <FormControl fullWidth className={classes.inputRow}>
          <Select
            disableUnderline
            value={status}
            defaultValue={status}
            onChange={this.onChangeStatus}
            displayEmpty
            fullWidth
            inputProps={{
              "aria-label": "Without label",
              style: {
                margin: "10px 0",
              },
            }}
          >
            <MenuItem value="">Status</MenuItem>
            {statusList.map((mItem) => {
              if (isDraft) {
                // if (
                //   mItem.status_code !== "draft" &&
                //   mItem.status_code !== "pending"
                // ) {
                //   return null
                // }
              } else if (!isDraft && mItem.status_code === "draft") {
                return null
              }

              return (
                <MenuItem
                  key={`menu_item_status_${mItem.status_code}`}
                  value={mItem.status_code}
                >
                  {mItem.status_desc}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("status")}
        </FormControl>
      </Box>
    )
  }

  renderAssignee = () => {
    const {
      classes,
      task: { assigneeList },
    } = this.props
    const { assignee } = this.state
    return (
      <Box p={0}>
        <FormControl fullWidth className={classes.inputRow}>
          <Select
            disableUnderline
            value={
              assignee === "Unassigned" || assignee === "" || assignee === 0
                ? 0
                : assignee
            }
            fullWidth
            onChange={this.onChangeAssignee}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="0">Assignee</MenuItem>
            {assigneeList.map((ele) => {
              // eslint-disable-next-line eqeqeq
              if (!ele.id || ele.id == 0) {
                return null
              }
              return (
                <MenuItem
                  // key={`menu_item_status_${ele.id}`}
                  key={`${ele.id}_${ele.name}`}
                  value={ele.id}
                >
                  {ele.name}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("assignee")}
        </FormControl>
      </Box>
    )
  }

  renderClientAssigneeList = () => {
    const {
      classes,
      task: { clientAssigneeList },
    } = this.props
    const { clientAssignee } = this.state
    return (
      <Box p={0}>
        <FormControl fullWidth className={classes.inputRow}>
          <Select
            disableUnderline
            value={
              clientAssignee === "Unassigned" ||
              clientAssignee === "" ||
              clientAssignee === 0
                ? 0
                : clientAssignee
            }
            fullWidth
            onChange={this.onChangeClientAssignee}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="0">External Assignee</MenuItem>
            {clientAssigneeList.map((ele) => {
              // eslint-disable-next-line eqeqeq
              if (!ele.id || ele.id == 0) {
                return null
              }
              return (
                <MenuItem key={`${ele.id}_${ele.name}`} value={ele.id}>
                  {ele.name}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("clientAssignee")}
        </FormControl>
      </Box>
    )
  }

  renderTaskPriorityList = () => {
    const {
      classes,
      task: { taskPriorityList },
    } = this.props
    const { taskPriority } = this.state
    // console.log("taskPriority", taskPriority)
    return (
      <Box p={0}>
        <FormControl
          fullWidth
          className={clsx(classes.inputRow, classes.capitalize)}
        >
          <Select
            disableUnderline
            value={
              !taskPriority || taskPriority === "" || taskPriority === 0
                ? ""
                : taskPriority
            }
            fullWidth
            onChange={this.onChangeTaskPriority}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">Task Priority</MenuItem>
            {taskPriorityList.map((ele) => {
              // eslint-disable-next-line eqeqeq
              // if (!ele.id || ele.id == 0) {
              //   return null
              // }
              return (
                <MenuItem
                  key={`${ele.id}_${ele.name}`}
                  value={ele.id}
                  className={classes.capitalize}
                >
                  {ele.name || ""}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("clientAssignee")}
        </FormControl>
      </Box>
    )
  }

  onChangeParentTask = (e) => {
    const { errors } = this.state
    this.setState({
      parentId: e.target.value,
      errors: {
        ...errors,
        parentId: undefined,
      },
    })
  }

  renderParentTaskOption = () => {
    const {
      classes,
      task: { parentTaskList },
    } = this.props
    const { parentId } = this.state
    // console.log("parentTaskList", parentTaskList)
    return (
      <Box
        paddingY={2}
        paddingX={4}
        style={{
          borderTop: "1px solid #70707032",
          paddingBottom: 8,
          marginTop: 20,
        }}
      >
        <FormControl fullWidth className={classes.inputRow}>
          <Typography className={classes.dateLabel}>Parent Task</Typography>
          <Select
            disableUnderline
            value={
              // eslint-disable-next-line eqeqeq
              !parentId || parentId === "" || parentId == 0 ? "0" : parentId
            }
            fullWidth
            onChange={this.onChangeParentTask}
            displayEmpty
            // inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="0">No Parent</MenuItem>
            {parentTaskList.map((ele) => {
              return (
                <MenuItem key={`${ele.id}_${ele.name}`} value={ele.id}>
                  {ele.task_name}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("parentId")}
        </FormControl>
      </Box>
    )
  }

  renderSnackBar = () => {
    const { message } = this.state
    return <SnackBar message={message} afterClose={this.handleCloseSnackbar} />
  }

  renderDueDateSection = () => {
    const {
      defaultStartDate,
      defaultEndDate,
      defaultDuration,
      defaultDurationType,
    } = this.state
    const {
      task: { task },
      auth: { userdata },
    } = this.props

    const userRoles = userdata["https://saffronios.com//roles"]
    const userHasAccess = Can({
      roleLevel: 3,
      role: userRoles[0],
      yes: () => true,
      no: () => false,
    })

    let disabled = false

    if (task && task.parent_id === 0) {
      if (task.has_children) {
        disabled = true
      }
    }

    if (!userHasAccess) {
      disabled = true
    }

    // console.log("disabled", disabled)
    return (
      <DueDateSection
        ref={(o) => {
          this.DueDateSection = o
        }}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        defaultDuration={defaultDuration}
        defaultDurationType={defaultDurationType}
        setMessage={this.setMessage}
        disabled={disabled}
        addActivityApi={this.addActivityApi}
      />
    )
  }

  renderCommentSection = () => {
    const { task } = this.props
    const { view } = this.state
    const isDraft = task.task && task.task.isDraft
    if (!isDraft && (view === "default" || view === "add-comment")) {
      return [
        this.renderActivityButtons(),
        this.renderCommentsWrapper(),
        view === "add-comment" && (
          <AddComment
            ref={(o) => {
              this.AddComment = o
            }}
            setMessage={this.setMessage}
            addActivityApi={this.addActivityApi}
            setDefaultView={this.setDefaultView}
          />
        ),
      ]
    }
    return null
  }

  getDocumentStatus = (row) => {
    // console.log('id === "status"', row)
    let status = 0
    // console.log("row.revisions", row.revisions)
    if (row && _.isArray(row.revisions)) {
      const sObj = _.find(
        row.revisions,
        (i) => i.document_id === row.document_id
      )
      // console.log("sObj", sObj)
      if (sObj) {
        status = sObj.status_id
      } else {
        // eslint-disable-next-line no-console
        console.log("getDocumentStatus error", row)
      }
    }
    return status
  }

  onSuccessDocPanel = () => {
    if (this.DocumentList) {
      this.DocumentList.getDocuments()
    }
  }

  renderDocumentView = () => {
    return (
      <DocumentPanel
        setMessage={this.setMessage}
        onSuccess={this.onSuccessDocPanel}
      />
    )
  }

  // renderDocumentView = () => {
  //   const {
  //     // task,
  //     classes,
  //     // auth: { userdata },
  //   } = this.props
  //   const { documentOpen } = this.state
  //   // console.log("documentOpen", documentOpen)
  //   const status = this.getDocumentStatus(documentOpen)
  //   return (
  //     <div className={classes.flex}>
  //       <Grid container className={classes.documentViewHeader}>
  //         <Grid
  //           item
  //           xs={12}
  //           sm={12}
  //           md={12}
  //           lg={5}
  //           xl={5}
  //           className={classes.documentViewHeaderPart}
  //           justify="center"
  //           alignItems="flex-start"
  //           direction="column"
  //         >
  //           <Typography
  //             noWrap
  //             variant="body2"
  //             className={classes.documentViewText}
  //           >
  //             {documentOpen.name || ""}
  //           </Typography>
  //           <Typography
  //             variant="caption"
  //             noWrap
  //             className={classes.documentViewSubText}
  //             style={{ color: "#000" }}
  //           >
  //             {documentOpen.description || ""}
  //           </Typography>
  //         </Grid>
  //         <Grid
  //           item
  //           xs={12}
  //           sm={12}
  //           md={12}
  //           lg={5}
  //           xl={5}
  //           className={classes.documentViewHeaderPart}
  //           justify="center"
  //           alignItems="flex-start"
  //           direction="column"
  //         >
  //           <Typography
  //             noWrap
  //             className={classes.documentViewSubText}
  //             variant="body2"
  //           >
  //             {`Version: `}
  //             <Typography
  //               style={{ color: "#000" }}
  //               noWrap
  //               className={clsx(classes.documentViewSubText, classes.Inline)}
  //               // variant="caption"
  //             >
  //               {documentOpen.version || ""}
  //             </Typography>
  //           </Typography>
  //           <Typography
  //             className={classes.documentViewSubText}
  //             variant="caption"
  //             noWrap
  //           >
  //             {`Status: `}
  //             <DocumentStatus
  //               text
  //               status={status}
  //               className={clsx(
  //                 classes.documentViewSubText,
  //                 classes.Inline,
  //                 classes.blackText
  //               )}
  //             />
  //           </Typography>
  //         </Grid>
  //         <Grid
  //           item
  //           xs={12}
  //           sm={12}
  //           md={12}
  //           lg={2}
  //           xl={2}
  //           className={classes.documentViewHeaderPart}
  //           alignItems="center"
  //           justify="flex-end"
  //           direction="row"
  //         >
  //           <IconButton
  //             size="medium"
  //             disableRipple
  //             disableFocusRipple
  //             disableTouchRipple
  //             // color="secondary"
  //             className={clsx(
  //               classes.documentViewIconButton,
  //               classes.blueIconButton
  //             )}
  //             onClick={(evt) => {
  //               evt.preventDefault()
  //               window.open(documentOpen.path, "_blank")
  //             }}
  //             // style={{ color: "royalblue" }}
  //           >
  //             <GetApp className={classes.documentViewIcons} />
  //           </IconButton>
  //           <IconButton
  //             size="medium"
  //             disableRipple
  //             disableFocusRipple
  //             disableTouchRipple
  //             // color="secondary"
  //             // style={{ color: "indianred" }}
  //             onClick={() => {
  //               this.setState({ documentOpen: null })
  //             }}
  //             className={clsx(
  //               classes.documentViewIconButton,
  //               classes.redIconButton
  //             )}
  //           >
  //             <CloseRounded className={classes.documentViewIcons} />
  //           </IconButton>
  //         </Grid>
  //       </Grid>
  //       {/* <Grid container className={classes.docViewContainer}> */}
  //       <DocumentViewer document={documentOpen} />
  //       {/* </Grid> */}
  //     </div>
  //   )
  // }

  renderPanelView = () => {
    const {
      task,
      classes,
      auth: { userdata },
    } = this.props
    const userRoles = userdata["https://saffronios.com//roles"]
    const {
      view,
      status,
      prevStatus,
      prevAssignee,
      sMessage,
      prevClientAssignee,
      prevTaskPriority,
      // documentOpen,
    } = this.state
    const { statusList, task: sTask } = task
    const isDraft = sTask && sTask.isDraft
    // eslint-disable-next-line eqeqeq
    const isParent = sTask && sTask.parent_id == 0
    // <Drawer
    //     width="50%"
    //     anchor="right"
    //     variant="permanent"
    //     classes={{
    //       paper: clsx(
    //         classes.drawerPaper,
    //         !open && classes.drawerPaperClose,
    //         classes.root
    //       ),
    //     }}
    //     open={open}
    //   >
    return (
      <>
        {this.renderToolabr()}
        <Box
          // ref={this.SidePanelContainer}
          // className={classes.mainContainer}
          p={0}
        >
          <Box className={classes.mainContainerBox}>
            <Grid
              container
              direction="row"
              className={classes.mainContainerInternal}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                style={{ padding: 0 }}
              >
                <Container
                  ref={this.SidePanelContainer}
                  className={classes.mainContainer}
                >
                  <TitleSection
                    ref={(o) => {
                      this.TitleSection = o
                    }}
                  />
                  {this.renderDueDateSection()}
                  {isDraft && this.renderParentTaskOption()}
                  <DescriptionSection
                    ref={(o) => {
                      this.DescriptionSection = o
                    }}
                  />
                  {this.renderCommentSection()}
                  {!isDraft ? (
                    <DependencySection
                      ref={(o) => {
                        this.DependencySection = o
                      }}
                    />
                  ) : null}
                  {!isDraft && isParent ? (
                    <SubTaskSection
                      setMessage={this.setMessage}
                      ref={(o) => {
                        this.SubTaskSection = o
                      }}
                    />
                  ) : null}
                  {!isDraft && view === "reason-required" ? (
                    <Reason
                      statusList={statusList}
                      prevStatus={prevStatus}
                      status={status}
                      setMessage={this.setMessage}
                      addActivityApi={this.addActivityApi}
                    />
                  ) : null}
                  {!isDraft && view === "add-document" ? (
                    <DocumentSection
                      ref={(o) => {
                        this.DocumentSection = o
                      }}
                      scrollToDocument={this.scrollToDocument}
                      setMessage={this.setMessage}
                      addActivityApi={this.addActivityApi}
                      setDefaultView={this.setDefaultView}
                    />
                  ) : null}
                </Container>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                className={classes.borderLeft}
              >
                <Box paddingX={1} paddingY={2}>
                  {/* {!isDraft && this.renderTypeItems()} */}
                  {this.renderTypeItems()}
                </Box>

                <Box paddingX={1} paddingY={1} className={classes.borderBottom}>
                  {!isDraft && startEnable.indexOf(status) > -1 ? (
                    <Button
                      onClick={this.startTask}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      disableElevation
                      className={classes.completeButton}
                    >
                      Start Task
                    </Button>
                  ) : null}

                  {!isDraft && completeEnable.indexOf(status) > -1 ? (
                    <Button
                      onClick={this.completeTask}
                      fullWidth
                      variant="outlined"
                      disableElevation
                      className={classes.completeButton}
                    >
                      Complete Task
                    </Button>
                  ) : null}

                  {!isDraft && status === "complete" && (
                    <Button
                      fullWidth
                      variant="text"
                      color="primary"
                      disableElevation
                      disableTouchRipple
                    >
                      Task Completed
                    </Button>
                  )}
                  <Box paddingY={1}>
                    <Button
                      onClick={this.saveTask}
                      fullWidth
                      variant="contained"
                      disableElevation
                      className={classes.saveButton}
                    >
                      Save Task
                    </Button>
                  </Box>

                  <Can
                    roleLevel={3}
                    role={userRoles[0]}
                    yes={() =>
                      !isDraft && (
                        <Button
                          fullWidth
                          variant="outlined"
                          disableElevation
                          className={classes.deleteButton}
                          onClick={this.deleteTask}
                        >
                          Delete
                        </Button>
                      )
                    }
                  />
                </Box>

                <Box paddingX={1}>
                  <Button
                    fullWidth
                    onClick={this.cancel}
                    className={classes.cancelButton}
                    variant="outlined"
                    disableElevation
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              {this.renderSnackBar()}
            </Grid>
          </Box>
        </Box>
        <DialogAlert
          ref={(o) => {
            this.confirmStatus = o
          }}
          title="Please Confirm"
          message="Are you sure want to change status ?"
          handleOK={this.onStatusConfirm}
          handleCancel={() => {
            this.setState({ status: prevStatus, prevStatus: "" })
          }}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmStartTask = o
          }}
          warning
          title="Warning"
          message={sMessage}
          handleOK={() => {}}
          handleCancel={() => {}}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmAssign = o
          }}
          title="Please Confirm"
          message="Are you sure want to change Assignee ?"
          handleOK={this.onAssigneeConfirm}
          handleCancel={() => {
            this.setState({ assignee: prevAssignee, prevAssignee: "" })
          }}
        />

        <DialogAlert
          ref={(o) => {
            this.confirmClientAssign = o
          }}
          title="Please Confirm"
          message="Are you sure want to change Client Assignee ?"
          handleOK={this.onClientAssigneeConfirm}
          handleCancel={() => {
            this.setState({
              clientAssignee: prevClientAssignee,
              prevClientAssignee: "",
            })
          }}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmTaskPriority = o
          }}
          title="Please Confirm"
          message="Are you sure want to change task priority ?"
          handleOK={this.onTaskPriorityConfirm}
          handleCancel={() => {
            this.setState({
              taskPriority: prevTaskPriority,
              prevTaskPriority: "",
            })
          }}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmSaveTask = o
          }}
          title="Please Confirm"
          message="Are you sure want to save the Task ?"
          handleOK={this.onSaveTaskConfirm}
          handleCancel={() => {}}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmDeleteTask = o
          }}
          title="Please Confirm"
          message="Are you sure want to delete the Task ?"
          handleOK={this.deleteTaskApi}
          handleCancel={() => {}}
        />
      </>
    )
  }

  render() {
    const {
      classes,
      task: { open },
      document: { document: documentOpen },
    } = this.props
    const { mainLoad } = this.state
    /* 
<Drawer
        width={documentOpen ? "100%" : "50%"}
        anchor="right"
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.root,
            !documentOpen && classes.dW
          ),
        }}
        open={open}
      >

</Drawer>
*/
    return (
      <Drawer
        width={documentOpen ? "100%" : "50%"}
        anchor="right"
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.root,
            !documentOpen && classes.dW
          ),
        }}
        open={open}
      >
        {mainLoad && <Fade in={mainLoad}>{this.renderMainLoading()}</Fade>}
        {!mainLoad && (
          <Fade in={!mainLoad}>
            <Grid container direction="row" style={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={documentOpen ? 6 : 12}
                lg={documentOpen ? 6 : 12}
                xl={documentOpen ? 6 : 12}
              >
                {this.renderPanelView()}
              </Grid>

              {documentOpen && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {this.renderDocumentView()}
                </Grid>
              )}
            </Grid>
          </Fade>
        )}
      </Drawer>
    )
  }
}

SidePanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  // handleClose: PropTypes.func,
}

SidePanel.defaultProps = {
  classes: {},
  task: {},
  auth: {},
  taskActions: {},
  apiActions: {},
  document: {},
  documentActions: {},
  // handleClose: () => {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
  document: state.document,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SidePanel)
)
