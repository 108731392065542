import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { DropzoneArea } from "material-ui-dropzone"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import withStyles from "@material-ui/styles/withStyles"
import { Card, CardContent, Divider, CardHeader } from "@material-ui/core"
import Select from "../Select/ActionSelect"

import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import documentActions from "../../redux/reducers/document/actions"
import styles from "./SidePanel.styles"
import { formDataReq } from "../../common/apiHelper"
import settings from "../../config/settings"
import TimeAgo from "../TimeAgo/TimeAgo"
import VersionMenu from "../VersionMenu/VersionMenu"
import { getDocumentName, getErrorMessage } from "../../common/utils"
import FileIcon from "../FileIcon/FileIcon"
import DocumentStatus from "../StatusChip/DocumentStatus"
// eslint-disable-next-line import/no-cycle
import DocumentActions from "../DocumentActions/DocumentActions"
// import Table from "../TableComponent/Table"

const maxFileSize = 25 * 1000000

const cols = [
  {
    id: "path",
    numeric: false,
    disablePadding: true,
    label: "",
    sidebar: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
    sidebar: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "DOCUMENT TYPE",
    sidebar: false,
  },
  // {
  //   id: "version",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Version",
  //   sidebar: false,
  // },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "UPDATED",
    sidebar: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "STATUS",
    sidebar: false,
  },
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Action",
  //   sidebar: true,
  // },
]

const initialState = {
  files: [],
  // documents: [],
  name: "",
  docType: "",
  docDesc: "",
  uploading: false,
  progress: 0,
  errors: {},
  dropKey: String(Math.random(0, 99999) * 100000),
  step: 1,
  isNew: true,
  documentId: "",
  columns: cols,
}

class DocumentSection extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.cardRef = React.createRef()
    this.state = {
      ...initialState,
    }
  }

  componentDidMount() {
    this.preFill()
  }

  getDocuments = async () => {
    const {
      // apiActions: { apiCall },
      documentActions: { getProjectDocuments },
    } = this.props
    const { projectId } = this.state
    getProjectDocuments(projectId)
  }

  getProjectDocuments = () => {
    const {
      documentActions: { getProjectDocuments },
    } = this.props
    getProjectDocuments(0)
  }

  preFill = () => {
    const { documentData } = this.props
    // console.log("preFill", documentData)
    if (!_.isEmpty(documentData)) {
      const { name, type, description } = documentData
      this.setState({
        name: name || "",
        docType: type || "",
        docDesc: description || "",
      })
    }
  }

  onChangeFiles = (files) => {
    const { errors } = this.state
    const file = files && files[0] ? files[0] : null
    if (file) {
      this.setState({
        files: file,
        errors: {
          ...errors,
          files: undefined,
        },
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  onFileDelete = (file) => {
    // console.log(file)
  }

  onChange = (key) => (e) => {
    const { errors } = this.state
    this.setState({
      [key]: e.target.value,
      errors: {
        ...errors,
        [key]: undefined,
      },
    })
  }

  onProgessUpload = (progress) => {
    // console.log(progress)
    if (_.isNumber(progress)) {
      this.setState({ progress: _.round(progress, 2) })
    }
  }

  onSuccess = (responseJson) => {
    const { onSuccess, setMessage } = this.props
    this.reset()
    setMessage({
      message: responseJson.message,
      open: true,
      type: "success",
    })
    onSuccess()
  }

  reset = () => {
    this.setState({ ...initialState }, this.getProjectDocuments)
  }

  documentAssignToTask = async () => {
    const {
      auth: { userdata },
      task: { task },
      setMessage,
      addActivityApi,
      apiActions: { apiCall },
    } = this.props
    const { docDesc, files, name, docType, documentId } = this.state
    this.setState({ uploading: true })

    let itemId = 0

    if (task && task.id) {
      itemId = task.id
    }

    try {
      const reqdata = {
        project_task_id: itemId,
        name,
        description: docDesc,
        type: docType,
      }

      if (documentId && documentId.document_id) {
        reqdata.document_id = documentId.document_id
      }

      const responseJson = await apiCall(
        settings.endpoints.documentAssignToTask,
        "post",
        reqdata
      )

      if (responseJson && responseJson.success) {
        addActivityApi(
          `${userdata.name || ""} uploaded a file : ${files.name}`,
          "documents"
        )
        this.onSuccess(responseJson)
        this.reset()
      } else {
        this.setState({ uploading: false, progress: 0 }, () => {
          setMessage({
            message: responseJson.message,
            open: true,
            type: "error",
          })
        })
      }
    } catch (error) {
      const message = getErrorMessage(error)
      this.setState({ uploading: false, progress: 0 }, () => {
        setMessage({
          message:
            message !== ""
              ? message
              : "Unable to process Due to Network / Server Error",
          type: "error",
          open: true,
        })
      })
      // console.log(error)
    }
  }

  addDocumentApi = async () => {
    const {
      auth: { token, userdata },
      task: { task },
      setMessage,
      addActivityApi,
      // setDefaultView,
      documentData,
    } = this.props
    const { docDesc, files, name, docType } = this.state
    this.setState({ uploading: true })

    let itemId = 0

    if (task && task.id) {
      itemId = task.id
    }
    if (documentData && documentData.item_id) {
      itemId = documentData.item_id
    }

    let version = 1

    if (documentData && _.isNumber(documentData.version)) {
      version = parseInt(documentData.version + 1, 10)
    }

    try {
      const data = {
        item_id: itemId,
        version: version.toFixed(2),
        document: files,
        name,
        description: docDesc,
        user_id: 1,
        type: docType,
      }

      if (documentData && documentData.document_id) {
        data.document_id = documentData.document_id
      }
      const headers = {
        // "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
      const responseJson = await formDataReq(
        settings.endpoints.createDocument,
        "POST",
        data,
        headers,
        this.onProgessUpload
      )

      if (responseJson && responseJson.success) {
        // setDefaultView()
        addActivityApi(
          `${userdata.name || ""} uploaded a file : ${files.name}`,
          "documents"
        )
        // this.getDocuments()
        // this.getTaskData()
        this.onSuccess(responseJson)
        this.reset()
      } else {
        // this.setDefaultView()
        this.setState({ uploading: false, progress: 0 }, () => {
          setMessage({
            message: responseJson.message,
            open: true,
            type: "error",
          })
        })
      }
    } catch (error) {
      let message = getErrorMessage(error)
      if (message === "") {
        message = "Unable to process Due to Network / Server Error"
      }
      this.setState({ uploading: false, progress: 0 }, () => {
        setMessage({
          message,
          type: "error",
          open: true,
        })
      })
      // console.log(error)
    }
  }

  validate = () => {
    const { docDesc, files, name, docType, documentId, isNew } = this.state
    let valid = true
    const errors = {}
    if (name.trim() === "") {
      valid = false
      errors.name = "Please add document name"
    }
    if (docType === "") {
      valid = false
      errors.docDesc = "Please select document type"
    }
    if (docDesc.trim() === "") {
      valid = false
      errors.docDesc = "Please add document description"
    }
    if (isNew) {
      if (files.length === 0) {
        valid = false
        errors.files = "Please add / drop file"
      }
    } else if (!isNew && (!documentId || documentId === "")) {
      valid = false
      errors.documentId = "Please select existing document to add"
    }

    if (valid) {
      if (!isNew) {
        this.documentAssignToTask()
      } else {
        this.addDocumentApi()
      }
    } else {
      this.setState({ errors })
    }
  }

  renderError = (key) => {
    const { errors } = this.state
    if (errors[key]) {
      return (
        <Typography color="error" style={{ fontSize: 12 }}>
          {errors[key]}
        </Typography>
      )
    }
    return null
  }

  renderDropZone = () => {
    const { progress, uploading, dropKey, documentId } = this.state
    const { classes } = this.props
    if (documentId && documentId !== "") {
      return null
    }
    return (
      <>
        <DropzoneArea
          onChange={this.onChangeFiles}
          onDrop={this.onChangeFiles}
          onDelete={this.onFileDelete}
          filesLimit={1}
          disabled={uploading}
          key={dropKey}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/jpg",
            "application/pdf",
            "application/msword",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/docx",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ]}
          showPreviews={false}
          showPreviewsInDropzone
          dropzoneClass={classes.dropZone}
          dropzoneParagraphClass={classes.dropZoneText}
          dropzoneText="Drag and drop here or click to browse files"
          showFileNamesInPreview
          showFileNames
          maxFileSize={maxFileSize}
        />
        {this.renderError("files")}
        {uploading && <LinearProgress variant="determinate" value={progress} />}
      </>
    )
  }

  renderNew = () => {
    const { docDesc, progress, uploading, name, docType } = this.state
    const {
      classes,
      task: { documentTypes },
      documentData,
    } = this.props
    // console.log("documentData", documentData)
    return (
      <Box display="flex" flexDirection="column">
        <Box
          paddingTop={0}
          paddingBottom={1}
          paddingX={2}
          className={classes.borderBottom}
        >
          <Typography
            className={classes.headText}
            color="textSecondary"
            style={{ marginLeft: "20px" }}
          >
            {documentData ? "Upload New Version" : "Add Document"}
          </Typography>
        </Box>
        <Grid container style={{ padding: "20px 30px 20px 30px" }}>
          <Grid container>
            <TextField
              ref={this.input}
              id="document-name-input"
              label="Document Name"
              variant="filled"
              placeholder="Document Name"
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: classes.multiLineInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={name}
              onChange={(e) => {
                this.setState({ name: e.target.value })
              }}
            />
            {this.renderError("name")}

            <FormControl fullWidth className={classes.filledSelect}>
              <Select
                variant="filled"
                disableUnderline
                value={docType}
                fullWidth
                onChange={(e) => {
                  // console.log(e)
                  this.setState({ docType: e.target.value })
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Document Type</MenuItem>
                {documentTypes.map((ele) => {
                  return (
                    <MenuItem key={`${ele.id}_${ele.name}`} value={ele.id}>
                      {ele.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {this.renderError("docType")}
            </FormControl>
            <FormControl fullWidth className={classes.inputRow}>
              <TextField
                multiline
                rows={20}
                id="description-name-input"
                label={false}
                variant="filled"
                placeholder="Enter Description"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.multiLineInput,
                }}
                value={docDesc}
                onChange={this.onChange("docDesc")}
              />
              {this.renderError("docDesc")}
            </FormControl>
          </Grid>
          {this.renderDropZone()}
        </Grid>
        <Button
          className={classes.uploadButton}
          disabled={uploading}
          onClick={this.validate}
          variant="outlined"
          size="small"
          color="secondary"
          disableElevation
          style={{
            marginTop: 5,
            marginRight: 30,
            marginBottom: 30,
            alignSelf: "flex-end",
          }}
        >
          {!uploading ? "Upload document" : `Uploading ${progress}%`}
        </Button>
      </Box>
    )
  }

  renderDocumentsTable = () => {
    const { documentId } = this.state
    const {
      classes,
      document: {
        // projectDocumentLoad,
        projectDocuments,
        // projectDocumentsError,
      },
    } = this.props
    let dataDocs = []
    if (_.isArray(projectDocuments)) {
      dataDocs = _.filter(
        projectDocuments,
        (i) => i.item_id === 0 || i.item_id === "0"
      )
      // dataDocs = projectDocuments
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        style={{ padding: "20px 30px" }}
      >
        <Box style={{ margin: "10px auto" }}>
          <Typography variant="body2" style={{ color: "#707070" }}>
            Select a document from following existing documents
          </Typography>
        </Box>

        <FormControl fullWidth className={classes.filledSelect}>
          <Select
            variant="filled"
            disableUnderline
            value={documentId}
            fullWidth
            onChange={(e) => {
              // console.log(e.target.value)
              this.onRowClick(e, e.target.value)
              // this.setState({ documentId: e.target.value })
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">Select Existing Document</MenuItem>
            {dataDocs.map((ele) => {
              return (
                <MenuItem key={`${ele.id}_${ele.name}`} value={ele}>
                  {ele.name}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderError("documentId")}
        </FormControl>
        {/* <Table
          checkboxes={false}
          filter={false}
          rows={dataDocs}
          columns={columns}
          dense
          // loadingError="No Documents found"
          loadingError={
            projectDocumentsError && projectDocumentsError !== ""
              ? projectDocumentsError
              : "No Documents found"
          }
          loading={projectDocumentLoad}
          renderCell={this.renderCell}
          onRowClick={this.onRowClick}
        /> */}
      </Box>
    )
  }

  onRowClick = (event, row) => {
    event.preventDefault()
    // console.log("onRowClick", row)
    this.setState({ documentId: row, name: row.name, docDesc: row.description })
  }

  renderCell = (data) => {
    const {
      row,
      column: { id },
    } = data
    const {
      setMessage,
      task: { documentTypes },
    } = this.props

    if (id === "action") {
      return (
        <DocumentActions
          key={`version_info_${row.id}`}
          data={row}
          onSuccess={this.getDocuments}
          setMessage={setMessage}
        />
      )
    }

    if (id === "status") {
      // console.log('id === "status"', row)
      let status = 0
      // console.log("row.revisions", row.revisions)
      if (_.isArray(row.revisions)) {
        const sObj = _.find(
          row.revisions,
          (i) => i.document_id === row.document_id
        )
        // console.log("sObj", sObj)
        if (sObj) {
          status = sObj.status_id
        }
      }
      return <DocumentStatus status={status} />
    }

    if (!row[id]) {
      return " "
    }
    if (id === "path") {
      return <FileIcon fileData={row} size={2} />
    }

    if (id === "name") {
      const documentName = getDocumentName(row)
      return (
        <ListItem disableGutters>
          <ListItemText
            // primary={documentName}
            primary={
              <Tooltip title={row[id]}>
                <Typography style={{ display: "inline-flex" }}>
                  {documentName}
                </Typography>
              </Tooltip>
            }
            secondary={
              <>
                <Typography
                  variant="body2"
                  style={{ fontSize: 12 }}
                  color="textSecondary"
                >
                  {row.description}
                </Typography>
              </>
            }
          />
        </ListItem>
      )
    }

    if (id === "updated_at") {
      return <TimeAgo time={row[id]} style={{ fontSize: 12 }} />
    }

    if (id === "type") {
      const typeObj = _.find(documentTypes, (i) => i.id === row.type)
      if (typeObj) {
        return typeObj.name
      }
      return "Unknown"
    }

    if (id === "version") {
      return <VersionMenu key={`version_info_${row.id}`} data={row} />
    }

    return row[id]
  }

  renderExisting = () => {
    const { documentId } = this.state
    if (!documentId || documentId === "") {
      return this.renderDocumentsTable()
    }
    return this.renderNew()
  }

  addNewDocument = () => {
    this.setState({ isNew: true, step: 2 }, this.scrollToDocument)
  }

  selectExistingDocument = () => {
    this.setState({ isNew: false, step: 2 }, this.scrollToDocument)
  }

  scrollToDocument = () => {
    const { scrollToDocument } = this.props
    if (_.isFunction(scrollToDocument)) {
      setTimeout(() => {
        scrollToDocument()
      }, 500)
    }
  }

  renderStep1 = () => {
    const { classes } = this.props
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          className={classes.uploadOptionButton}
          variant="outlined"
          size="small"
          color="secondary"
          disableElevation
          onClick={this.addNewDocument}
        >
          Add new document
        </Button>
        <Typography>OR</Typography>
        <Button
          className={classes.uploadOptionButton}
          variant="outlined"
          size="small"
          color="secondary"
          disableElevation
          onClick={this.selectExistingDocument}
        >
          Select existing document
        </Button>
      </Box>
    )
  }

  renderCardContent = () => {
    const { step, isNew } = this.state
    if (step === 1) {
      return this.renderStep1()
    }
    if (step === 2) {
      if (isNew) {
        return this.renderNew()
      }
      return this.renderExisting()
    }
    return null
  }

  render() {
    return (
      <Card ref={this.cardRef} elevation={3}>
        <CardHeader
          title="Add Document"
          titleTypographyProps={{
            "margin-left": "30px",
            "margin-top": "30px",
            style: { fontSize: 16 },
          }}
          style={{ padding: "20px" }}
        />
        <Divider />
        <CardContent style={{ padding: "30px" }}>
          {/* <Typography variant="h4">Add document</Typography> */}
          {/* <Divider /> */}
          {this.renderCardContent()}
        </CardContent>
      </Card>
    )
  }
}

DocumentSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func.isRequired,
  addActivityApi: PropTypes.func.isRequired,
  // setDefaultView: PropTypes.func.isRequired,
  documentData: PropTypes.objectOf(PropTypes.any),
  onSuccess: PropTypes.func,
  scrollToDocument: PropTypes.func,
}

DocumentSection.defaultProps = {
  classes: {},
  task: {},
  auth: {},
  document: {},
  apiActions: {},
  // authActions: {},
  // taskActions: {},
  documentActions: {},
  documentData: {},
  onSuccess: () => {},
  scrollToDocument: null,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  document: state.document,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DocumentSection
  )
)
