import actions from "./actions"

const initialState = {
  statusList: [],
  companyList: [],
  program: {},
  projects: [],
  pLoading: false,
  pError: "",
  currentFilter: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_PROGRAMME_STATUS_REQUEST:
      return { ...state, statusList: [] }

    case actions.GET_PROGRAMME_STATUS_SUCCESS:
      return { ...state, statusList: action.payload }

    case actions.GET_PROGRAMME_STATUS_FAILED:
      return { ...state, statusList: [] }

    case actions.GET_COMPANY_LIST_REQUEST:
      return state

    case actions.GET_COMPANY_LIST_SUCCESS:
      return state

    case actions.GET_COMPANY_LIST_FAILED:
      return state

    case actions.GET_PROGRAMME_REQUEST:
      return { ...state, program: {} }

    case actions.GET_PROGRAMME_SUCCESS:
      return { ...state, program: action.payload }

    case actions.GET_PROGRAMME_FAILED:
      return { ...state, program: {} }

    case actions.GET_PROGRAMME_PROJECTS_REQUEST:
      return { ...state, pLoading: true, projects: [], pError: "" }

    case actions.GET_PROGRAMME_PROJECTS_SUCCESS:
      return {
        ...state,
        pLoading: false,
        projects: action.payload,
        currentFilter: action.filter,
      }

    case actions.GET_PROGRAMME_PROJECTS_FAILED:
      return {
        ...state,
        pLoading: false,
        pError: action.payload,
        currentFilter: action.filter || {},
      }

    default:
      return state
  }
}
