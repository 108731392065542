import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { compose } from "redux"
import { withSnackbar } from "notistack"
// import Typography from "@material-ui/core/Typography"

class Handler extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  /**
  * Warning: This lifecycle is currently deprecated, and will be removed in React version 17+
  More details here: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      auth: { lastNoti },
    } = this.props
    if (
      nextProps.auth.lastNoti &&
      !_.isEqual(lastNoti, nextProps.auth.lastNoti)
    ) {
      this.showNoti(nextProps.auth.lastNoti)
    }
  }

  showNoti = (lastNoti) => {
    const { enqueueSnackbar } = this.props
    if (lastNoti && lastNoti.notification) {
      enqueueSnackbar(lastNoti.notification.message || "Unknown message")
    }
  }

  render() {
    return null
    // const { enqueueSnackbar } = this.props
    // return (
    //   <Typography
    //     onClick={() => {
    //       enqueueSnackbar("Unknown message")
    //     }}
    //   >
    //     Click here Test
    //   </Typography>
    // )
  }
}

Handler.propTypes = {
  // authActions: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  enqueueSnackbar: PropTypes.func.isRequired,
}

Handler.defaultProps = {
  // authActions: {},
  auth: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {}

export default compose(
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps)
)(Handler)
