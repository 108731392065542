import React from "react"

const TaskIcon = ({
  style = {},
  fill = "rgba(0, 0, 0, 0.35)",
  width = "100%",
  className = "navIcon",
  height = "100%",
  viewBox = "0 0 463 463",
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      style={{ height: 30, width: 30, ...style }}
      height={height}
      viewBox={viewBox}
      xmlSpace="preserve"
      // version="1.1"
      fill={fill}
    >
      <g>
        <g>
          <path d="M71.5,0C32.075,0,0,32.075,0,71.5S32.075,143,71.5,143S143,110.925,143,71.5S110.925,0,71.5,0z M71.5,128    C40.346,128,15,102.654,15,71.5S40.346,15,71.5,15S128,40.346,128,71.5S102.654,128,71.5,128z" />
          <path d="M71.5,160C32.075,160,0,192.075,0,231.5S32.075,303,71.5,303s71.5-32.075,71.5-71.5S110.925,160,71.5,160z M71.5,288    C40.346,288,15,262.654,15,231.5S40.346,175,71.5,175s56.5,25.346,56.5,56.5S102.654,288,71.5,288z" />
          <path d="M71.5,320C32.075,320,0,352.075,0,391.5S32.075,463,71.5,463s71.5-32.075,71.5-71.5S110.925,320,71.5,320z M71.5,448    C40.346,448,15,422.654,15,391.5S40.346,335,71.5,335s56.5,25.346,56.5,56.5S102.654,448,71.5,448z" />
          <path d="m90.196,50.197l-26.696,26.697-10.696-10.697c-2.93-2.929-7.678-2.929-10.607,0s-2.929,7.678 0,10.606l16,16c1.464,1.465 3.384,2.197 5.303,2.197s3.839-0.732 5.304-2.197l32-32c2.929-2.929 2.929-7.678 0-10.606-2.93-2.929-7.678-2.929-10.608,7.10543e-15z" />
          <path d="m90.196,210.197l-26.696,26.697-10.696-10.697c-2.93-2.929-7.678-2.929-10.607,0s-2.929,7.678 0,10.606l16,16c1.464,1.465 3.384,2.197 5.303,2.197s3.839-0.732 5.304-2.197l32-32c2.929-2.929 2.929-7.678 0-10.606-2.93-2.929-7.678-2.929-10.608,0z" />
          <path d="m175.5,55h144c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-144c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
          <path d="m175.5,103h280c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-280c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
          <path d="m175.5,215h144c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-144c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
          <path d="m455.5,248h-280c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h280c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
          <path d="m175.5,375h144c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-144c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
          <path d="m455.5,408h-280c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h280c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
        </g>
      </g>
    </svg>
  )
}

export default TaskIcon
