import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import clsx from "clsx"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseRounded from "@material-ui/icons/CloseRounded"
import GetApp from "@material-ui/icons/GetApp"
import withStyles from "@material-ui/styles/withStyles"
import documentActions from "../../redux/reducers/document/actions"
import DocumentStatus from "../StatusChip/DocumentStatus"
import DocumentViewer from "../DocumentViewer/DocumentViewer"
import DocumentActions from "../DocumentActions/DocumentActions"
import VersionMenu from "../VersionMenu/VersionMenu"

const styles = (theme) => ({
  flex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  documentViewIconButton: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(0, 1),
    // backgroundColor: "#F00",
  },
  blueIconButton: {
    backgroundColor: "#436EE0",
    "&:hover": {
      backgroundColor: "#436EE080",
    },
  },
  redIconButton: {
    backgroundColor: "#FF3C00",
    "&:hover": {
      backgroundColor: "#FF3C0080",
    },
  },
  documentViewIcons: {
    color: "white",
    fontSize: 24,
  },
  documentViewText: {
    color: "white",
    fontSize: 16,
    width: "100%",
  },
  Inline: {
    display: "inline",
  },
  documentViewSubText: {
    color: "white",
    fontSize: 12,
  },
  documentViewHeaderPart: {
    display: "flex",
  },
  documentViewHeader: {
    // height: "64px",
    minHeight: "90px",
    backgroundColor: "rgba(112, 112, 112, 0.5)",
    display: "flex",
    padding: theme.spacing(0, 3),
  },
  docViewContainer: {
    padding: theme.spacing(2),
    height: "100vh",
    backgroundColor: "#E7E8E9",
  },
  blackText: {
    color: "#2F2F2F",
  },
})

class DocumentPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: {},
    }
  }

  componentDidMount() {
    const {
      document: { document },
    } = this.props
    this.setInitialVersion(document)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      document: { document },
    } = this.props

    const {
      document: { document: nextDocument },
    } = nextProps

    if (!_.isEqual(document, nextDocument)) {
      // this.setState({ version: null })
      this.setInitialVersion(nextDocument)
    }
  }

  setInitialVersion = (document) => {
    if (document) {
      if (document && _.isArray(document.revisions)) {
        const sObj = _.first(document.revisions)
        if (sObj) {
          this.setState({ version: sObj })
        }
      }
    }
  }

  getDocumentStatus = (row) => {
    let status = 0
    if (row && _.isArray(row.revisions)) {
      const sObj = _.find(
        row.revisions,
        (i) => i.document_id === row.document_id
      )
      if (sObj) {
        status = sObj.status_id
      } else {
        // eslint-disable-next-line no-console
        console.log("getDocumentStatus error", row)
      }
    }
    return status
  }

  setDocument = (v) => () => {
    const {
      documentActions: { setDocument },
    } = this.props
    setDocument(v)
  }

  render() {
    const {
      classes,
      document: { document },
      setMessage,
      onSuccess,
    } = this.props
    const { version } = this.state
    if (!document) return null
    const status = this.getDocumentStatus(document)
    return (
      <div className={classes.flex}>
        <Grid container className={classes.documentViewHeader}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={5}
            className={classes.documentViewHeaderPart}
            justify="center"
            alignItems="flex-start"
            direction="column"
          >
            <Typography
              noWrap
              variant="body2"
              className={classes.documentViewText}
            >
              {version?.name || ""}
            </Typography>
            <Typography
              variant="caption"
              noWrap
              className={classes.documentViewSubText}
              style={{ color: "#000" }}
            >
              {/* {document.description || ""} */}
              {version?.description || ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={5}
            className={classes.documentViewHeaderPart}
            justify="center"
            alignItems="flex-start"
            direction="column"
          >
            <Typography
              noWrap
              className={classes.documentViewSubText}
              variant="body2"
            >
              {`Version: `}
              {/* <Typography
                style={{ color: "#000" }}
                noWrap
                className={clsx(classes.documentViewSubText, classes.Inline)}
              >
                {document.version || ""}
              </Typography> */}
              <VersionMenu
                key={`version_info_${document.id}`}
                data={document}
                selectedVersion={version}
                onChangeVersion={(v) => this.setState({ version: v })}
              />
            </Typography>
            <Typography
              className={classes.documentViewSubText}
              variant="caption"
              noWrap
            >
              {`Status: `}
              <DocumentStatus
                // text
                status={status}
                className={clsx(
                  classes.documentViewSubText,
                  classes.Inline,
                  classes.blackText
                )}
              />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={2}
            xl={2}
            className={classes.documentViewHeaderPart}
            alignItems="center"
            justify="flex-end"
            direction="row"
          >
            <DocumentActions
              key={`version_info_${document.id}`}
              data={document}
              panel
              // onSuccess={this.getDocuments}
              onSuccess={onSuccess}
              setMessage={setMessage}
              buttonClass={clsx(
                classes.documentViewIconButton,
                classes.blueIconButton
              )}
              // setMessage={setMessage}
            />

            <IconButton
              size="medium"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              className={clsx(
                classes.documentViewIconButton,
                classes.blueIconButton
              )}
              onClick={(evt) => {
                evt.preventDefault()
                window.open(document.path, "_blank")
              }}
            >
              <GetApp className={classes.documentViewIcons} />
            </IconButton>
            <IconButton
              size="medium"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={this.setDocument(null)}
              className={clsx(
                classes.documentViewIconButton,
                classes.redIconButton
              )}
            >
              <CloseRounded className={classes.documentViewIcons} />
            </IconButton>
          </Grid>
        </Grid>
        <DocumentViewer document={document} version={version} />
      </div>
    )
  }
}

DocumentPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func,
  onSuccess: PropTypes.func,
}

DocumentPanel.defaultProps = {
  classes: {},
  document: {},
  documentActions: {},
  setMessage: () => {},
  onSuccess: () => {},
}

const mapStateToProps = (state) => ({
  document: state.document,
})

const mapDispatchToProps = (dispatch) => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DocumentPanel
  )
)
