import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Link as MainLink } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import CardMedia from "@material-ui/core/CardMedia"
import Avatar from "@material-ui/core/Avatar"
import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
// import { useAuth0 } from "@auth0/auth0-react"
import styles from "./Drawer.styles"
import authActions from "../../redux/reducers/auth/actions"
import appConfigActions from "../../redux/reducers/appConfig/actions"
import images from "../../config/images"
import PoweredBy from "../PoweredBy/PoweredBy"
import { useAuth0 } from "../../common/react-auth0-spa"
import DrawerNavItem from "./DrawerItem"
import { getParentChildFormatRoutes, getTenantData } from "../../common/utils"
import ZendDesk from "../ZendDesk/ZendDesk"

export function GetAcronym(name) {
  const str = String(name.string)
  const acronym = str
    .split(/\s/)
    // eslint-disable-next-line
    .reduce((response, word) => (response += word.slice(0, 1)), "")
  return acronym
}
function DrawerComponent(props) {
  // eslint-disable-next-line no-unused-vars
  const { isAuthenticated, logout, user, loading, isLoading } = useAuth0()

  if (isLoading || !user) {
    return <div>Loading...</div>
  }

  const tenantData = getTenantData()

  const SALogout = (e) => {
    // e.preventDefault()
    // logout({ returnTo: process.env.REACT_APP_BASE_URI })

    e.preventDefault()

    let tenantDomain = ""
    if (tenantData !== null) {
      tenantDomain = `${tenantData.domain}.`

      if (tenantDomain === "localhost.") {
        tenantDomain = ""
      }
    }

    const logoutUrl = `${process.env.REACT_APP_BASE}${tenantDomain}${process.env.REACT_APP_URI}`
    logout({ returnTo: logoutUrl })
  }

  const {
    open,
    classes,
    auth: { userdata },
    navigate,
    // appConfigActions: { getPrivateRoutes },
  } = props
  // let it = ""
  // if (userdata.name) {
  //   it = userdata.name
  //     .split(" ")
  //     .map((n) => n[0])
  //     .join("")
  // }
  const pRoutes = getParentChildFormatRoutes()
  const userRoles = userdata["https://saffronios.com//roles"]
  // const privateRoutes = getPrivateRoutes()

  // let tenantName = localStorage.getItem("tenantData")
  let tenantName = ""
  if (tenantData) {
    tenantName = tenantData.client_name
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={open ? classes.toolbarIcon : classes.toolbarIconOpen}>
        <CardMedia
          className={classes.logo}
          image={images.logo}
          title="Saffron IOS"
        />
        {/*
        Draw component open close not require for now
        <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton> */}
      </div>
      <Divider />

      <Grid container direction="column">
        <Grid item md={12} style={{ margin: "auto" }}>
          <Typography
            variant="body2"
            className={classes.sidebarFooterText}
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontWeight: 450,
              whiteSpace: "pre-wrap",
            }}
            noWrap={false}
          >
            {tenantName}
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container component="span" className={classes.avatarSection}>
        <Grid container>
          <Grid item style={{ margin: "auto" }}>
            <Avatar
              style={{ margin: "auto" }}
              component="span"
              className={classes.styledAvatar}
            >
              <GetAcronym string={user.name} />
            </Avatar>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item style={{ margin: "auto" }}>
            <Typography
              variant="body2"
              className={classes.sidebarFooterText}
              style={{ marginTop: 5 }}
            >
              {isAuthenticated && user.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item style={{ margin: "auto" }}>
            {isAuthenticated && (
              <MainLink
                to="#"
                pathname="#"
                style={{ margin: "0", paddingLeft: "0", paddingRight: "0" }}
                onClick={SALogout}
                className={classes.LLink}
              >
                Log Out
              </MainLink>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <List className={classes.menuList}>
        <div>
          {pRoutes.map((rr, index) => {
            return (
              <DrawerNavItem
                // eslint-disable-next-line react/no-array-index-key
                key={`drawer_nav_item_${rr.id}_${rr.path}_${index}`}
                route={rr}
                userRoles={userRoles}
                navigate={navigate}
              />
            )
          })}
        </div>
      </List>
      <Divider style={{ marginLeft: 25 }} />
      {open && <PoweredBy />}
      <ZendDesk />
    </Drawer>
  )
}

DrawerComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool,
  auth: PropTypes.objectOf(PropTypes.any),
  navigate: PropTypes.func,
  // appConfigActions: PropTypes.objectOf(PropTypes.any),
}

DrawerComponent.defaultProps = {
  classes: {},
  open: true,
  auth: {},
  navigate: () => {},
  // appConfigActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  appConfig: state.appConfig,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    appConfigActions: bindActionCreators(appConfigActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DrawerComponent)
)
