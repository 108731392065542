import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { CaptureConsole } from "@sentry/integrations"
import GA4React from "ga-4-react"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      "https://a09198e915f54259a3f1f96af7a27cf3@o446773.ingest.sentry.io/5777310",
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === "console") {
        return null
      }
      return breadcrumb
    },
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // environment: process.env.REACT_APP_ENV,
  })
}

// if (process.env.NODE_ENV === "development") {
//   console.error = () => {}
//   console.warn = () => {}
// }

/* Disable sentry temporarily- SMVP-358 */
// Sentry.init({
//   dsn:
//     "https://c9b3515e9d4f4452967b7cb5b6e8c33d@o446773.ingest.sentry.io/5425780",
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new CaptureConsole({
//       levels: ["error"],
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   environment: process.env.REACT_APP_ENV,
// })

;(async () => {
  await ga4react.initialize()

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  )
})()

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
