import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import _ from "lodash"
import { connect } from "react-redux"
import { lighten } from "@material-ui/core/styles"
import withStyles from "@material-ui/styles/withStyles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteIcon from "@material-ui/icons/Delete"
import FilterListIcon from "@material-ui/icons/FilterList"

const toolbarStyles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
})

class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderFilter = () => {
    const { renderFilter } = this.props
    if (_.isFunction(renderFilter)) {
      return renderFilter()
    }

    return (
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )
  }

  render() {
    const { classes, numSelected } = this.props

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          />
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          this.renderFilter()
        )}
      </Toolbar>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  renderFilter: PropTypes.func,
}

EnhancedTableToolbar.defaultProps = {
  renderFilter: null,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(toolbarStyles)(
  connect(mapStateToProps, mapDispatchToProps)(EnhancedTableToolbar)
)
