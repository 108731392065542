import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { Link, withRouter } from "react-router-dom"
import { Container } from "@material-ui/core"
import { Home } from "@material-ui/icons"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import programmeActions from "../../redux/reducers/programme/actions"

const styles = (theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  container: {
    marginTop: 10,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "unset",
  },
  homeIconView: {
    backgroundColor: "#FF9600",
    display: "flex",
    height: 26,
    width: 38,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  homeIcon: {
    color: "#FFF",
    width: 40,
    height: 22,
  },
  selectedRouteSection: {
    backgroundColor: "#F008",
  },
  routeSection: {
    height: 26,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 1,
  },
  routeSectionCurrent: {
    height: 26,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    marginLeft: 2,
    minWidth: "20px",
    clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
  },
  routeSectionView: {
    backgroundColor: "#278BED",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 5,
  },
  routeSectionText: {
    color: "#FFF",
    fontSize: 11,
    marginRight: 10,
    whiteSpace: "nowrap",
  },
  separator: {
    display: "none",
  },
  routeSectionOld: {
    height: 26,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    marginLeft: 2,
  },
  routeSectionViewOld: {
    backgroundColor: "#D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 5,
  },
  routeSectionTextOld: {
    color: "#2F2F2F",
    fontSize: 11,
    textDecoration: "none",
  },
  ol: {
    flexWrap: "nowrap",
  },
})

class SimpleBreadcrumbs extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderName = (name) => {
    const {
      task: { project },
      programme: { program },
    } = this.props
    if (name === "project_name") {
      if (project && project.project_name) {
        return project.project_name
      }
      return ""
    }

    if (name === "program_name") {
      if (program && program.name) {
        return program.name
      }
      return ""
    }

    return name
  }

  render() {
    const { classes, crumbs, match } = this.props
    if (crumbs.length <= 0) {
      return null
    }

    // console.log("CRUMBS:", crumbs)
    let matchPath = ""
    if (match && match.path) {
      matchPath = match.path
    }

    return (
      <Container maxWidth="xl" className={classes.container}>
        <Breadcrumbs
          separator={false}
          aria-label="breadcrumb"
          classes={{
            separator: classes.separator,
            ol: classes.ol,
          }}
        >
          {/* Link back to any previous steps of the breadcrumb. */}
          <div className={classes.homeIconView}>
            <Home className={classes.homeIcon} />
          </div>
          {/* eslint-disable-next-line */}
          {crumbs.map(({ name, path }, key) => {
            if (key === 0 && crumbs.length > 1) {
              //  return null
            }

            // console.log('crumbs', crumbs)
            // console.log("crumbs", crumbs)
            // console.log("path:", path)
            let nameEle = this.renderName(name)
            if (!nameEle || nameEle === "") {
              return null
            }
            // && path.includes("manage")
            if (nameEle === "Dashboards" && matchPath.includes("manage")) {
              nameEle = "Manage"
            }

            if (key + 1 === crumbs.length) {
              return (
                <div
                  key={`breadcrumb_${name}_${path}`}
                  className={classes.routeSectionCurrent}
                >
                  <div className={classes.routeSectionView}>
                    <Typography
                      color="textPrimary"
                      // eslint-disable-next-line react/no-array-index-key
                      key={key}
                      className={classes.routeSectionText}
                    >
                      {nameEle}
                    </Typography>
                  </div>
                  <div />
                </div>
              )
            }

            // Exclude project task from breadcrumbs
            // TODO - Tidy up - cleaner implementation
            if (name !== "project task") {
              // console.log("Crumb Name:", name)
              // console.log("Crumb Name:", name)
              return (
                <div
                  key={`breadcrumb_${name}_${path}`}
                  className={classes.routeSectionOld}
                >
                  <div className={classes.routeSectionViewOld}>
                    <Link
                      color="inherit"
                      // eslint-disable-next-line react/no-array-index-key
                      key={key}
                      className={classes.routeSectionTextOld}
                      // to={`${match.url}/${path}`}
                      to={`/${path}`}
                    >
                      {nameEle}
                    </Link>
                  </div>
                </div>
              )
            }
          })}
        </Breadcrumbs>
      </Container>
    )
  }
}

SimpleBreadcrumbs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  crumbs: PropTypes.arrayOf(PropTypes.any),
  programme: PropTypes.objectOf(PropTypes.any),
}

SimpleBreadcrumbs.defaultProps = {
  classes: {},
  match: {},
  task: {},
  crumbs: [],
  programme: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
  programme: state.programme,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    programmeActions: bindActionCreators(programmeActions, dispatch),
  }
}

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(SimpleBreadcrumbs)
  )
)
