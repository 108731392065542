import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { Router, Route, Redirect } from "react-router-dom"
import { AnimatedSwitch } from "react-router-transition"
// import ErrorBoundary from "./ErrorBoundry"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
// import { useAuth0 } from "@auth0/auth0-react"
import Loader from "../../components/Loader/Loader"
import PrivateRoute from "./PrivateRoute"
// import { privateRoutes } from "./Routes"
import ErrorPage from "../Error/Error"
import AccessDenied from "../AccessDenied/AccessDenied"
import DashboardLayout from "../Dashboard/Dashboard"
import appConfigActions from "../../redux/reducers/appConfig/actions"
import { useAuth0 } from "../../common/react-auth0-spa"
// import { publicRoutes } from "./Routes"

// const Dashboard = lazy(() => import("../Dashboard/Dashboard"))
// const GDashboard = lazy(() => import("../Gantt/Dashboard"))

// const Denied = () => {
//   return <h2>Access Denied</h2>
// }

const renderRoute = (route, props) => {
  // const crumbs = getCrumbs(route, props)
  // console.log("crumbs", crumbs)
  if (route.component) {
    return (
      <Suspense fallback={<Loader />}>
        {/* eslint-disable-next-line react/prop-types */}
        <DashboardLayout routeProps={props} route={route} match={props.match}>
          <route.component {...props} />
        </DashboardLayout>
      </Suspense>
    )
  }
  return null
}

// <ErrorBoundary>
/* <Suspense fallback={<Loader />}> */

const Routes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    // isAuthenticated,
    // logout,
    // user,
    // loading,
    isLoading,
    error,
  } = useAuth0()
  const { history } = props
  const {
    // panelOpen,
    // document: { document: documentOpen },
    // documentActions: { setDocument },
    appConfigActions: { getPrivateRoutes },
  } = props
  // console.log("Routes", history)
  const privateRoutes = getPrivateRoutes()

  if (error) {
    return <div>Oops... {error.message}</div>
  }
  if (isLoading) {
    return <Loader />
  }
  return (
    <Router history={history}>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        {/* <Switch> */}
        {/* {publicRoutes.map((route, index) => (
              <PrivateRoute
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                path={route.path}
                exact={route.exact}
                // component={route.component}
              >
                <route.component />
              </PrivateRoute>
            ))} */}
        <PrivateRoute
          exact
          path="/"
          render={() => <Redirect to="/dashboard" />}
        />
        {/* <PrivateRoute path="/" component={Dashboard} roleLevel={6}>
              // <Dashboard /> /
            </PrivateRoute> */}
        {privateRoutes.map((route, idx) => {
          if (route.path === "") {
            return null
          }
          const path = `/${route.path}`
          console.log("path", path)
          return (
            <PrivateRoute
              exact={route.exact}
              // eslint-disable-next-line react/no-array-index-key
              key={`route_${idx}`}
              // path={`${match.url}/${route.path}`}
              path={path}
              render={(rprops) => renderRoute(route, rprops)}
            />
          )
        })}
        <Route path="/denied" component={AccessDenied}>
          {/* <Denied /> */}
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
      </AnimatedSwitch>
      {/* </Switch> */}
    </Router>
  )
}

Routes.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  appConfigActions: PropTypes.objectOf(PropTypes.any),
}

Routes.defaultProps = {
  appConfigActions: {},
}

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
})

const mapDispatchToProps = (dispatch) => {
  return {
    appConfigActions: bindActionCreators(appConfigActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
