import Axios from "axios"
import { getTenantHeaders } from "../../../common/utils"
import settings from "../../../config/settings"
import {
  dashboardRoutes,
  adminRoutes,
  extraRoutes,
  clientPortalRoutes,
} from "../../../containers/AppContainer/Routes"

const actions = {
  GET_APP_SETTINGS: "appConfig/GET_APP_SETTINGS",
  SET_APP_SETTINGS: "appConfig/SET_APP_SETTINGS",
  SET_PRIVATE_ROUTES: "appConfig/SET_PRIVATE_ROUTES",

  setAppSettings: (data) => (dispatch) => {
    return dispatch({
      type: actions.SET_APP_SETTINGS,
      payload: data,
    })
  },

  getAppSettings: () => async (dispatch) => {
    try {
      const tenantHeaders = getTenantHeaders()
      const url = settings.endpoints.getAppSettings
      // console.log(url)
      const response = await Axios.get(url, {
        headers: tenantHeaders,
      })
      const { data: responseJson } = response
      console.log("getAppSettings", JSON.stringify(responseJson))
      // const responseJson = {
      //   id: 1,
      //   tenant_id: null,
      //   data: {
      //     features: {
      //       coreIDV: true,
      //       corePms: true,
      //       pmsAdmin: true,
      //       coreAdmin: true,
      //       coreAdminUsers: true,
      //       coreSalesForce: true,
      //       pmsClientPortal: true,
      //     },
      //   },
      //   status: 1,
      //   created_at: null,
      //   updated_at: null,
      // }
      if (responseJson && responseJson.id) {
        return dispatch(actions.setAppSettings(responseJson))
      }
    } catch (error) {
      console.log("getAppSettings", error)
    }
  },

  getPrivateRoutes: () => (dispatch, getState) => {
    const { appSettings } = getState().appConfig
    let routes = [...dashboardRoutes]
    if (appSettings && appSettings.data) {
      const {
        data: { features },
      } = appSettings
      if (features) {
        const { pmsAdmin, pmsClientPortal } = features
        if (pmsAdmin) {
          routes = routes.concat(adminRoutes)
        }

        if (pmsClientPortal) {
          routes = routes.concat(clientPortalRoutes)
        }
      }
    }

    if (process.env.NODE_ENV === "development") {
      routes = routes.concat(extraRoutes)
    }
    return routes
  },
}

export default actions
