import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Backdrop from "@material-ui/core/Backdrop"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import Zoom from "@material-ui/core/Zoom"
import Box from "@material-ui/core/Box"
import moment from "moment"
import Table from "../../components/TableComponent/Table"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import programmeActions from "../../redux/reducers/programme/actions"
import settings from "../../config/settings"
// import StatusChip from "../../components/StatusChip/StatusChip"
import ProjectStatusChip from "../../components/StatusChip/ProjectStatusChip"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  flex: {
    flex: 1,
  },
  footerView: {
    borderTop: "1px solid #D9D9D9",
    padding: "27.5px 49px",
  },
  okButton: {
    height: 50,
    backgroundColor: "#436EE0",
    borderRadius: "4px",
    fontSize: 15,
    color: "#FFFFFF",
    fontWeight: "normal",
    textTransform: "none",
  },
  cancelButton: {
    // backgroundColor: "#436EE0",
    height: 50,
    borderRadius: "4px",
    fontSize: 15,
    color: "#707070",
    fontWeight: "normal",
    textTransform: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderColor: "#E5E5E5",
    borderRadius: 6,
    borderWidth: 1,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 0, 0),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "80%",
    // minHeight: "680px",
    "&:focus": {
      outline: "none",
    },
  },
  titleView: {},
  title: {},
  subTitle: {},
  tableView: { flex: 1, width: "100%" },
})

class AddExistingProjectModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      error: "",
      addLoading: false,
      addError: "",
      data: [],
      columns: [
        {
          id: "project_name",
          numeric: false,
          disablePadding: false,
          label: "Project Name",
          sidebar: true,
        },
        {
          id: "start_date",
          numeric: false,
          disablePadding: false,
          label: "Start Date",
          sidebar: true,
        },
        {
          id: "status",
          numeric: false,
          disablePadding: false,
          label: "Status",
          sidebar: true,
        },
      ],
    }
  }

  componentDidMount() {
    this.getProjectStatusList()
  }

  getProjectStatusList = () => {
    const {
      taskActions: { getProjectStatusList },
    } = this.props
    getProjectStatusList()
  }

  handleOk = () => {
    let selected = []

    if (this.Table) {
      selected = this.Table.getSelected()
    }
    // console.log("handleOk", selected)
    if (!selected || !selected.length) {
      this.setState({ addError: "Please select at-least 1 project to add" })
    } else {
      this.addExistingProject(selected)
    }
  }

  addExistingProject = async (selected) => {
    const {
      apiActions: { apiCall },
      programme: { program },
      // eslint-disable-next-line react/prop-types
      setMessage,
    } = this.props
    this.setState({ addLoading: true, addError: "" })
    try {
      const reqdata = {
        program_id: program.id || "",
        project_id: selected,
        // request_user_id: 1,
      }
      // console.log(reqdata)
      const responseJson = await apiCall(
        settings.endpoints.addProgrammeProjects,
        "post",
        reqdata
      )
      this.setState({ addLoading: false })
      if (responseJson && responseJson.success) {
        this.handleClose(true)
      }
      setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
    } catch (error) {
      // console.log(error)
      this.setState({ addLoading: false })
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  getData = async () => {
    const {
      apiActions: { apiCall },
      programme: { program },
    } = this.props
    this.setState({ loading: true, error: "" })
    try {
      const reqdata = {
        org_id: 1,
        // status: 1,
        limit: 0,
        skip: 0,
        exclude_program_id: program.id || "",
      }
      const responseJson = await apiCall(
        settings.endpoints.getProjects,
        "post",
        reqdata
      )
      if (responseJson && responseJson.success) {
        this.setState({
          loading: false,
          data: responseJson.data,
          error: !responseJson.data ? "No Projects Found" : "",
        })
      } else {
        this.setState({ loading: false, error: "No Projects Found" })
      }
    } catch (error) {
      // console.log(error)
      this.setState({ loading: false, error: "Unable to Load Projects" })
    }
  }

  handleOpen = () => {
    this.setState({ open: true }, this.getData)
  }

  handleClose = (success = false) => {
    this.setState({ open: false }, () => {
      const { onClose } = this.props
      if (_.isFunction(onClose)) {
        onClose(success)
      }
    })
  }

  renderCell = (data) => {
    // const { classes } = this.props
    const {
      row,
      column: { id },
    } = data
    // const { tableActions } = this.state
    // if (id === "actions") {
    //   return <TableActions data={row} options={tableActions} />
    // }
    if (id === "start_date") {
      return moment(row[id]).format("DD/MM/YYYY")
    }

    if (id === "status") {
      return <ProjectStatusChip status={row[id]} />
    }
    return row[id]
  }

  onRowClick = () => {}

  render() {
    const { classes } = this.props
    const {
      open,
      loading,
      data,
      columns,
      error,
      addLoading,
      addError,
    } = this.state
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => this.handleClose(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open} exit={!open}>
          <div className={classes.paper}>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              className={classes.flex}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={2}
                className={classes.titleView}
              >
                <Typography className={classes.title}>Add Project</Typography>
                <Typography className={classes.subTitle}>
                  Select projects from the list below to link to programme
                </Typography>
              </Box>
              <Box p={2} className={classes.tableView}>
                <Table
                  ref={(o) => {
                    this.Table = o
                  }}
                  stickyHeader
                  rows={data}
                  columns={columns}
                  loading={loading}
                  loadingError={error}
                  renderCell={this.renderCell}
                  onRowClick={this.onRowClick}
                  renderFilter={() => null}
                  filter
                  checkboxes
                />
              </Box>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              direction="row"
              className={classes.footerView}
            >
              <Button
                variant="text"
                // color="secondary"
                disabled={loading}
                onClick={() => this.handleClose(false)}
                classes={{
                  root: classes.cancelButton,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={addLoading}
                onClick={this.handleOk}
                classes={{
                  root: classes.okButton,
                }}
              >
                {addLoading && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: 20, color: "#FFF" }}
                    color="inherit"
                  />
                )}
                <Typography variant="button">Complete</Typography>
              </Button>
            </Grid>

            {addError !== "" && (
              <Typography
                color="error"
                style={{ margin: "10px 0", fontSize: 16 }}
              >
                {addError}
              </Typography>
            )}
          </div>
        </Zoom>
      </Modal>
    )
  }
}

AddExistingProjectModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // theme: PropTypes.objectOf(PropTypes.any),
  // history: PropTypes.objectOf(PropTypes.any),
  // match: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  programme: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  // programmeActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func,
  onClose: PropTypes.func,
}

AddExistingProjectModal.defaultProps = {
  classes: {},
  // theme: {},
  // history: {},
  // match: {},
  // authActions: {},
  // taskActions: {},
  apiActions: {},
  programme: {},
  taskActions: {},
  // programmeActions: {},
  setMessage: () => {},
  onClose: () => {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  api: state.api,
  task: state.task,
  programme: state.programme,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    programmeActions: bindActionCreators(programmeActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AddExistingProjectModal
  )
)
