const drawerWidth = 200

const styles = (theme) => ({
  logo: {
    width: 100,
    height: 30,
    marginRight: 50,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  logoutText: {
    color: "#2F2F2F80",
    fontSize: 12,
    cursor: "pointer",
  },
  menuList: {
    padding: "20px 0px",
  },
  menuItem: {
    height: 52,
    "&:hover": {
      backgroundColor: "#F1F3F9",
      borderRadius: 6,
    },
  },
  menuItemIcon: {
    minWidth: 40,
    "& .navIcon": {
      height: 30,
      width: 30,
    },
  },
  subMenuItemIcon: {
    minWidth: 30,
    "& .navIcon": {
      height: 20,
      width: 20,
    },
  },
  menuItemText: {
    fontSize: 15,
    color: "#2F2F2F99",
  },
  subMenuItemText: {
    fontSize: "12px !important",
    color: "#2F2F2F99",
    "& span": {
      fontSize: "14px !important",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avatarSection: {
    color: theme.sidebar.color,
    padding: theme.spacing(2, 1, 2),
    opacity: 0.9,
    display: "block",
  },
  styledAvatar: {
    color: theme.sidebar.avatar.color,
    backgroundColor: theme.sidebar.avatar.background,
    marginRight: theme.spacing(2),
    // margin-left: 0;//${(props) => props.theme.spacing(4)}px;
  },
  sidebarFooterText: {
    color: theme.sidebar.footer.color,
  },
  LLink: {
    paddingLeft: theme.spacing(15),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#A5A5A5",
    textDecoration: "none",
    "&:hover": {
      color: "#FF9600",
    },
  },
  profileAvatarView: {
    padding: "40px 16px 0",
    // alignItems: "center",
    // justifyContent: "center",
  },
  profileItem: {
    padding: "0px 16px 40px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  toolbarIconOpen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  userAvatar: {
    backgroundColor: "#0000",
    border: "solid 1px #278BED",
    color: "#278BED",
    fontSize: 18,
    marginRight: 10,
    height: 40,
    width: 40,
  },
  userName: {
    fontSize: 14,
  },
  userRole: {},
  m0: {
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  p0: {
    padding: 0,
  },
  badgeAnchorOrigin: {
    top: 6,
    right: 12,
  },
})

export default styles
