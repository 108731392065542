import React from "react"

const ProgrammeDashboardIconSub = ({
  style = {},
  fill = "rgba(0, 0, 0, 0.35)",
  width = "25",
  className = "ProgrammeDashboardIconSub",
  height = "35",
  viewBox = "0 0 100 125",
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <path d="M50,28.3l-13.5-9.5L50,9.2l13.5,9.5L50,28.3z M36.5,38.8L50,48.3l13.5-9.5l1.4-1l0,0V22.6l-13,9.2l-0.8,0.6  c-0.3,0.2-0.8,0.4-1.2,0.4s-0.8-0.1-1.2-0.4L48,31.8l-13-9.2v15.1l0,0L36.5,38.8z M18,73v-8.2h30V73c0.7-0.1,1.3-0.2,2-0.2  s1.3,0.1,2,0.2v-8.2h30V73c0.7-0.1,1.3-0.2,2-0.2s1.3,0.1,2,0.2V62.8c0-1.1-0.9-2-2-2H52v-9l-0.8,0.6c-0.3,0.2-0.8,0.4-1.2,0.4  s-0.8-0.1-1.2-0.4L48,51.8v9H16c-1.1,0-2,0.9-2,2V73c0.7-0.1,1.3-0.2,2-0.2S17.3,72.8,18,73z M50,76.8c-3.9,0-7,3.1-7,7s3.1,7,7,7  s7-3.1,7-7S53.9,76.8,50,76.8z M84,76.8c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S87.9,76.8,84,76.8z M16,76.8c-3.9,0-7,3.1-7,7  s3.1,7,7,7s7-3.1,7-7S19.9,76.8,16,76.8z" />
    </svg>
  )
}

export default ProgrammeDashboardIconSub
