import React from "react"

const NotificationIcon = ({
  style = {},
  fill = "rgba(0, 0, 0, 0.25)",
  width = "100%",
  className = "navIcon",
  height = "100%",
  viewBox = "0 0 100 100",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34.163"
      viewBox="0 0 35 34.163"
    >
      <g id="noun_notifications_163791" transform="translate(0 -0.4)">
        <g id="Group_1562" data-name="Group 1562" transform="translate(0 0.4)">
          <g id="Group_1561" data-name="Group 1561" transform="translate(0 0)">
            <g
              id="Group_1560"
              data-name="Group 1560"
              transform="translate(0 0)"
            >
              <g
                id="Group_1559"
                data-name="Group 1559"
                transform="translate(0 0)"
              >
                <path
                  id="Path_299"
                  data-name="Path 299"
                  d="M28.12,17.818v8.14c0,3.4-.048,4.295-.358,5.482q-.041.155-.088.311a5.346,5.346,0,0,1-3.117,3.244c-.069.022-.168.053-.267.081-1.133.325-1.983.376-5.234.376H10.544c-3.251,0-4.1-.05-5.235-.376q-.147-.042-.3-.091a5.35,5.35,0,0,1-3.1-3.264c-.021-.073-.05-.176-.077-.279-.311-1.187-.358-2.077-.358-5.482V17.043c0-3.405.048-4.3.359-5.483q.04-.154.087-.309A5.347,5.347,0,0,1,5.043,8.006c.069-.022.167-.052.266-.081C6.442,7.6,7.292,7.55,10.544,7.55h7.772a.776.776,0,0,0,0-1.55H10.544c-3.4,0-4.315.054-5.627.431q-.175.05-.35.108A6.891,6.891,0,0,0,.525,10.751c-.047.154-.081.276-.113.4C.052,12.523,0,13.484,0,17.043v8.915c0,3.557.052,4.518.411,5.891q.048.184.1.368A6.893,6.893,0,0,0,4.537,36.45c.147.049.263.085.38.119,1.312.377,2.229.431,5.627.431h8.512c3.4,0,4.314-.054,5.625-.431q.176-.05.352-.109a6.89,6.89,0,0,0,4.042-4.212c.047-.154.082-.277.114-.4.359-1.374.411-2.334.411-5.891v-8.14a.741.741,0,1,0-1.48,0Z"
                  transform="translate(0 -2.837)"
                  fill="#babbbd"
                  fillRule="evenodd"
                />
                <path
                  id="Path_300"
                  data-name="Path 300"
                  d="M37.4,16.4a8,8,0,1,0-8-8,8,8,0,0,0,8,8Zm0-2a6,6,0,1,1,6-6,6,6,0,0,1-6,6Z"
                  transform="translate(-10.4 -0.4)"
                  fill="#babbbd"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default NotificationIcon
