import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// material - ui components
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import withStyles from "@material-ui/styles/withStyles"
import Autocomplete from "@material-ui/lab/Autocomplete"

// icons
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import documentActions from "../../redux/reducers/document/actions"
import settings from "../../config/settings"
import DialogAlert from "../DialogAlert/DialogAlert"

const styles = (theme) => ({
  boxContainer: {
    padding: 0,
    marginTop: 0,
  },
  boxContent: {
    position: "relative",
    paddingTop: 40,
  },
  mDivide: {
    // marginBottom: "10px",
    // paddingBottom: "10px",
  },
  addIcon: {
    fill: "#000",
    fontSize: 35,
    height: 25,
    position: "absolute",
    top: 8,
    right: 0,
    zIndex: 11,
    cursor: "pointer",
  },
  emptyText: {
    fontSize: 14,
    textAlign: "center",
  },
  selectCont: {
    height: "27px !important",
  },
  autocompletePopper: {
    top: "10px !important",
    // width: "300px !important",
  },
  autocompletePaper: {
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 20px #00000033",
    borderRadius: "4px",
    width: 350,
  },
  autocompleteOption: {
    padding: "0 10px",
    height: 40,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#F1F3F9",
    },
    // Selected
    '&[aria-selected="true"]': {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
  },
  dependantInputRow: {
    margin: "5px 0",
    "& .MuiTypography-root": {
      fontSize: 14,
      textTransform: "Capitalize",
    },
  },
  dependantInputRowTitle: {
    marginTop: 43,
  },
  closeIconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  closeIcon: {
    height: 20,
    width: 20,
    color: "#2f2f2f",
  },
})

const addSubTaskObj = {
  id: 0,
}

class SubTaskSection extends Component {
  constructor(props) {
    super(props)
    this.confirmDelete = React.createRef()
    this.confirmAdd = React.createRef()
    // const {
    //   task: { task },
    // } = props
    //   const dependencyList =
    //     task && _.isArray(task.project_task_dependencies)
    //       ? task.project_task_dependencies
    //       : []
    this.state = {
      open: false,
      subTaskList: [],
      taskList: [],
      selectedSubTask: undefined,
      deleteItem: undefined,
    }
  }

  componentDidMount() {
    const {
      task: { task, project },
    } = this.props
    this.getTaskSubtask(task)
    this.getTaskWithoutSubTask(project)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (nextTask.open && !_.isEqual(task.task, nextTask.task)) {
      if (nextTask.task) {
        this.getTaskSubtask(nextTask.task)
        this.getTaskWithoutSubTask(nextTask.project)
      }
    }

    if (nextTask.open && !_.isEqual(task.project, nextTask.project)) {
      if (nextTask.project) {
        this.getTaskWithoutSubTask(nextTask.project)
      }
    }
  }

  getTaskWithoutSubTask = async (project) => {
    const {
      apiActions: { apiCall },
      task: { task },
    } = this.props
    if (!project || !project.id) {
      return
    }
    try {
      const data = {}
      const responseJson = await apiCall(
        settings.endpoints.getTaskWithoutSubTask(project.id),
        "get",
        data
        // headers
      )
      // console.log("getTaskWithoutSubTask", responseJson)
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        let compareId
        if (task && task.id) {
          compareId = task.id
        }
        const filterAry = _.filter(responseJson.data, (i) => i.id !== compareId)
        this.setState({ taskList: filterAry })
      } else {
        this.setState({ taskList: [] })
      }
    } catch (error) {
      // console.log("error", error)
      this.setState({ taskList: [] })
    }
  }

  getTaskSubtask = async (task) => {
    const {
      apiActions: { apiCall },
    } = this.props
    if (!task || !task.id) {
      return
    }
    try {
      const data = {}
      const responseJson = await apiCall(
        settings.endpoints.getTaskSubtask(task.id),
        "get",
        data
        // headers
      )
      // console.log("responseJson", responseJson)
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        this.setState({ subTaskList: responseJson.data })
      } else {
        this.setState({ subTaskList: [] })
      }
    } catch (error) {
      // console.log("error", error)
      this.setState({ subTaskList: [] })
    }
  }

  removeTaskApi = async () => {
    const { deleteItem } = this.state
    console.log("removeTaskApi", deleteItem)
    if (!deleteItem) {
      return
    }
    const { rTask, index } = deleteItem
    const {
      apiActions: { apiCall },
      task: { task },
      setMessage,
      taskActions: { getTasks },
    } = this.props
    if (!task || !task.id || !rTask || !rTask.id) {
      return
    }
    try {
      const data = {}
      const responseJson = await apiCall(
        settings.endpoints.removeTaskSubTask(rTask.id),
        "delete",
        data
        // headers
      )
      // console.log("responseJson", responseJson)
      setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
      if (responseJson.success) {
        getTasks(0, true)
        this.remove(rTask, index)
      }
    } catch (error) {
      // console.log("error", error)
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  addSubTaskApi = async () => {
    const {
      apiActions: { apiCall },
      task: { task, project },
      setMessage,
      taskActions: { getTasks },
    } = this.props
    const { subTaskList, selectedSubTask } = this.state
    if (!task || !task.id || !selectedSubTask || !selectedSubTask.id) {
      return
    }
    try {
      const data = {
        task_id: task.id,
        sub_task_id: selectedSubTask.id,
      }
      const responseJson = await apiCall(
        settings.endpoints.taskSubtask,
        "post",
        data
        // headers
      )
      // console.log("responseJson", responseJson)
      setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
      if (responseJson.success) {
        subTaskList.push(selectedSubTask)
        getTasks(0, true)
        this.setState({ subTaskList, selectedSubTask: undefined }, () =>
          this.getTaskWithoutSubTask(project)
        )
      }
    } catch (error) {
      // console.log("error", error)
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  toggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  remove = (item, index) => {
    const {
      task: { project },
    } = this.props
    const { subTaskList } = this.state
    subTaskList.splice(index, 1)
    this.setState({ subTaskList }, () => {
      this.getTaskWithoutSubTask(project)
    })
  }

  addSubTask = () => {
    const { subTaskList } = this.state
    // const {
    //   task: { task },
    // } = this.props
    const dList = [
      {
        ...addSubTaskObj,
        // project_task_id: task.id,
      },
      ...subTaskList,
    ]
    this.setState({ subTaskList: dList })
  }

  onChangeTaskName = (event, newValue) => {
    // const { subTaskList } = this.state
    // eslint-disable-next-line array-callback-return
    // subTaskList.map((item, dI) => {
    //   if (dI === index) {
    //     // eslint-disable-next-line no-param-reassign
    //     item[key] = newValue
    //   }
    // })
    if (newValue && newValue.id) {
      this.setState({ selectedSubTask: newValue }, this.askAddTask)
    }
    // this.setState({ subTaskList })
  }

  onDeleteSubTaskConfirm = () => {}

  askAddTask = () => {
    // this.addSubTaskApi(newTask)
    if (this.confirmAdd) {
      this.confirmAdd.open()
    }
  }

  askDelete = (row, index) => () => {
    this.setState(
      {
        deleteItem: {
          rTask: row,
          index,
        },
      },
      () => {
        if (this.confirmDelete) {
          this.confirmDelete.open()
        }
      }
    )
  }

  renderHeader = () => {
    const { open } = this.state
    return (
      <ListItem button divider onClick={this.toggle}>
        <ListItemIcon>
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </ListItemIcon>
        <ListItemText primary="Subtasks:" />
      </ListItem>
    )
  }

  renderSubTaskRow = (row, index) => {
    const {
      classes,
      // task: { dependencyTypeList },
    } = this.props
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={2}
        wrap="wrap"
        className={classes.dependantInputRow}
      >
        <Grid
          item
          // xs
          xs={11}
          sm={11}
          md={11}
          lg={11}
          xl={11}
          style={{
            flexShrink: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <Typography className={classes.taskName}>
            {row.id} - {row.task_name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          className={classes.closeIconView}
        >
          <Tooltip title="Remove">
            <CloseIcon
              className={classes.closeIcon}
              onClick={this.askDelete(row, index)}
            />
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  // getTaskList = ({ index, isParent }) => {
  //   const {
  //     task: { taskList },
  //   } = this.props
  //   const forbiddenIds = []
  //   const retAry = []
  //   // eslint-disable-next-line array-callback-return
  //   taskList.map((taskItem) => {
  //     const allowed = forbiddenIds.indexOf(taskItem.id) < 0
  //     if (allowed && isParent) {
  //       retAry.push(taskItem)
  //     }
  //     if (
  //       !isParent &&
  //       _.isArray(taskItem.childrens) &&
  //       taskItem.childrens.length
  //     ) {
  //       // eslint-disable-next-line array-callback-return
  //       taskItem.childrens.map((cI) => {
  //         const callowed = forbiddenIds.indexOf(cI.id) < 0
  //         if (callowed) {
  //           retAry.push(cI)
  //         }
  //       })
  //     }
  //   })
  //   return _.sortBy(retAry, "id")
  // }

  renderTaskDropdown = () => {
    const { classes } = this.props
    const { taskList, selectedSubTask } = this.state
    return (
      <FormControl
        fullWidth
        className={classes.inputRow}
        // error={!!errors.company}
      >
        <Autocomplete
          id={`add_sub_task_row_${
            selectedSubTask ? selectedSubTask.id : "undef"
          }`}
          options={taskList}
          // groupBy={(option) => option.first}
          getOptionLabel={(option) => `${option.id} - ${option.task_name}`}
          value={selectedSubTask}
          onChange={this.onChangeTaskName}
          classes={{
            popper: classes.autocompletePopper,
            paper: classes.autocompletePaper,
            option: classes.autocompleteOption,
          }}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              // error={!!errors.company}
              InputProps={{
                ...params.InputProps,
                className: classes.selectCont,
                disableUnderline: true,
                fullWidth: true,
              }}
              id={`add_sub_task_row_input_${
                selectedSubTask ? selectedSubTask.id : "undef"
              }`}
              type="Name"
              placeholder="Select Task Name"
            />
          )}
        />
      </FormControl>
    )
  }

  renderAddSubTaskRow = () => {
    const row = addSubTaskObj
    const { classes } = this.props
    // const isParent = false
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        spacing={2}
        className={classes.dependantInputRow}
      >
        <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>
          <Typography>Task Name</Typography>
          {this.renderTaskDropdown({ row })}
        </Grid>
        <Grid item xs={1} sm={1} md={4} lg={4} xl={4} />
      </Grid>
    )
  }

  render() {
    const { open, subTaskList, selectedSubTask, deleteItem } = this.state
    const { classes } = this.props
    const condition = _.isArray(subTaskList) && subTaskList.length
    // const condition = false

    let addTaskName = ""
    if (selectedSubTask && selectedSubTask.task_name) {
      addTaskName = selectedSubTask.task_name
    }
    let deleteTaskName = ""
    if (deleteItem && deleteItem.rTask && deleteItem.rTask.task_name) {
      deleteTaskName = deleteItem.rTask.task_name
    }
    return (
      <Box className={classes.boxContainer}>
        <List disablePadding>
          {this.renderHeader()}
          <Collapse in={open} timeout="auto">
            <Box paddingY={1} paddingX={2} className={classes.boxContent}>
              <Tooltip title="Add Subtask">
                <AddIcon
                  className={classes.addIcon}
                  onClick={this.addSubTask}
                />
              </Tooltip>
              {/* {condition ? this.renderDependencyHeader() : null} */}
              {/* {condition ? this.renderAddSubTaskRow() : null} */}
              {condition ? (
                subTaskList.map((tD, tI) => {
                  if (!tD.id) {
                    return this.renderAddSubTaskRow()
                    // return null
                  }
                  return this.renderSubTaskRow(tD, tI)
                })
              ) : (
                <Typography className={classes.emptyText}>
                  No Subtasks for this task
                </Typography>
              )}
            </Box>
            <Divider className={classes.mDivide} />
          </Collapse>
        </List>
        <DialogAlert
          ref={(o) => {
            this.confirmAdd = o
          }}
          title="Please Confirm"
          message={`Are you sure want to add ${addTaskName} as a Subtask ?`}
          handleOK={this.addSubTaskApi}
          handleCancel={() => {
            this.setState({ selectedSubTask: undefined })
          }}
        />
        <DialogAlert
          ref={(o) => {
            this.confirmDelete = o
          }}
          title="Please Confirm"
          message={`Are you sure want to remove Subtask ${deleteTaskName} from parent?`}
          handleOK={this.removeTaskApi}
          handleCancel={() => {
            this.setState({ deleteItem: undefined })
          }}
        />
      </Box>
    )
  }
}

SubTaskSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  api: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  authActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func,
  onRowClick: PropTypes.func,
}

SubTaskSection.defaultProps = {
  classes: {},
  auth: {},
  task: {},
  api: {},
  document: {},
  authActions: {},
  apiActions: {},
  taskActions: {},
  documentActions: {},
  setMessage: () => {},
  onRowClick: () => {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
  document: state.document,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SubTaskSection
  )
)
