import React, { Component } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import _ from "lodash"
import withStyles from "@material-ui/styles/withStyles"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Checkbox from "@material-ui/core/Checkbox"
import NavIcon from "../NavIcon/NavIcon"
import CheckIcon from "../NavIcon/CheckIcon"

const icon = <NavIcon style={{ height: 20, width: 20 }} />
const checkedIcon = <CheckIcon style={{ height: 16, width: 16 }} />

const styles = (theme) => ({
  formControlShort: {
    margin: "0 8px",
    minWidth: 150,
    maxWidth: 150,
    "& .MuiSelect-select": {
      "&:focus": {
        background: "none",
      },
    },
    "label + &": {
      marginLeft: 20,
    },
    "& .MuiInputLabel-root": {
      marginLeft: 10,
    },
    "& .MuiFormLabel-root": {
      "&.Mui-focused": {
        color: "#2F2F2F80",
      },
    },
  },
  formControl: {
    margin: "0 8px",
    minWidth: 200,
    maxWidth: 200,
    "& .MuiSelect-select": {
      "&:focus": {
        background: "none",
      },
    },
    "label + &": {
      marginLeft: 20,
    },
    "& .MuiInputLabel-root": {
      marginLeft: 10,
    },
    "& .MuiFormLabel-root": {
      "&.Mui-focused": {
        color: "#2F2F2F80",
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },

  // disabled: {},
  // filled: {},
  // icon: {},
  // iconFilled: {},
  // iconOpen: {},
  // iconOutlined: {},
  // outlined: {},
  // root: {},
  // select: {},
  // selectMenu: {},

  checkedIcon: {
    borderLeft: "2px solid #436EE0",
  },
  uncheckedIcon: {
    borderLeft: "2px solid #0000",
  },
  listItem: {
    padding: 0,
    height: 40,
  },
  popoverRoot: {
    // top: "45px !important",
    // left: "-10px !important",
  },
  popoverPaper: {
    marginLeft: -10,
    width: 200,
    top: "60px !important",
    // left: "-10px !important",
  },
})

class SelectComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderCheckBox = (selected) => {
    const { multiple } = this.props
    if (multiple) {
      // const selected = _.isArray(value) && value.indexOf(option.id) > -1
      return (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          size="small"
          checked={selected}
        />
      )
    }

    return null
  }

  renderOption = (option) => {
    const { multiple, value, classes, getOptionLabel } = this.props
    let selected = false
    if (multiple && _.isArray(value) && value.indexOf(option) > -1) {
      selected = true
    } else if (!multiple && value === option.id) {
      selected = true
    }
    return (
      <MenuItem
        key={option}
        value={option}
        className={clsx(classes.listItem, selected && classes.checkedIcon)}
      >
        {this.renderCheckBox(selected)}
        <ListItemText primary={getOptionLabel(option)} />
      </MenuItem>
    )
  }

  renderValue = (selected) => {
    const { getOptionLabel } = this.props
    if (_.isArray(selected)) {
      const ary = []
      // eslint-disable-next-line
      selected.map((opt) => {
        ary.push(getOptionLabel(opt))
      })

      return ary.join(", ")
    }
    return selected.join(", ")
  }

  render() {
    const {
      options,
      classes,
      label,
      isShort,
      getOptionLabel,
      ...rest
    } = this.props

    return (
      <FormControl
        className={isShort ? classes.formControlShort : classes.formControl}
        fullWidth
      >
        {label && (
          <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
        )}
        <Select
          //   labelId="mutiple-select-label"
          //   id="mutiple-select"
          //   multiple
          //   value={personName}
          //   onChange={handleChange}
          {...rest}
          label={false}
          multiple
          input={
            <Input
              disableUnderline
              style={{ background: "#0000", height: 36 }}
              // placeholder={placeholder}
            />
          }
          renderValue={this.renderValue}
          MenuProps={{
            PopoverClasses: {
              root: classes.popoverRoot,
              paper: classes.popoverPaper,
            },
          }}
          // classes={classes}
        >
          {options.map(this.renderOption)}
        </Select>
      </FormControl>
    )
  }
}

SelectComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
  multiple: PropTypes.bool,
  isShort: PropTypes.bool,
  label: PropTypes.string,
  getOptionLabel: PropTypes.func,
}

SelectComponent.defaultProps = {
  classes: {},
  options: [],
  value: "",
  label: "",
  multiple: false,
  isShort: false,
  getOptionLabel: () => "",
}

export default withStyles(styles)(SelectComponent)
