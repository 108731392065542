export default {
  backgroundColor: "#FFFFFF",
  // border: "1px solid #436EE0",
  // padding: "5px 0px",
  // borderRadius: 20,
  // marginLeft: -5,
  color: "#436EE0",
  zIndex: 1,
  position: "relative",
}
