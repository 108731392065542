import actions from "./actions"

const initialState = {
  tLoading: false,
  templateList: [],
  tError: "",
  typeList: [],
  statusList: [],
  projectsList: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_TEMPLATE_TYPES_LIST:
      return {
        ...state,
        typeList: action.payload,
      }

    case actions.SET_TEMPLATE_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload,
      }

    case actions.SET_PROJECTS_LIST:
      return {
        ...state,
        projectsList: action.payload,
      }

    case actions.GET_TEMPLATE_LIST:
      return {
        ...state,
        tLoading: true,
        templateList: [],
        tError: "",
      }

    case actions.GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        tLoading: false,
        templateList: action.payload,
        tError: "",
      }

    case actions.GET_TEMPLATE_LIST_FAIL:
      return {
        ...state,
        tLoading: false,
        templateList: [],
        tError: action.payload,
      }

    default:
      return state
  }
}
