import React, { Component } from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import CardMedia from "@material-ui/core/CardMedia"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/styles"
import styles from "./Error.styles"
import images from "../../config/images"
import PoweredBy from "../../components/PoweredBy/PoweredBy"

class Error extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} color="default">
          <Toolbar className={classes.toolbar}>
            <CardMedia
              className={classes.logo}
              image={images.logo}
              title="Saffron IOS"
            />
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} className={classes.container}>
          <Paper className={classes.form} elevation={3}>
            <Grid
              container
              justify="center"
              style={{ flexDirection: "column" }}
            >
              <Grid
                container
                style={{ marginBottom: 20, marginTop: 30 }}
                alignItems="center"
                justify="center"
              >
                <Typography
                  variant="h1"
                  style={{ fontSize: "30px", color: "#2F2F2F80" }}
                >
                  Oops Something went wrong...
                </Typography>
              </Grid>
              <Grid>
                <Typography align="center" style={{ color: "#2F2F2F80" }}>
                  <div>
                    if the issue continues please contact
                    <br />
                    SaffronIOS Support
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <PoweredBy />
        </Container>
      </div>
    )
  }
}

export default withStyles(styles)(Error)
