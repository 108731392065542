import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import withStyles from "@material-ui/styles/withStyles"
import authActions from "../../redux/reducers/auth/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./DialogAlert.styles"

class DialogAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  open = () => {
    this.setState({ open: true })
  }

  close = (callback = null) => {
    this.setState({ open: false }, () => {
      if (_.isFunction(callback)) {
        callback()
      }
    })
  }

  handleClose = (bool = false) => () => {
    const { handleOK, handleCancel } = this.props
    if (bool) {
      this.close(handleOK)
    } else {
      this.close(handleCancel)
    }
  }

  render() {
    const { open } = this.state
    const { title, message, warning } = this.props
    return (
      <Dialog
        open={open}
        onClose={this.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title !== "" && (
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        )}
        <DialogContent>
          {message !== "" && (
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          )}
        </DialogContent>
        {warning ? (
          <DialogActions>
            <Button onClick={this.handleClose(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={this.handleClose(true)} color="primary">
              Yes
            </Button>
            <Button onClick={this.handleClose(false)} color="primary">
              No
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

DialogAlert.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.any),
  // task: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  message: PropTypes.string,
  handleOK: PropTypes.func,
  handleCancel: PropTypes.func,
  warning: PropTypes.bool,
}

DialogAlert.defaultProps = {
  // classes: {},
  // task: {},
  // auth: {},
  // authActions: {},
  // taskActions: {},
  title: "",
  message: "",
  handleOK: () => {},
  handleCancel: () => {},
  warning: false,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DialogAlert
  )
)
