import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import CircularProgress from "@material-ui/core/CircularProgress"
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Button from "@material-ui/core/Button"
import withStyles from "@material-ui/styles/withStyles"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
import settings from "../../config/settings"
import TimeAgo from "../TimeAgo/TimeAgo"
import { getLocalMoment } from "../../common/utils"

class ActivitySection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      perpage: 5,
      page: 1,
      activities: [],
      more: true,
    }
  }

  componentDidMount() {
    this.getAllActivity()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (!_.isEqual(task.task, nextTask.task) && nextTask.open) {
      if (nextTask.task) {
        this.getAllActivity(nextTask.task)
      }
    }
  }

  onLoadMore = () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, this.getAllActivity)
  }

  getAllActivity = async (nextTask = null) => {
    const {
      // auth,
      apiActions: { apiCall },
      task: { task: currentTask },
    } = this.props
    const { perpage, page } = this.state
    let task = currentTask
    if (nextTask) {
      task = nextTask
    }
    if (!task || !task.id) {
      return
    }
    this.setState({ loading: true })
    try {
      const reqdata = {
        project_task_id: task.id,
        type: "activity",
        skip: (page - 1) * perpage,
        limit: perpage,
      }
      const responseJson = await apiCall(
        settings.endpoints.getAllActivity(task.id),
        "post",
        reqdata
      )
      let { more, activities } = this.state
      if (responseJson && responseJson.success) {
        activities = activities.concat(responseJson.data)
        const sorted = _.reverse(
          _.sortBy(activities, (i) => getLocalMoment(i.created_at).unix())
        )
        activities = sorted
        more = responseJson.data && responseJson.data.length
      }
      this.setState({ loading: false, activities, more })
    } catch (error) {
      console.log(error)
      this.setState({ loading: false })
    }
  }

  render() {
    const { classes } = this.props
    const { activities, loading, more } = this.state

    // if (loading) {
    //   return (
    //     <Grid
    //       container
    //       alignItems="center"
    //       justify="center"
    //       style={{ minHeight: 250 }}
    //     >
    //       <CircularProgress size={50} />
    //     </Grid>
    //   )
    // }

    if (!activities || activities.length === 0) {
      return (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: 300 }}
        >
          <Typography>No activities yet</Typography>
        </Grid>
      )
    }
    return (
      <List style={{ padding: "8px 40px 20px 40px" }}>
        {activities.length > 0 &&
          activities.map((ele) => {
            const userName = ele.updated_by_name || "Unknown"
            const avatarInitials = userName
              .split(/\s/)
              // eslint-disable-next-line
              .reduce((response, word) => (response += word.slice(0, 1)), "")
            return [
              <ListItem alignItems="flex-start" disableGutters>
                <ListItemAvatar className={classes.CommentListItemAvatar}>
                  <Avatar
                    alt={avatarInitials}
                    src="/static/images/avatar/1.jpg"
                    className={classes.CommentUserAvatar}
                  >
                    {avatarInitials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // primary={ele.updated_by_name || ""}
                  primary={
                    <Grid
                      container
                      flexDirection="row"
                      alignItems="center"
                      className={classes.CommentTextPrimaryGrid}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.CommentTextPrimary}
                        color="textPrimary"
                      >
                        {ele.updated_by_name || ""}
                      </Typography>
                      <TimeAgo
                        time={ele.created_at}
                        format="DD/MM/YYYY hh:mm A"
                        style={{ fontSize: 12, marginLeft: 10 }}
                      />
                    </Grid>
                  }
                  classes={{
                    multiline: classes.CommentTextMultiline,
                    // primary: classes.CommentTextPrimary,
                    root: classes.CommentTextRoot,
                    secondary: classes.CommentTextSecondary,
                  }}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.block}
                        color="textSecondary"
                      >
                        {`${ele.message || ""}`}
                        {/* {"Sign off Investment Policy"}
                        {"\n"} */}
                      </Typography>
                      {/* {`${ele.message || ""}`} */}
                    </>
                  }
                />
                {/* <ListItemSecondaryAction
                  className={classes.CommentListItemSecondary}
                >
                  <TimeAgo time={ele.created_at} style={{ fontSize: 12 }} />
                </ListItemSecondaryAction> */}
              </ListItem>,
              <Divider component="li" />,
            ]
          })}
        {loading && (
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: 300 }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        {!loading && more && activities && activities.length > 0 ? (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.activitiesLoadMoreContainer}
          >
            <Button
              color="inherit"
              href="#"
              onClick={this.onLoadMore}
              className={classes.loadMoreText}
            >
              Load More Activities
            </Button>
          </Grid>
        ) : null}
      </List>
    )
  }
}

ActivitySection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
}

ActivitySection.defaultProps = {
  classes: {},
  task: {},
  apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ActivitySection)
)
