import apiActions from "../../middleware/api/actions"
import authActions from "../auth/actions"
import { apiCall } from "../../../common/apiHelper"

const actions = {
  SET_JWT_TOKEN: "api/SET_JWT_TOKEN",
  getJwtToken: () => (dispatch) =>
    dispatch({
      type: apiActions.GET_API_JWT,
    }),

  callApi: ({ endpoint, method, data, onSuceess, onFail }) => (dispatch) =>
    dispatch({
      type: apiActions.API_CALL,
      endpoint,
      method,
      data,
      onSuceess,
      onFail,
    }),

  apiCall: (endpoint, method, data, headers = {}) => (dispatch, getState) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const { auth } = getState()
      try {
        const reqheaders = {
          "content-type": "application/json",
          // Authorization: `Bearer ${auth.token}`,
          ...headers,
        }
        if (auth.token && auth.token !== "") {
          reqheaders.Authorization = `Bearer ${auth.token}`
        }

        // TODO - clean up quick fix
        // if(localStorage.getItem("saffron-token") !== null){
        //   reqheaders.Authorization = `Bearer ${localStorage.getItem("saffron-token")}`;
        // }

        // Set auth user id on every request
        data.request_user_id = auth.userdata.auth_user_id

        const responseJson = await apiCall(endpoint, method, data, reqheaders)
        // console.log("apiCall reducer")
        resolve(responseJson)
      } catch (error) {
        // console.log("apiCall reducer", error)
        if (error.status) {
          if (error.status === 401) {
            dispatch(authActions.setUserData("", {}))
            window.location.href = "/"
          }

          if (
            error.status === 403
            // ||
            // error.status === 400 ||
            // error.status === 500
          ) {
            // dispatch(authActions.setUserData("", {}))
            window.location.href = "/denied"
          }
        }
        reject(error)
      }
    })
  },
}

export default actions
