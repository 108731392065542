import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import InputLabel from "@material-ui/core/InputLabel"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
// import { apiCall } from "../../common/apiHelper"
import settings from "../../config/settings"

class Reason extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reason: "",
      errors: {},
    }
  }

  // updateStatusApi

  updateStatusApi = async () => {
    const {
      statusList,
      prevStatus,
      status,
      setMessage,
      addActivityApi,
    } = this.props
    const { reason } = this.state
    const sObj = _.find(statusList, (e) => e.status_code === status)
    const pObj = _.find(statusList, (e) => e.status_code === prevStatus)
    const {
      auth,
      task: { task },
      apiActions: { apiCall },
    } = this.props
    try {
      const data = {
        update_type: "status",
        status: sObj ? sObj.id : 1,
        reason,
      }
      const headers = {
        "content-type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "put",
        data,
        headers
      )
      // console.log("assignApi", responseJson)
      // if (responseJson && responseJson.success) {
      // this.setDefaultView()
      if (responseJson && responseJson.success) {
        addActivityApi(
          `Updated status ${
            pObj && pObj.status_desc ? `from ${pObj.status_desc}` : ""
          } to ${status}`
        )
        // this.getTaskData()
      }
      setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })
      // }
    } catch (error) {
      // console.log(error)
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  onChange = (key) => (e) => {
    const { errors } = this.state
    this.setState({
      [key]: e.target.value,
      errors: {
        ...errors,
        [key]: undefined,
      },
    })
  }

  validate = () => {
    const { reason } = this.state
    const { status } = this.props
    let valid = true
    const errors = {}
    if (status !== "complete" && reason.trim() === "") {
      valid = false
      errors.reason = "Please add reason"
    }

    if (valid) {
      this.updateStatusApi()
    } else {
      this.setState({ errors })
    }
  }

  renderError = (key) => {
    const { errors } = this.state
    if (errors[key]) {
      return (
        <Typography color="error" style={{ fontSize: 12 }}>
          {errors[key]}
        </Typography>
      )
    }
    return null
  }

  render() {
    const { reason } = this.state
    const { status } = this.props
    return (
      <Box paddingY={2} display="flex" flexDirection="column">
        <InputLabel>Add Reason :</InputLabel>
        <TextField
          id="outlined-multiline-static"
          placeholder="Add reason here"
          multiline
          rows={2}
          disableUnderline
          value={reason}
          onChange={this.onChange("reason")}
          fullWidth
          InputProps={{
            fullWidth: true,
            disableUnderline: true,
          }}
        />
        {this.renderError("reason")}
        <Box paddingY={1}>
          <Button
            onClick={this.validate}
            // disabled={status !== "complete" && reason.trim() === ""}
            variant="outlined"
            size="small"
            color="primary"
            disableElevation
            style={{
              marginTop: 10,
              alignSelf: "flex-end",
            }}
          >
            {`Update status to ${status || ""}`}
          </Button>
        </Box>
      </Box>
    )
  }
}

Reason.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func.isRequired,
  addActivityApi: PropTypes.func.isRequired,
  statusList: PropTypes.func.isRequired,
  status: PropTypes.func.isRequired,
  prevStatus: PropTypes.func.isRequired,
}

Reason.defaultProps = {
  // classes: {},
  task: {},
  auth: {},
  // authActions: {},
  // taskActions: {},
  apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Reason)
)
