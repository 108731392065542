import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import withStyles from "@material-ui/styles/withStyles"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ButtonGroup from "@material-ui/core/ButtonGroup"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  menu: {
    // backgroundColor: "#f5f5f5",
    ".MuiMenuItem-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-selected": {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
    "& .MuiListItem-button": {
      borderLeft: "2px solid #0000",
      "&:hover": {
        borderLeft: "2px solid #436EE0",
        backgroundColor: "#7B87C616",
      },
    },
  },
  menuItem: {
    minWidth: 150,
    display: "flex",
    justifyContent: "space-between",
  },
  menuItemIcon: {
    minWidth: "unset",
    marginRight: "15px",
  },
})

class DropdownButton extends Component {
  constructor(props) {
    super(props)
    this.anchorRef = React.createRef()
    this.state = {
      dropdownOpen: false,
      // options: [
      //   { id: 1, label: "Create New" },
      //   { id: 2, label: "Add Existing" },
      // ],
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleMenuItemClick = (option) => (event) => {
    this.setState({ dropdownOpen: false }, () => {
      if (_.isFunction(option.onClick)) {
        option.onClick()
      }
    })
  }

  handleMenuToggle = () => {
    const { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  handleMenuClose = (event) => {
    if (
      this.anchorRef &&
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return
    }
    this.setState({ dropdownOpen: false })
  }

  render() {
    const { dropdownOpen } = this.state
    const { options, classes } = this.props
    return (
      <>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={this.anchorRef}
          aria-label="split button"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleMenuToggle}
          >
            Add Project
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={dropdownOpen ? "split-button-menu" : undefined}
            aria-expanded={dropdownOpen ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={this.handleMenuToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          open={dropdownOpen}
          anchorEl={this.anchorRef ? this.anchorRef.current : null}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
          style={{ zIndex: 1122, minWidth: 225 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleMenuClose}>
                  <MenuList id="split-button-menu" className={classes.menu}>
                    {options.map((option) => {
                      const linkObj = {}
                      return (
                        <MenuItem
                          key={String(option.id)}
                          className={classes.menuItem}
                          onClick={this.handleMenuItemClick(option)}
                          {...linkObj}
                        >
                          {option.label}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }
}

DropdownButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
}

DropdownButton.defaultProps = {
  classes: {},
  title: "",
  options: [],
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({})

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DropdownButton
  )
)
