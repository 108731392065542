import _ from "lodash"
import moment from "moment"
import momentB from "moment-business-days"
import momentBT from "moment-business-time"
import momentTimezone from "moment-timezone"
import settings from "../config/settings"
// eslint-disable-next-line import/no-cycle
import { store } from "../redux/store"
import { privateRoutes } from "../containers/AppContainer/Routes"
import authActions from "../redux/reducers/auth/actions"

const timeZone = momentTimezone.tz.guess(true)

momentBT.locale("en", {
  holidays: ["2015-05-04"],
  // holidays: ["*-12-25"],
  workinghours: {
    0: null,
    1: ["09:00:00", "17:00:00"],
    2: ["09:00:00", "17:00:00"],
    3: ["09:00:00", "17:00:00"],
    4: ["09:00:00", "17:00:00"],
    5: ["09:00:00", "17:00:00"],
    6: null,
  },
})

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

const defaultAppTimeZone = process.env.REACT_APP_DEFAULT_TIME_ZONE
  ? process.env.REACT_APP_DEFAULT_TIME_ZONE
  : "Europe/London"
const appTimeZone = process.env.REACT_APP_TIME_ZONE
  ? process.env.REACT_APP_TIME_ZONE
  : defaultAppTimeZone

export const getErrorMessage = (error) => {
  let message = ""
  if (error && error.response && error.response.data) {
    const { errors } = error.response.data
    if (errors) {
      // eslint-disable-next-line array-callback-return
      Object.keys(errors).map((kk) => {
        const { [kk]: keyErr } = errors
        if (_.isArray(keyErr) && keyErr.length > 0) {
          // eslint-disable-next-line array-callback-return
          keyErr.map((ele) => {
            message += `${ele}\n`
          })
        }
      })
    }
  }

  // console.log("message", message)
  return message
}

export const getParentChildFormatRoutes = () => {
  const ary = []
  // eslint-disable-next-line array-callback-return
  privateRoutes.map((route) => {
    if (route.parentId === null) {
      ary.push(route)
    } else if (route.parentId > 0) {
      const parentObj = _.find(ary, (i) => i.id === route.parentId)
      if (parentObj) {
        if (!_.isArray(parentObj.children)) {
          parentObj.children = []
        }
        const exists = _.find(parentObj.children, (i) => i.id === route.id)
        if (!exists) {
          parentObj.children.push(route)
        }
      }
    }
  })
  // console.log("getParentChildFormatRoutes", ary)
  return ary
}

export const getTenantData = () => {
  let tenantData = null
  try {
    const tStr = localStorage.getItem("tenantData")
    if (tStr && tStr !== "") {
      tenantData = JSON.parse(tStr)
    } else {
      tenantData = null
    }
  } catch (error) {
    // console.log(error)
    tenantData = null
  }
  return tenantData
}

export const getPusherChannelList = () => {
  const {
    auth: { userdata },
  } = store.getState()
  const userId = userdata.auth_user_id
  // const userId = "2"
  const events = {}
  const tenantData = getTenantData()

  if (tenantData) {
    events["presence-saffron-{tenant_id}"] = `presence-saffron-${tenantData.id}`
  }

  if (userId) {
    events[
      "private-user-{userId}.notification"
    ] = `private-user-${userId}.notification`
  }
  // console.log("PUSHER-getPusherChannelList", events)
  return events
}

export const increaseUnreadCount = () => {
  const {
    auth: { unreadCount },
  } = store.getState()

  let count = 1
  if (unreadCount > 0) {
    count += unreadCount
  }
  store.dispatch(authActions.setUnreadCount(count))
}

const appendFunc = (item, state, props) => {
  console.log("appendFunc", item)
  const {
    auth: { lastNoti },
  } = store.getState()
  const isNotificationScreen =
    props.history.location.pathname === "/notifications"
  let isSame = false
  if (lastNoti) {
    isSame = _.isEqual(item, lastNoti)
  }
  // location: {pathname: "/notifications", search: "", hash: "", state: undefined, key: "tpokxk"}
  if (item && item.notification && !isSame) {
    console.log("appendFunc inside", item)
    // Hotfix - formatting
    // eslint-disable-next-line no-param-reassign
    item.status = isNotificationScreen ? "Read" : "Un-Read"
    if (item.createdAt === undefined) {
      // eslint-disable-next-line no-param-reassign
      item.createdAt = moment(new Date()).format()
    }
    if (!isNotificationScreen) {
      increaseUnreadCount()
    }
    store.dispatch(authActions.onNewNotification(item))
    return {
      // ...props,
      items: [item].concat(state.items),
    }
  }

  return {}
}

export const mapEventsToProps = () => {
  const eventsList = getPusherChannelList()
  const events = {}
  if (eventsList["private-user-{userId}.notification"]) {
    const key = `${eventsList["private-user-{userId}.notification"]}.add`
    events[key] = appendFunc
  }

  if (eventsList["presence-saffron-{tenant_id}"]) {
    const key = `${eventsList["presence-saffron-{tenant_id}"]}.add`
    events[key] = appendFunc
  }
  // console.log("mapEventsToProps called")
  return {
    mapPropsToValues: (props) => ({
      items: [], // defaultItems,
    }),
    events,
  }
}

export const getTenantHeaders = () => {
  const headers = {}
  const tenantData = getTenantData()
  if (tenantData) {
    headers["X-Saffron-Tenant"] = tenantData.id
  }

  // console.log("getTenantHeaders", headers)

  return headers
}

export const getDocumentName = (document) => {
  if (document && _.isString(document.name)) {
    const { name } = document
    let fileName = name.substring(0, name.lastIndexOf("."))
    let extension = name.substring(name.lastIndexOf(".") + 1)
    if (extension.toLowerCase() === "jpeg") {
      extension = "jpg"
    }
    if (fileName.length > settings.maxFileNameCharacters) {
      fileName = `${fileName.substring(0, settings.maxFileNameCharacters)}..`
    }
    return `${fileName}.${extension}`
  }
  return ""
}

export const fileSize = (x) => {
  let l = 0
  let n = parseInt(x, 10) || 0

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024
  }
  // include a decimal point and a tenths-place digit if presenting
  // less than ten of KB or greater units
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`
}

export const getLocalMoment = (str = "") => {
  return momentTimezone.tz(str, timeZone)
}

export const getLocalMomentObj = (str = "") => {
  return moment(getLocalMoment(str).format())
}

export const formatTime = (str = "", format = "YYYY-MM-DD HH:mm") => {
  const mZ = momentTimezone.tz(str, appTimeZone)
  return mZ.tz(timeZone).format(format)
}

export const fromNow = (str = "") => {
  const mZ = momentTimezone.tz(str, appTimeZone)
  return mZ.tz(timeZone).fromNow()
}

export const addBusinessHours = (str = "", duration) => {
  const tM = getLocalMomentObj(str)
  const isWorking = tM.isWorkingTime()
  let rTMoment = tM
  if (!isWorking) {
    rTMoment = tM.nextWorkingTime()
  }
  const rTM = rTMoment.addWorkingTime(duration, "hours")
  return rTM
}

export const addBusinessDays = (str = "", duration) => {
  const tM = getLocalMomentObj(str)
  const tMB = momentB(tM.format())
  const isBusinessDay = tMB.isBusinessDay()
  let rTMoment = tMB
  if (!isBusinessDay) {
    rTMoment = tMB.nextBusinessDay()
  }
  const rTM = rTMoment.businessAdd(duration, "days")
  return rTM
}

export const getBusinessMoment = (str) => {
  const tM = getLocalMomentObj(str)
  const tMB = momentB(tM.format())
  return tMB
}

export const getBusinessDiffDays = (day1, day2) => {
  const day1Moment = getBusinessMoment(day1)
  const day2Moment = getBusinessMoment(day2)

  const diff = day1Moment.businessDiff(day2Moment)
  return diff
}

export const getBusinessDiffHours = (day1, day2) => {
  const day1Moment = getLocalMomentObj(day1)
  const day2Moment = getLocalMomentObj(day2)

  const diff = day2Moment.workingDiff(day1Moment, "hours")
  return diff
}

export const getBDiff = (day1, day2) => {
  const hdiff = getBusinessDiffHours(day1, day2)
  if (hdiff <= 0) {
    return null
  }
  if (hdiff < 8) {
    return { diff: hdiff, type: "hours" }
  }

  const diff = getBusinessDiffDays(day1, day2)
  if (diff <= 0) {
    return null
  }
  return { diff, type: "days" }
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getNextWorkingTime = (str) => {
  const tM = getLocalMomentObj(str)
  const isWorking = tM.isWorkingTime()
  let rTMoment = tM
  if (!isWorking) {
    rTMoment = tM.nextWorkingTime()
  }
  return rTMoment
}

export const defaultFilters = {
  status: [],
  assignee: [],
  parentTask: [],
}
