import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import clsx from "clsx"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Chip from "@material-ui/core/Chip"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import { capitalizeFirstLetter } from "../../common/utils"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    width: 125,
    height: 30,
    borderRadius: 15,
  },
  label: {
    fontSize: 12,
  },
  rootDraft: {
    backgroundColor: "#e0e0e0",
  },
  labelDraft: {
    color: "#2f2f2f",
  },
  rootLive: {
    backgroundColor: "#5BB37E",
  },
  labelLive: {
    color: "#FFF",
  },
  rootOnHold: {
    backgroundColor: "#F06F6F",
  },
  labelOnHold: {
    color: "#FFF",
  },
  rootCancelled: {
    backgroundColor: "#F06F6F",
  },
  labelCancelled: {
    color: "#FFF",
  },
  rootDelete: {
    backgroundColor: "#F06F6F",
  },
  labelDelete: {
    color: "#FFF",
  },
})

class ProjectStatusChip extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { classes, status } = this.props
    const {
      task: { projectStatusList },
    } = this.props
    // console.log("projectStatusList", projectStatusList)
    if (_.isArray(projectStatusList) && _.isNumber(status)) {
      const sObj = _.find(projectStatusList, (e) => e.id === status)
      if (sObj) {
        const suffix = sObj.status_name
          ? capitalizeFirstLetter(sObj.status_name)
          : ""
        const rootClass = clsx(classes.root, classes[`root${suffix}`])
        const labelClass = clsx(classes.label, classes[`label${suffix}`])
        return (
          <Chip
            label={sObj.status_name}
            classes={{
              root: rootClass,
              label: labelClass,
            }}
          />
        )
      }
    }
    return null
  }
}

ProjectStatusChip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  status: PropTypes.oneOfType([PropTypes.any]),
  // statusList: PropTypes.arrayOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
}

ProjectStatusChip.defaultProps = {
  classes: {},
  status: null,
  // statusList: [],
  task: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProjectStatusChip)
)
