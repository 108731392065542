// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  allPageContainer: {
    // marginLeft: 20,
    // marginTop: 20,
    // height: "100%",
    width: "100%",
    height: "91vh",
    backgroundColor: "#E7E8E9",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: theme.spacing(5, 2, 2),
    // display: "flex",
    // flex: 1,
    // maxHeight: 500,
    overflow: "scroll",
    // "& .react-pdf__Page": {
    // marginBottom: 20,
    // },
  },
})

export default styles
