import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
// import { makeStyles } from '@material-ui/core/styles';
// import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
// import ListItemText from "@material-ui/core/ListItemText"
// import ListItem from "@material-ui/core/ListItem"
// import List from "@material-ui/core/List"
// import Divider from "@material-ui/core/Divider"
// import AppBar from "@material-ui/core/AppBar"
// import Toolbar from "@material-ui/core/Toolbar"
// import IconButton from "@material-ui/core/IconButton"
// import Typography from "@material-ui/core/Typography"
// import CloseIcon from "@material-ui/icons/Close"
// import Slide from "@material-ui/core/Slide"
import { withStyles } from "@material-ui/core/styles"
import { CircularProgress, Fade, Grid } from "@material-ui/core"

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  loaderView: {
    // width: "100%",
    // height: "100vh",
    width: 200,
    height: 200,
    borderRadius: 10,
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    height: 50,
    width: 50,
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={300} ref={ref} {...props} />
})

class LoaderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <Dialog
        // fullScreen
        open={open}
        // onClose={this.handleClose}
        disableBackdropClick
        TransitionComponent={Transition}
      >
        <Grid container className={classes.loaderView}>
          <CircularProgress
            m={2}
            color="secondary"
            className={classes.loader}
          />
        </Grid>
      </Dialog>
    )
  }
}

LoaderModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
}

LoaderModal.defaultProps = {
  classes: {},
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    LoaderModal
  )
)
