// import _ from "lodash"
// import Axios from "axios"
// import authActions from "../auth/actions"
import apiActions from "../api/actions"
import settings from "../../../config/settings"

const actions = {
  SET_DOCUMENT: "document/SET_DOCUMENT",

  GET_TASK_DOCUMENTS: "document/GET_TASK_DOCUMENTS",
  GET_TASK_DOCUMENTS_SUCCESS: "document/GET_TASK_DOCUMENTS_SUCCESS",
  GET_TASK_DOCUMENTS_FAIL: "document/GET_TASK_DOCUMENTS_FAIL",

  GET_PROJECT_DOCUMENTS: "document/GET_PROJECT_DOCUMENTS",
  GET_PROJECT_DOCUMENTS_SUCCESS: "document/GET_PROJECT_DOCUMENTS_SUCCESS",
  GET_PROJECT_DOCUMENTS_FAIL: "document/GET_PROJECT_DOCUMENTS_FAIL",

  setDocument: (document) => (dispatch) => {
    return dispatch({
      type: actions.SET_DOCUMENT,
      payload: document,
    })
  },

  getTaskDocumentsSuccess: (documents) => (dispatch) => {
    return dispatch({
      type: actions.GET_TASK_DOCUMENTS_SUCCESS,
      payload: documents,
    })
  },

  getTaskDocumentsFail: (message) => (dispatch) => {
    return dispatch({
      type: actions.GET_TASK_DOCUMENTS_FAIL,
      payload: message || "Unable to process Due to Network / Server Error",
    })
  },

  getTaskDocuments: () => async (dispatch, getState) => {
    const { task } = getState().task
    const { document } = getState().document
    try {
      const reqdata = {
        item_id: task ? task.id : 0,
        skip: 0,
        limit: 0,
      }
      dispatch({
        type: actions.GET_TASK_DOCUMENTS,
      })
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getDocumentByTask(task ? task.id : 0),
          "post",
          reqdata
        )
      )

      let documents = []
      if (responseJson && responseJson.success) {
        documents = responseJson.data
        if (document) {
          // eslint-disable-next-line array-callback-return
          documents.map((ele) => {
            if (ele.id === document.id) {
              dispatch(actions.setDocument(ele))
            }
          })
        }
      }
      return dispatch(actions.getTaskDocumentsSuccess(documents))
    } catch (error) {
      return dispatch(actions.getTaskDocumentsFail())
    }
  },

  getProjectDocumentsSuccess: (documents) => (dispatch) => {
    return dispatch({
      type: actions.GET_PROJECT_DOCUMENTS_SUCCESS,
      payload: documents,
    })
  },

  getProjectDocumentsFail: (message) => (dispatch) => {
    return dispatch({
      type: actions.GET_PROJECT_DOCUMENTS_FAIL,
      payload: message || "Unable to process Due to Network / Server Error",
    })
  },

  // eslint-disable-next-line no-unused-vars
  getProjectDocuments: (projectId = 0) => async (dispatch, getState) => {
    const { project } = getState().task
    const { document } = getState().document
    try {
      const reqdata = {
        project_id: project.id || projectId,
        skip: 0,
        limit: 0,
      }
      dispatch({
        type: actions.GET_PROJECT_DOCUMENTS,
      })
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getDocumentsByProject,
          "post",
          reqdata
        )
      )

      let documents = []
      if (responseJson && responseJson.success) {
        documents = responseJson.data
        if (document) {
          // eslint-disable-next-line array-callback-return
          documents.map((ele) => {
            if (ele.id === document.id) {
              dispatch(actions.setDocument(ele))
            }
          })
        }
      }
      return dispatch(actions.getProjectDocumentsSuccess(documents))
    } catch (error) {
      return dispatch(actions.getProjectDocumentsFail())
    }
  },
}

export default actions
