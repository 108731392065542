import types from "./actions"

const initialState = {
  token: "",
  userdata: {},
  screen: "",
  connected: false,
  deviceInfo: {},
  routeName: "",
  unreadCount: 0,
  notificationList: [],
  lastNoti: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_DATA:
      return {
        ...state,
        token: action.token,
        userdata: action.userdata,
      }

    case types.SET_USER:
      return {
        ...state,
        userdata: action.userdata,
      }

    case types.SET_SCREEN:
      return {
        ...state,
        screen: action.screen,
      }

    case types.SET_NETWORK_STATUS:
      return {
        ...state,
        connected: action.connected,
      }

    case types.SET_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.deviceInfo,
      }

    case types.SET_ROUTE_NAME:
      // console.log('setRouteName called', action)
      return {
        ...state,
        routeName: action.routeName,
      }

    case types.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.count,
      }

    case types.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      }

    case types.ON_NEW_NOTIFICATION:
      return {
        ...state,
        notificationList: action.payload,
        lastNoti: action.lastNoti,
      }

    default:
      return state
  }
}
