import React from "react"
import Dashboard from "./DashBoardIcon"
import Nav from "./NavIcon"
import Projects from "./ProjectIcon"
import ProjectNew from "./Project2Icon"
import Tasks from "./TaskIcon"
import Portal from "./PortalIcon"
import SettingsIcon from "./SettingsIcon"
import NotificationIcon from "./NotificationIcon"
import ProgrammeIcon from "./ProgrammeDashboardIcon"

// import ProgrammeIconSub from "./ProgrammeDashboardIconSub"
import ProgrammeDashboardIconSub from "./ProgrammeDashboardIconSub"
import ProjectIconSub from "./ProjectIconSub"

const Components = {
  dashboard: Dashboard,
  nav: Nav,
  projects: Projects,
  projectnew: ProjectNew,
  tasks: Tasks,
  portal: Portal,
  programmeDashboardIcon: ProgrammeIcon,
  programmeIcon: ProgrammeIcon,
  programmeDashboardIconSub: ProgrammeDashboardIconSub,
  projectIconSub: ProjectIconSub,
  settings: SettingsIcon,
  notifications: NotificationIcon,
}

export default (component) => {
  if (typeof Components[component] !== "undefined") {
    return React.createElement(Components[component])
  }
  return React.createElement(() => (
    <div>The component {component} is not available.</div>
  ))
}
