import _ from "lodash"
import Axios from "axios"
import authActions from "../auth/actions"
import apiActions from "../api/actions"
import documentActions from "../document/actions"
import settings from "../../../config/settings"
import { defaultFilters, getTenantHeaders } from "../../../common/utils"

const actions = {
  SET_OPEN: "task/SET_OPEN",
  SET_TASK: "task/SET_TASK",
  SET_PROJECT: "PROJECT/SET_TASK",
  SET_STATUS_LIST: "task/SET_STATUS_LIST",
  SET_PROJECT_STATUS_LIST: "task/SET_PROJECT_STATUS_LIST",
  SET_ASSIGNEE_LIST: "task/SET_ASSIGNEE_LIST",
  SET_CLIENT_ASSIGNEE_LIST: "task/SET_CLIENT_ASSIGNEE_LIST",
  SET_PARENT_TASK_LIST: "task/SET_PARENT_TASK_LIST",
  SET_TASK_LOADING: "task/SET_TASK_LOADING",
  SET_TASK_LIST: "task/SET_TASK_LIST",
  SET_TASK_ERROR: "task/SET_TASK_ERROR",

  SET_OVERDUE_TASK_LOADING: "task/SET_OVERDUE_TASK_LOADING",
  SET_OVERDUE_TASK_LIST: "task/SET_OVERDUE_TASK_LIST",
  SET_OVERDUE_TASK_ERROR: "task/SET_OVERDUE_TASK_ERROR",

  SET_FILTER_VISIBLE: "task/SET_FILTER_VISIBLE",
  SET_FILTER_DATA: "task/SET_FILTER_DATA",
  SET_DOCUMENT_TYPES: "task/SET_DOCUMENT_TYPES",
  SET_DEPENDENCY_TYPE_LIST: "task/SET_DEPENDENCY_TYPE_LIST",
  SET_MY_TASKS_LOADING: "task/SET_MY_TASKS_LOADING",
  SET_MY_TASKS_LIST: "task/SET_MY_TASKS_LIST",
  SET_MY_TASKS_ERROR: "task/SET_MY_TASKS_ERROR",
  SET_COMPANY_LIST: "task/SET_COMPANY_LIST",
  SET_PROJECT_RAG_STATUS_LIST: "task/SET_PROJECT_RAG_STATUS_LIST",
  SET_TASK_PRIORITY_LIST: "task/SET_TASK_PRIORITY_LIST",
  SET_DOCUMENT_STATUS_LIST: "task/SET_DOCUMENT_STATUS_LIST",

  setOpen: (open) => (dispatch) =>
    dispatch({
      type: actions.SET_OPEN,
      open,
    }),

  // eslint-disable-next-line consistent-return
  refreshIfRequired: (task) => (dispatch, getState) => {
    const { taskList } = getState().task
    // const newTaskList = []
    let refresh = false
    if (_.isArray(taskList) && taskList.length) {
      // eslint-disable-next-line array-callback-return
      taskList.map((ele) => {
        if (ele.id === task.id) {
          refresh = true
        } else if (_.isArray(ele.childrens) && ele.childrens.length) {
          // eslint-disable-next-line array-callback-return
          ele.childrens.map((cEle) => {
            if (cEle.id === task.id) {
              refresh = true
            }
          })
        }
      })
    }
    if (refresh) {
      return dispatch(actions.getTasks(0, true))
    }
  },

  setTask: (task, refresh = false) => (dispatch) => {
    if (task && refresh) {
      dispatch(actions.refreshIfRequired(task))
    }
    return dispatch({
      type: actions.SET_TASK,
      task,
    })
  },

  setFilterVisible: (filterVisible) => (dispatch) =>
    dispatch({
      type: actions.SET_FILTER_VISIBLE,
      filterVisible,
    }),

  setFilterData: (data = null) => (dispatch) => {
    let filterObj = defaultFilters
    if (_.isObject(data) && !_.isEmpty(data)) {
      filterObj = data
    }
    dispatch({
      type: actions.SET_FILTER_DATA,
      filterObj,
    })
    return dispatch(actions.getTasks())
  },

  setTaskLoad: (taskLoad) => (dispatch) =>
    dispatch({
      type: actions.SET_TASK_LOADING,
      taskLoad,
    }),

  setTaskError: (taskError) => (dispatch) =>
    dispatch({
      type: actions.SET_TASK_ERROR,
      taskError,
    }),

  setTaskList: (taskList) => (dispatch) =>
    dispatch({
      type: actions.SET_TASK_LIST,
      taskList,
    }),

  setOverdueTaskLoad: (overdueTaskLoad) => (dispatch) =>
    dispatch({
      type: actions.SET_OVERDUE_TASK_LOADING,
      overdueTaskLoad,
    }),

  setOverdueTaskError: (overdueTaskError) => (dispatch) =>
    dispatch({
      type: actions.SET_OVERDUE_TASK_ERROR,
      overdueTaskError,
    }),

  setOverdueTaskList: (overdueTaskList) => (dispatch) =>
    dispatch({
      type: actions.SET_OVERDUE_TASK_LIST,
      overdueTaskList,
    }),

  setMyTasksLoad: (myTasksLoad) => (dispatch) =>
    dispatch({
      type: actions.SET_MY_TASKS_LOADING,
      myTasksLoad,
    }),

  setMyTasksError: (myTasksError) => (dispatch) =>
    dispatch({
      type: actions.SET_MY_TASKS_ERROR,
      myTasksError,
    }),

  setMyTasksList: (myTasks) => (dispatch) =>
    dispatch({
      type: actions.SET_MY_TASKS_LIST,
      myTasks,
    }),

  setStatusList: (data) => (dispatch) => {
    // console.log("setStatusList", data)
    return dispatch({
      type: actions.SET_STATUS_LIST,
      statusList: data,
    })
  },

  setDocumentStatusList: (data) => (dispatch) => {
    // console.log("setDocumentStatusList", data)
    return dispatch({
      type: actions.SET_DOCUMENT_STATUS_LIST,
      documentStatusList: data,
    })
  },

  setProjectStatusList: (data) => (dispatch) => {
    // console.log("setStatusList", data)
    return dispatch({
      type: actions.SET_PROJECT_STATUS_LIST,
      projectStatusList: data,
    })
  },

  setAssigneeList: (data) => (dispatch) => {
    // console.log("setAssigneeList", data)
    return dispatch({
      type: actions.SET_ASSIGNEE_LIST,
      assigneeList: data,
    })
  },

  setParentTaskList: (data) => (dispatch) => {
    // console.log("setParentTaskList", data)
    return dispatch({
      type: actions.SET_PARENT_TASK_LIST,
      parentTaskList: data,
    })
  },

  setClientAssigneeList: (data) => (dispatch) => {
    // eslint-disable-next-line no-console
    // console.log("setClientAssigneeList", data)
    return dispatch({
      type: actions.SET_CLIENT_ASSIGNEE_LIST,
      clientAssigneeList: data,
    })
  },

  setDocumentTypesList: (data) => (dispatch) => {
    // console.log("setParentTaskList", data)
    return dispatch({
      type: actions.SET_DOCUMENT_TYPES,
      documentTypes: data,
    })
  },

  setDependencyTypesList: (data) => (dispatch) => {
    // console.log("setDependencyTypesList", data)
    return dispatch({
      type: actions.SET_DEPENDENCY_TYPE_LIST,
      dependencyTypeList: data,
    })
  },

  setCompanyList: (data) => (dispatch) => {
    // console.log("setCompanyList", data)
    return dispatch({
      type: actions.SET_COMPANY_LIST,
      companyList: data,
    })
  },

  setProjectRagStatusList: (data) => (dispatch) => {
    // console.log("setCompanyList", data)
    return dispatch({
      type: actions.SET_PROJECT_RAG_STATUS_LIST,
      ragStatusList: data,
    })
  },

  setProjectTasksPriorityList: (data) => (dispatch) => {
    // console.log("setCompanyList", data)
    return dispatch({
      type: actions.SET_TASK_PRIORITY_LIST,
      taskPriorityList: data,
    })
  },

  addSubTask: (subtask = {}) => async (dispatch, getState) => {
    // task/subtask
    const { task } = getState().task
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.taskSubtask, "post", {
          task_id: task ? task.id : "",
          subtask_id: subtask.id || "",
        })
      )
      console.log("addSubTask", responseJson)
      // if (
      //   responseJson &&
      //   responseJson.success &&
      //   _.isArray(responseJson.data)
      // ) {
      //   return dispatch(actions.setProjectTasksPriorityList(responseJson.data))
      // }
      // return dispatch(actions.setProjectTasksPriorityList([]))
    } catch (error) {
      // return dispatch(actions.setProjectTasksPriorityList([]))
    }
  },

  deleteSubTask: (subtask = {}) => async (dispatch, getState) => {
    // task/subtask
    const { task } = getState().task
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.taskSubtask, "delete", {
          task_id: task ? task.id : "",
          subtask_id: subtask.id || "",
        })
      )
      console.log("deleteSubTask", responseJson)
      // if (
      //   responseJson &&
      //   responseJson.success &&
      //   _.isArray(responseJson.data)
      // ) {
      //   return dispatch(actions.setProjectTasksPriorityList(responseJson.data))
      // }
      // return dispatch(actions.setProjectTasksPriorityList([]))
    } catch (error) {
      // return dispatch(actions.setProjectTasksPriorityList([]))
    }
  },

  // eslint-disable-next-line
  getProjectTasksPriorityList: () => async (dispatch, getState) => {
    // const { ragStatusList } = getState().task
    // console.log("getProjectTasksPriorityList")
    // if (ragStatusList.length === 0) {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProjectTasksPriorityList,
          "get",
          {}
        )
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setProjectTasksPriorityList(responseJson.data))
      }
      return dispatch(actions.setProjectTasksPriorityList([]))
    } catch (error) {
      return dispatch(actions.setProjectTasksPriorityList([]))
    }
    // }
  },

  // eslint-disable-next-line
  getProjectRagStatusList: () => async (dispatch, getState) => {
    // const { ragStatusList } = getState().task
    // console.log("getProjectRagStatusList")
    // if (ragStatusList.length === 0) {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProjectRagStatusList,
          "get",
          {}
        )
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setProjectRagStatusList(responseJson.data))
      }
      return dispatch(actions.setProjectRagStatusList([]))
    } catch (error) {
      return dispatch(actions.setProjectRagStatusList([]))
    }
    // }
  },

  getCompanyList: () => async (dispatch) => {
    try {
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.get(settings.endpoints.organizations.list, {
        headers: tenantHeaders,
      })
      const responseJson = response.data
      // console.log(responseJson)
      if (_.isArray(responseJson)) {
        const ary = []
        // eslint-disable-next-line array-callback-return
        responseJson.map((ele) => {
          let first = ""
          if (_.isString(ele.legal_name)) {
            first = ele.legal_name.charAt(0)
            const obj = {
              id: ele.id,
              legal_name: ele.legal_name,
              first: _.isString(first) ? first : "",
            }
            ary.push(obj)
          }
        })
        dispatch(actions.setCompanyList(ary))
      } else {
        dispatch(actions.setCompanyList([]))
      }
    } catch (error) {
      dispatch(actions.setCompanyList([]))
    }
  },

  // eslint-disable-next-line consistent-return
  getDependencyTypeList: () => async (dispatch, getState) => {
    const { dependencyTypeList } = getState().task
    if (dependencyTypeList.length === 0) {
      try {
        const responseJson = await dispatch(
          apiActions.apiCall(
            settings.endpoints.getDependencyTypeList,
            "get",
            {}
          )
        )
        if (
          responseJson &&
          responseJson.success &&
          _.isArray(responseJson.data)
        ) {
          return dispatch(actions.setDependencyTypesList(responseJson.data))
        }
        return dispatch(actions.setDependencyTypesList([]))
      } catch (error) {
        return dispatch(actions.setDependencyTypesList([]))
      }
    }
  },

  getProjectStatusList: () => async (dispatch) => {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProjectStatusList(1),
          "get",
          {}
        )
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setProjectStatusList(responseJson.data))
      }
      return dispatch(actions.setProjectStatusList([]))
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      return dispatch(actions.setProjectStatusList([]))
    }
  },

  // eslint-disable-next-line consistent-return
  getDocumentStatusList: () => async (dispatch, getState) => {
    const { documentStatusList } = getState().task
    if (documentStatusList.length === 0) {
      try {
        const tenantHeaders = getTenantHeaders()
        const response = await Axios.get(
          settings.endpoints.getDocumentStatusList,
          {
            headers: tenantHeaders,
          }
        )
        const responseJson = response.data
        // const responseJson = await dispatch(
        //   apiActions.apiCall(
        //     settings.endpoints.getDocumentStatusList,
        //     "get",
        //     {}
        //   )
        // )
        if (
          responseJson &&
          responseJson.success &&
          _.isArray(responseJson.data)
        ) {
          return dispatch(actions.setDocumentStatusList(responseJson.data))
        }
        return dispatch(actions.setDocumentStatusList([]))
      } catch (error) {
        return dispatch(actions.setDocumentStatusList([]))
      }
    }
  },

  // eslint-disable-next-line consistent-return
  getStatusList: () => async (dispatch, getState) => {
    const { statusList } = getState().task
    if (statusList.length === 0) {
      try {
        const responseJson = await dispatch(
          apiActions.apiCall(settings.endpoints.getAllStatus, "get", {})
        )
        if (
          responseJson &&
          responseJson.success &&
          _.isArray(responseJson.data)
        ) {
          return dispatch(actions.setStatusList(responseJson.data))
        }
        return dispatch(actions.setStatusList([]))
      } catch (error) {
        return dispatch(actions.setStatusList([]))
      }
    }
  },

  // eslint-disable-next-line consistent-return
  getAssigneeList: (myTasks = false) => async (dispatch, getState) => {
    const { assigneeList } = getState().task
    if (assigneeList.length === 0) {
      try {
        const responseJson = await dispatch(
          apiActions.apiCall(settings.endpoints.getAssigneeList(1), "get", {})
        )
        if (
          responseJson &&
          responseJson.success &&
          _.isArray(responseJson.data)
        ) {
          dispatch(actions.setAssigneeList(responseJson.data))
        } else {
          dispatch(actions.setAssigneeList([]))
        }
        if (myTasks) {
          dispatch(actions.getMyTasks())
        }
      } catch (error) {
        return dispatch(actions.setAssigneeList([]))
      }
    } else if (myTasks) {
      dispatch(actions.getMyTasks())
    }
  },

  getParentTaskList: () => async (dispatch, getState) => {
    const { project } = getState().task
    try {
      // const endpoint = settings.endpoints.getPrimaryTask(
      //   project.base_template_id
      // )
      // const responseJson = await dispatch(
      //   apiActions.apiCall(endpoint, "get", {})
      // )
      const data = {
        project_id: project.id,
        parent_id: 0,
        // primary_task_id: _.map(pTask, "id"),
        // status: _.map(status, "id"),
        // assign_to: myTask ? [userdata.name] : _.map(assignee, "name"),
        limit: 0,
        skip: 0,
      }
      const endpoint = settings.endpoints.getProjectTaskList
      const responseJson = await dispatch(
        apiActions.apiCall(endpoint, "post", data)
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setParentTaskList(responseJson.data))
      }
      return dispatch(actions.setParentTaskList([]))
    } catch (error) {
      return dispatch(actions.setParentTaskList([]))
    }
  },

  getClientAssigneeList: () => async (dispatch, getState) => {
    const { project } = getState().task
    // console.log("getClientAssigneeList", project)
    try {
      const orgId = 1
      const companyId = project.company_id
      const endpoint = settings.endpoints.getClientAssigneeList(
        orgId,
        companyId
      )
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.get(endpoint, {
        headers: tenantHeaders,
      })
      const responseJson = response.data

      // console.log("getClientAssigneeList", responseJson)
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setClientAssigneeList(responseJson.data))
      }
      return dispatch(actions.setClientAssigneeList([]))
    } catch (error) {
      // console.log("getClientAssigneeList", error)
      return dispatch(actions.setClientAssigneeList([]))
    }
  },

  getOverdueTasks: (refresh = false, critical = false, params = {}) => async (
    dispatch
  ) => {
    if (!refresh) {
      dispatch(actions.setOverdueTaskLoad(true))
    }
    dispatch(actions.setTaskError(""))
    try {
      const data = {
        // limit: 20,
        skip: 0,
        project_status: 2,
        status: [2, 3, 4],
        ...params,
      }

      if (critical) {
        data.priority_id = [4]
      } else {
        data.overdue_task = true
      }
      const endpoint = settings.endpoints.getTasksList
      const responseJson = await dispatch(
        apiActions.apiCall(endpoint, "post", data)
      )
      dispatch(actions.setOverdueTaskLoad(false))
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data) &&
        responseJson.data.length
      ) {
        dispatch(actions.setOverdueTaskList(responseJson.data))
      } else {
        dispatch(actions.setOverdueTaskList([]))
        dispatch(
          actions.setOverdueTaskError(
            `No ${critical ? "Critical" : "Overdue"} Tasks Found`
          )
        )
      }
    } catch (error) {
      // console.log(error)
      dispatch(actions.setOverdueTaskLoad(false))
      dispatch(actions.setOverdueTaskList([]))
      dispatch(actions.setOverdueTaskError("Error in loading overdue tasks"))
      dispatch(
        actions.setOverdueTaskError(
          `Error in loading ${critical ? "Critical" : "Overdue"} tasks`
        )
      )
    }
  },

  getTasks: (projectId = 0, refresh = false) => async (dispatch, getState) => {
    const { project, filterObj } = getState().task
    const { status, assignee, parentTask } = filterObj
    if (!refresh) {
      dispatch(actions.setTaskLoad(true))
    }
    dispatch(actions.setTaskError(""))
    try {
      const data = {
        project_id: projectId || project.id,
        limit: 0,
        skip: 0,
        // status: _.map(status, "id"),
        // assign_to: _.map(assignee, "name"),
        // parent_id: _.map(parentTask, "id"),
      }

      if (_.isArray(status) && status.length) {
        data.status = _.map(status, "id")
      }

      if (_.isArray(assignee) && assignee.length) {
        data.assignee = _.map(assignee, "id")
      }

      if (_.isArray(parentTask) && parentTask.length) {
        data.parent_id = _.map(parentTask, "id")
      }
      const endpoint = settings.endpoints.getProjectTaskList
      const responseJson = await dispatch(
        apiActions.apiCall(endpoint, "post", data)
      )
      dispatch(actions.setTaskLoad(false))
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data) &&
        responseJson.data.length
      ) {
        dispatch(actions.setTaskList(responseJson.data))
      } else {
        dispatch(actions.setTaskList([]))
        dispatch(actions.setTaskError("No Tasks Found"))
      }
    } catch (error) {
      // console.log(error)
      dispatch(actions.setTaskLoad(false))
      dispatch(actions.setTaskList([]))
      dispatch(actions.setTaskError("No Tasks Found"))
    }
  },

  // eslint-disable-next-line consistent-return
  getDocumentTypesList: () => async (dispatch, getState) => {
    const { documentTypes } = getState().task
    if (documentTypes.length === 0) {
      try {
        const data = {}
        const endpoint = settings.endpoints.getDocumentTypesList
        const responseJson = await dispatch(
          apiActions.apiCall(endpoint, "get", data)
        )
        if (
          responseJson &&
          responseJson.success &&
          _.isArray(responseJson.data)
        ) {
          return dispatch(actions.setDocumentTypesList(responseJson.data))
        }
        return dispatch(actions.setDocumentTypesList([]))
      } catch (error) {
        return dispatch(actions.setDocumentTypesList([]))
      }
    }
  },

  setProject: (project) => (dispatch) => {
    // console.log("setProject", project)
    const companyName =
      project && project.company_name && project.company_name !== ""
        ? `${project.company_name} : `
        : ""
    dispatch(
      authActions.setRouteName(
        project && project.project_name
          ? companyName + project.project_name
          : ""
      )
    )
    dispatch({
      type: actions.SET_PROJECT,
      project,
    })
    if (project && project.id) {
      dispatch(actions.getStatusList())
      // dispatch(actions.getProjectRagStatusList())
      dispatch(actions.getAssigneeList())
      dispatch(actions.getParentTaskList())
      dispatch(actions.getClientAssigneeList(project))
      dispatch(actions.getTasks(project && project.id ? project.id : ""))
      dispatch(
        documentActions.getProjectDocuments(
          project && project.id ? project.id : 0
        )
      )
    }
  },

  loadMyTasks: () => (dispatch) => {
    // console.log("loadMyTasks")
    return dispatch(actions.getAssigneeList(true))
  },

  getMyTasks: () => async (dispatch, getState) => {
    const { assigneeList } = getState().task
    const { userdata } = getState().auth
    // User id
    const user = _.find(assigneeList, (i) => i.id === userdata.auth_user_id)
    dispatch(actions.getStatusList())
    dispatch(actions.setMyTasksLoad(true))
    dispatch(actions.setMyTasksError(""))
    try {
      const data = {
        limit: 0,
        skip: 0,
        project_status: 2,
        status: [2, 3, 4, 6, 7],
      }
      if (user) {
        data.assignee = [user.id]
      } else {
        // console.log("_Error - no user id")
      }

      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.getTasksList, "post", data)
      )
      dispatch(actions.setMyTasksLoad(false))
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data) &&
        responseJson.data.length
      ) {
        dispatch(actions.setMyTasksList(responseJson.data))
      } else {
        dispatch(actions.setMyTasksList([]))
        dispatch(actions.setMyTasksError("No Tasks Found"))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      dispatch(actions.setMyTasksLoad(false))
      dispatch(actions.setMyTasksList([]))
      dispatch(actions.setMyTasksError("No Tasks Found"))
    }
  },
}

export default actions
