import _ from "lodash"
import settings from "../../../config/settings"
import apiActions from "../api/actions"

const actions = {
  SET_TEMPLATE_TYPES_LIST: "template/SET_TEMPLATE_TYPES_LIST",
  SET_TEMPLATE_STATUS_LIST: "template/SET_TEMPLATE_STATUS_LIST",
  SET_PROJECTS_LIST: "template/SET_PROJECTS_LIST",

  GET_TEMPLATE_LIST: "template/GET_TEMPLATE_LIST",
  GET_TEMPLATE_LIST_SUCCESS: "template/GET_TEMPLATE_LIST_SUCCESS",
  GET_TEMPLATE_LIST_FAIL: "template/GET_TEMPLATE_LIST_FAIL",

  getTemplatesSuccess: (templates) => async (dispatch) => {
    return dispatch({
      type: actions.GET_TEMPLATE_LIST_SUCCESS,
      payload: templates,
    })
  },

  getTemplatesFail: (message) => async (dispatch) => {
    return dispatch({
      type: actions.GET_TEMPLATE_LIST_FAIL,
      payload: message || "Unable to process Due to Network / Server Error",
    })
  },

  getTemplates: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_TEMPLATE_LIST,
      })
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.getTemplates, "get", {})
      )

      if (responseJson && responseJson.success) {
        // this.setState({ tLoading: false, projects: responseJson.data })
        return dispatch(actions.getTemplatesSuccess(responseJson.data))
      }
      return dispatch(actions.getTemplatesFail("No Templates Found"))
    } catch (error) {
      return dispatch(actions.getTemplatesFail())
    }
  },

  setProjectsList: (data) => (dispatch) => {
    return dispatch({
      type: actions.SET_PROJECTS_LIST,
      payload: data,
    })
  },

  // eslint-disable-next-line no-unused-vars
  getProjects: () => async (dispatch, getState) => {
    try {
      const reqdata = {
        org_id: 1,
        status: 2,
        limit: 0,
        skip: 0,
      }
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProjects,
          "post",
          reqdata
          // headers
        )
      )
      // console.log("responseJson", responseJson)
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        const ary = []
        // eslint-disable-next-line array-callback-return
        responseJson.data.map((ele) => {
          let first = ""
          if (_.isString(ele.project_name)) {
            first = ele.project_name.charAt(0)
            const obj = {
              id: ele.id,
              project_name: ele.project_name,
              first: _.isString(first) ? first : "",
            }
            ary.push(obj)
          }
        })
        return dispatch(actions.setProjectsList(ary))
      }
      return dispatch(actions.setProjectsList([]))
    } catch (error) {
      return dispatch(actions.setProjectsList([]))
    }
  },

  setTemplateTypeList: (data) => (dispatch) => {
    return dispatch({
      type: actions.SET_TEMPLATE_TYPES_LIST,
      payload: data,
    })
  },

  setTemplateStatusList: (data) => (dispatch) => {
    return dispatch({
      type: actions.SET_TEMPLATE_STATUS_LIST,
      payload: data,
    })
  },

  // eslint-disable-next-line no-unused-vars
  getTemplateTypeList: () => async (dispatch, getState) => {
    // const { typeList } = getState().template
    // console.log("getTemplateTypeList")
    // if (typeList.length === 0) {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.getTemplateTypes, "get", {})
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setTemplateTypeList(responseJson.data))
      }
      return dispatch(actions.setTemplateTypeList([]))
    } catch (error) {
      return dispatch(actions.setTemplateTypeList([]))
    }
    // }
  },

  // eslint-disable-next-line no-unused-vars
  getTemplateStatusList: () => async (dispatch, getState) => {
    // const { statusList } = getState().template
    // console.log("getTemplateStatusList")
    // if (statusList.length === 0) {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.getTemplateStatus, "get", {})
      )
      if (
        responseJson &&
        responseJson.success &&
        _.isArray(responseJson.data)
      ) {
        return dispatch(actions.setTemplateStatusList(responseJson.data))
      }
      return dispatch(actions.setTemplateStatusList([]))
    } catch (error) {
      return dispatch(actions.setTemplateStatusList([]))
    }
    // }
  },
}

export default actions
