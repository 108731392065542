import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
// import Grid from "@material-ui/core/Grid"
// import Typography from "@material-ui/core/Typography"
// import ToggleButton from "@material-ui/lab/ToggleButton"
// import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
// import settings from "../../config/settings"
import taskActions from "../../redux/reducers/task/actions"
// import AutocompleteComponent from "../Autocomplete/Autocomplete"
import Select from "../Select/Select"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({})

class TaskFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // status: [],
      // assignee: [],
      // parentTask: [],
    }
  }

  componentWillUnmount() {
    const {
      // task: { filterObj },
      taskActions: { setFilterData },
    } = this.props
    setFilterData(null)
  }

  // onChange = (key) => (event, newValue) => {
  onChange = (key) => (event) => {
    // this.setState({ [key]: newValue })
    if (event && event.target) {
      const { value: newValue } = event.target
      const {
        task: { filterObj },
        taskActions: { setFilterData },
      } = this.props
      // console.log("onChange")
      // console.log("key => ", key)
      // console.log("newValue => ", newValue)
      setFilterData({
        ...filterObj,
        [key]: newValue,
      })
    }
  }

  renderStatusFilter = () => {
    const {
      task: { statusList, filterObj, open },
    } = this.props
    const sList = _.filter(statusList, (i) => i.id !== 1)
    return (
      <Select
        isShort={open}
        id="status-filer"
        options={sList}
        getOptionLabel={(option) => option.status_desc}
        defaultValue={[]}
        multiple
        value={_.isArray(filterObj.status) ? filterObj.status : []}
        label="Status"
        placeholder="Status"
        onChange={this.onChange("status")}
      />
    )
  }

  renderAssigneeFilter = () => {
    // const { classes } = this.props
    const {
      task: { assigneeList, filterObj, open },
    } = this.props
    // const { assignee } = this.state
    return (
      <Select
        isShort={open}
        id="assignee-filter"
        options={assigneeList}
        getOptionLabel={(option) => option.name}
        defaultValue={[]}
        // value={assignee}
        multiple
        value={_.isArray(filterObj.assignee) ? filterObj.assignee : []}
        label="Assignee"
        placeholder="Assignee"
        onChange={this.onChange("assignee")}
      />
    )
  }

  renderParentTaskFilter = () => {
    // const { classes } = this.props
    const {
      task: { parentTaskList, filterObj },
    } = this.props
    // const { parentTask } = this.state
    return (
      <Select
        id="parent-task-filter"
        options={parentTaskList}
        getOptionLabel={(option) => option.task_name}
        defaultValue={[]}
        multiple
        // value={parentTask}
        value={_.isArray(filterObj.parentTask) ? filterObj.parentTask : []}
        label="Parent Task"
        placeholder="Parent Task"
        onChange={this.onChange("parentTask")}
      />
    )
  }

  render() {
    return (
      <Box p={0} style={{ marginTop: -10 }}>
        {this.renderStatusFilter()}
        {this.renderAssigneeFilter()}
      </Box>
    )
  }
}

TaskFilters.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
  // apiActions: PropTypes.objectOf(PropTypes.any),
}

TaskFilters.defaultProps = {
  // classes: {},
  // auth: {},
  task: {},
  taskActions: {},
  // authActions: {},
  // apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TaskFilters)
)
