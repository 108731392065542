import React from "react"
import { pdfjs } from "react-pdf"

import withStyles from "@material-ui/styles/withStyles"
import PropTypes from "prop-types"
import AllPagesPDFViewer from "./Pdf/AllPages"
import styles from "./DocumentViewer.styles"

// import samplePDF from "./test.pdf"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

function DocumentViewer(props) {
  const { classes, document, version } = props
  let pdf = "" // samplePDF

  const checkObj = version || document

  if (checkObj && checkObj.path && checkObj.path.includes("pdf")) {
    // const newpath = checkObj.path.replace(
    //   "amazonaws.com/dev",
    //   "amazonaws.com/dev/"
    // )
    pdf = {
      url: checkObj.path,
      //  withCredentials: true,
      httpHeaders: {
        "Access-Control-Allow-Origin": "*",
        Origin: `${process.env.REACT_APP_BASE_URI}`,
        "Access-Control-Request-Method": "GET",
      },
    }
  }

  return (
    <div className={classes.allPageContainer}>
      <AllPagesPDFViewer pdf={pdf} />
    </div>
  )

  /*
     return (
       <Document file={samplePDF}>
         <Page pageNumber={1} />
       </Document>
     ) */
}

DocumentViewer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
}

DocumentViewer.defaultProps = {
  classes: {},
}
export default withStyles(styles)(DocumentViewer)
