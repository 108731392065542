import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
// import NavIcon from "../NavIcon/NavIcon"
import Can from "../Can"

class TitleSection extends Component {
  constructor(props) {
    super(props)
    const {
      task: { task },
    } = props
    this.state = {
      taskName: task && task.task_name ? task.task_name : "",
      errors: {},
    }

    this.taskName = React.createRef()
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.onChange) {
  //     if ( ! _.isEqual(prevState.taskName, this.state.taskName)) {
  //       this.props.onChange(this.state);
  //     }
  //   }
  // }

  componentDidMount() {
    this.getTaskInfo()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (nextTask.open && !_.isEqual(task.task, nextTask.task)) {
      if (nextTask.task) {
        this.getTaskInfo(nextTask.task)
      }
    }
  }

  getTaskInfo = (task) => {
    if (!task) {
      return
    }
    const { task_name: taskName } = task
    this.setState({
      taskName: taskName || "",
    })
  }

  validate = () => {
    const { taskName } = this.state
    const errors = {}
    let valid = true
    if (taskName.trim() === "") {
      valid = false
      errors.taskName = "Please enter task name"
    }

    if (valid) {
      return { task_name: taskName }
    }
    this.setState({ errors })
    return null
  }

  renderError = (key) => {
    const { errors } = this.state
    return (
      <Typography color="error" style={{ fontSize: 12 }}>
        {errors[key] || ""}
      </Typography>
    )
  }

  renderTitle = () => {
    const {
      classes,
      task: { project },
      auth: { userdata },
    } = this.props
    const { taskName } = this.state
    // eslint-disable-next-line react/prop-types
    const userRoles = userdata["https://saffronios.com//roles"]

    return (
      <Box
        px={2}
        pb={1}
        pt={2}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.borderBottom}
      >
        <Box p={0} width={40}>
          {/* <NavIcon /> */}
        </Box>
        <Box p={0} display="flex" flex={1} flexDirection="column">
          <TextField
            ref={this.taskName}
            id="task-name-input"
            label="Task Name"
            variant="filled"
            placeholder="Task Name"
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: { fontSize: 15, width: 445 },
              readOnly: Can({
                roleLevel: 3,
                role: userRoles[0],
                yes: () => false,
                no: () => true,
              }),
            }}
            value={taskName}
            onChange={(e) => {
              this.setState({ taskName: e.target.value, errors: {} })
            }}
          />
          {this.renderError("taskName")}
          <Typography
            variant="body2"
            style={{
              fontSize: 14,
              marginTop: 8,
              marginLeft: 10,
              marginBottom: 0,
            }}
            color="textSecondary"
          >
            {project.project_name || ""}
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    return <Box p={0}>{this.renderTitle()}</Box>
  }
}

TitleSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
}

TitleSection.defaultProps = {
  classes: {},
  task: {},
  auth: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TitleSection
  )
)
