import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import TabPanel from "../TabPanel/TabPanel"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
import Can from "../Can"

class DescriptionSection extends Component {
  constructor(props) {
    super(props)
    const {
      task: { task },
    } = props
    this.state = {
      taskDesc: task && task.task_desc ? task.task_desc : "",
      taskRes: task && task.resolution ? task.resolution : "",
      errors: {},
      value: 0,
      // projectId: 0,
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.onChange) {
  //     if ( ! _.isEqual(prevState.taskDesc, this.state.taskDesc)) {
  //       this.props.onChange(this.state);
  //     }
  //   }
  // }

  componentDidMount() {
    this.getTaskInfo()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { task } = this.props
    const { task: nextTask } = nextProps
    if (nextTask.open && !_.isEqual(task.task, nextTask.task)) {
      if (nextTask.task) {
        this.getTaskInfo(nextTask.task)
      }
    }
  }

  a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  handleChange = (e, index) => {
    this.setState({ value: index })
  }

  getTaskInfo = (task) => {
    if (!task) {
      return
    }
    // console.log(task)
    const { task_desc: taskDesc, resolution: taskRes } = task
    this.setState({
      taskDesc: taskDesc || "",
      taskRes: taskRes || "",
    })
  }

  validate = () => {
    const { taskDesc, taskRes } = this.state
    const errors = {}
    let valid = true

    const isDraft = this.isDraft()
    if (taskDesc.trim() === "") {
      valid = false
      errors.taskDesc = "Please enter task description"
    }

    // if (!isDraft && taskRes.trim() === "") {
    //  valid = false
    //  errors.taskRes = "Please enter task resolution"
    // }

    if (valid) {
      const retObj = { task_desc: taskDesc }
      if (!isDraft) {
        retObj.resolution = taskRes
      }
      return retObj
    }
    this.setState({ errors })
    if (!isDraft && !errors.taskDesc && errors.taskRes) {
      this.setState({ value: 1 })
    }
    return null
  }

  renderError = (key) => {
    const { errors } = this.state
    return (
      <Typography color="error" style={{ fontSize: 12 }}>
        {errors[key] || ""}
      </Typography>
    )
  }

  isDraft = () => {
    const {
      task: { task },
    } = this.props
    // const { assignee } = this.state
    return task && task.isDraft
  }

  onChangeInput = (key) => (e) => {
    const { errors } = this.state
    this.setState({
      [key]: e.target.value,
      errors: { ...errors, [key]: undefined },
    })
  }

  renderResolutionInput = () => {
    const {
      classes,
      auth: { userdata },
    } = this.props
    const userRoles = userdata["https://saffronios.com//roles"]
    const { taskRes } = this.state
    return (
      <Box
        whiteSpace="normal"
        // style={{ padding: "10px 0px" }}
        className={classes.borderBottom}
      >
        <TextField
          multiline
          id="task-name-input"
          label={false}
          variant="filled"
          placeholder="Enter Task Resolution"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classes.multiLineInput,
            readOnly: Can({
              roleLevel: 3,
              role: userRoles[0],
              yes: () => false,
              no: () => true,
            }),
          }}
          value={taskRes}
          onChange={this.onChangeInput("taskRes")}
        />
        {this.renderError("taskRes")}
      </Box>
    )
  }

  renderDescriptionInput = () => {
    const {
      classes,
      auth: { userdata },
    } = this.props

    const userRoles = userdata["https://saffronios.com//roles"]

    const { taskDesc } = this.state
    return (
      <Box
        whiteSpace="normal"
        // style={{ padding: "10px 0px" }}
        className={classes.borderBottom}
      >
        <TextField
          multiline
          id="task-name-input"
          label={false}
          variant="filled"
          placeholder="Enter Task Description"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classes.multiLineInput,
            readOnly: Can({
              roleLevel: 3,
              role: userRoles[0],
              yes: () => false,
              no: () => true,
            }),
          }}
          value={taskDesc}
          onChange={this.onChangeInput("taskDesc")}
        />
        {this.renderError("taskDesc")}
      </Box>
    )
  }

  renderDescription = () => {
    const { classes } = this.props
    return (
      <>
        <Box whiteSpace="normal">
          <Typography
            variant="body2"
            style={{
              fontSize: 14,
              marginTop: 15,
              // marginLeft: 30,
              marginBottom: 5,
            }}
            color="textSecondary"
          >
            Description:
          </Typography>
          <Divider classname={classes.mDivide} style={{ marginBottom: 10 }} />
        </Box>
        {this.renderDescriptionInput()}
      </>
    )
  }

  renderTabs = () => {
    const { value } = this.state
    const { classes, theme } = this.props
    return (
      <Box style={{ padding: "8px 16px 8px 16px" }}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          // indicatorColor="#2F2F2FFF"
          // textColor="#2F2F2F80"
          variant="standard"
          aria-label="full width tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            className={classes.tabButton}
            label="Description"
            {...this.a11yProps(0)}
          />
          <Tab
            className={classes.tabButton}
            label="Resolution"
            {...this.a11yProps(1)}
          />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {this.renderDescriptionInput()}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {this.renderResolutionInput()}
          </TabPanel>
        </SwipeableViews>
      </Box>
    )
  }

  render() {
    const isDraft = this.isDraft()
    if (isDraft) {
      return (
        <Box style={{ padding: "10px 27px 27px 30px" }}>
          {this.renderDescription()}
        </Box>
      )
    }
    return this.renderTabs()
  }
}

DescriptionSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  theme: PropTypes.objectOf(PropTypes.any),
}

DescriptionSection.defaultProps = {
  classes: {},
  task: {},
  theme: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DescriptionSection
  )
)
