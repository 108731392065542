const styles = (theme) => ({
  input: {
    height: 50,
  },
  // adornedEnd: {
  //   // backgroundColor: "#F008",
  // },
  // adornedStart: {
  //   // backgroundColor: "#0F08",
  // },
  colorSecondary: {},
  disabled: {},
  error: {},
  focused: {},
  formControl: {},
  fullWidth: {},
  // inputAdornedEnd: {
  //   // backgroundColor: "#0F05",
  // },
  // inputAdornedStart: {
  //   // backgroundColor: "#F802",
  // },
  // inputHiddenLabel: {},
  inputMarginDense: {},
  inputMultiline: {},
  inputTypeSearch: {},
  marginDense: {},
  multiline: {},
  // notchedOutline: {},
  root: {
    height: 50,
    //  border: "1px solid #436EE0",
    borderRadius: 4,
    padding: "5px 0px 5px 10px",
    // margin: "18px 0 10px",
    //  color: "#436EE0",
    fontSize: 16,
  },
  underline: {},
  // popover: {
  //   top: 10,
  // },
})

export default styles
