// eslint-disable-next-line no-unused-vars
import React from "react"
import _ from "lodash"
// import I18n from '../../../common/i18n';
import actions from "./actions"
import settings from "../../../config/settings"
import apiActions from "../../reducers/api/actions"
import authActions from "../../reducers/auth/actions"
import { apiCall } from "../../../common/apiHelper"

// eslint-disable-next-line no-unused-vars
export default function ({ dispatch, getState }) {
  // eslint-disable-next-line consistent-return
  return (next) => async (action) => {
    switch (action.type) {
      case actions.GET_API_JWT:
        try {
          const data = {
            identifier: "vbp-api@vbp.com",
            password: "Admin@123!",
          }
          const response = await apiCall(
            settings.endpoints.getApiJwt,
            "post",
            data
          )
          if (response.jwt) {
            return dispatch({
              type: apiActions.SET_JWT_TOKEN,
              token: response.jwt,
            })
          }
          return next(action)
        } catch (error) {
          return next(action)
        }

      case actions.API_CALL:
        /* eslint-disable no-case-declarations */
        const { token } = getState().api
        const { deviceInfo, token: userToken, currency } = getState().auth
        const { endpoint, method, data, onSuceess, onFail } = action
        const reqData = _.isObject(data) ? data : {}
        const nData = {
          ...reqData,
          device_info: deviceInfo,
          token: userToken,
          currency,
        }
        /* eslint-enable no-case-declarations */
        try {
          const headers = {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          }
          const response = await apiCall(endpoint, method, nData, headers)
          // if (response && _.isString(response.message)) {
          //   if (response.message.includes('Errors.server_error')) {
          //     response.message = I18n.t(response.message);
          //   }
          // }
          if (response.status) {
            // console.log(onSuceess);
            if (_.isFunction(onSuceess)) {
              onSuceess(response)
            }
          } else if (
            response.logout &&
            endpoint !== settings.endpoints.logout
          ) {
            // console.log('logout logic');
            dispatch(authActions.logoutAsync())
          } else if (_.isFunction(onFail)) {
            onFail(response)
          } else {
            onFail({ message: "Network or server Error" })
            // onFail({ message: I18n.t('Extra.network_or_server_error') });
          }
          return next(action)
        } catch (error) {
          if (_.isFunction(onFail)) {
            onFail({ message: "Network or server Error" })
          }
          return next(action)
        }
      default:
        return next(action)
    }
  }
}
