const drawerWidth = 800

const styles = (theme) => ({
  tabButton: {
    padding: "0px 10px 0px 0px",
  },
  indicator: {
    backgroundColor: "#000",
  },
  logo: {
    width: 138,
    height: 40,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  mainContainerBox: {
    padding: 20,
    backgroundColor: "#F9FAFC",
    // margin: 33,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 0,
    paddingRight: 0,
  },
  mainContainerInternal: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: "0px 0px 6px #00000029",
    height: "88vh",
  },
  drawerPaper: {
    // paddingTop: "64px",
    // position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "#F9FAFC",
    width: "calc(100% - 200px)",
    // zIndex: 9999,
    zIndex: 1201,
    // width: '50%',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  dW: {
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(7),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      // width: theme.spacing(9),
      width: 0,
    },
  },
  userAvatar: {
    backgroundColor: "#0000",
    border: "solid 1px #278BED",
    color: "#278BED",
  },
  userName: {},
  userRole: {},
  m0: {
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  p0: {
    padding: 0,
  },
  borderBottom: {
    borderBottom: "1px solid #70707032",
  },
  borderLeft: {
    borderLeft: "1px solid #70707032",
  },
  loadingView: {
    minHeight: 500,
  },
  mainView: {
    minHeight: "100vh",
  },
  multilineInput: {
    height: 200,
    fontSize: 14,
  },
  smalltext: {
    fontSize: 10,
    textTransform: "capitalize",
  },
  dropZone: {
    // minHeight: 181,
    margin: "25px 0 0 0",
    backgroundColor: "#FFFFFF",
    border: "2px dashed #B7B7B7",
    borderRadius: "4px",
    "& .MuiDropzoneArea-textContainer": {
      marginBottom: 0,
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: "30px 32px 25px 32px",
    },
  },
  dropZoneText: {
    fontSize: 15,
    color: "#2F2F2F80",
    fontWeight: "normal",
  },
  inline: {
    marginBottom: "2px",
    fontSize: "16px",
    display: "inline",
    whiteSpace: "normal",
    color: "#909090",
  },
  block: {
    marginBottom: "2px",
    fontSize: "16px",
    // display: "block",
    display: "inline",
    whiteSpace: "normal",
    color: "#909090",
  },
  listComment: {
    width: "100%",
    // maxWidth: "36ch",
    // backgroundColor: theme.palette.background.paper,
  },
  overflow: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  loadMoreText: {
    margin: "10px 0",
    textAlign: "center",
  },
  actionPanelHeader: {
    color: "#707070",
    fontSize: 12,
  },
  actionButton: {
    width: "140px",
    height: "45",
  },
  saveButton: {
    textTransform: "none",
    backgroundColor: "#6DA952",
    "& .MuiButton-label": {
      color: "#fff",
    },
  },
  completeButton: {
    textTransform: "none",
  },
  deleteButton: {
    textTransform: "none",
  },
  cancelButton: {
    border: "none",
    textTransform: "none",
    color: "#707070",
    fontWeight: "normal",
    paddingTop: 0,
  },
  dateLabel: {
    color: "#747474",
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  appBar: {
    borderBottom: "1px solid #70707032",
    width: "auto",
    margin: "0 20px",
  },
  root: {
    display: "flex",
    overflow: "auto",
    height: "100%",
    "& .MuiFilledInput-root": {
      borderRadius: "4px",
      height: 45,
      backgroundColor: "#f4f4f4",
    },
    "& .MuiButton-root": {
      height: 45,
      color: "#707070",
      textTransform: "none",
    },
    "& .MuiSelect-root": {
      height: 45,
      color: "#707070",
    },
    "& .MuiInput-root": {
      height: 45,
      margin: "5px 0",
    },
    "& .MuiToggleButtonGroup-root": {
      marginLeft: 20,
    },
    "& .MuiToggleButton-root": {
      border: 0,
      height: "32px",
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: "15px",
      marginRight: "15px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#fff",
      borderBottom: "1px solid",
    },
    "& .MuiTextField-root": {
      // width: '435px'
    },
    "& .MuiButton-containedPrimary": {
      color: "#fff",
    },
    "& .MuiFilledInput-multiline": {
      height: 200,
      display: "flex",
      flexDirection: "column",
      borderRadius: "4px",
      padding: "15px 20px 20px 20px",
      fontSize: "14px",
      // width: '95%'
    },
    "& .MuiInputLabel-filled": {
      "& .Mui-focused": {
        transform: "translate(12px, 15px) scale(1)",
      },
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(12px, 5px) scale(0.75)",
    },
    "& .navIcon": {
      marginTop: "5px",
    },
    "& .MuiContainer-root": {
      padding: 0,
      overflow: "auto",
      // height: "100vh",
      height: "88vh",
      paddingBottom: "50px",
    },
    // Activities Tab list
    "& .MuiList-padding": {
      padding: "8px 40px 8px 40px",
    },
    "& .MuiListItemText-multiline": {
      marginLeft: 10,
      marginTop: 10,
    },
    "& .MuiListItemSecondaryAction-root": {
      top: 39,
      "& .MuiTypography-body1": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    "& .MuiListItem-root": {
      paddingBottom: 15,
      paddingTop: 10,
    },
  },
  activitiesLoadMoreContainer: {
    height: 45,
    marginBottom: 10,
    marginTop: 10,
  },
  mDivide: {
    marginBottom: "10px",
    paddingBottom: "10px",
  },
  addCommentInput: {
    height: "150px !important",
  },
  addComment: {
    height: "unset !important",
    minHeight: "174px !important",
    borderRadius: 6,
    padding: "12px 9px",
    border: "1px solid #70707064",
  },
  addCommentButton: {
    // width: "130px !important",
    // height: "36px !important",
    width: "50px !important",
    height: "50px !important",
    padding: "0 0 0 4px",
    backgroundColor: "#436EE0",
    // borderRadius: "6px",
    borderRadius: "25px",
    position: "absolute",
    bottom: "15px",
    right: "15px",
    color: "#FFF !important",
    fontSize: "16px !important",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#436EE080",
    },
  },
  userAvatar2: {
    width: "37px",
    height: "37px",
    backgroundColor: "#7B87C6",
    boxShadow: "0px 3px 6px #00000029",
    margin: "5px 20px 5px 15px",
    color: "#FFF",
    fontSize: 14,
  },
  CommentTextMultiline: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },
  CommentTextPrimaryGrid: {
    marginBottom: 2,
    marginTop: 10,
    display: "flex",
  },
  CommentTextPrimary: {
    color: "#2f2f2f",
    fontSize: 18,
    // marginBottom: 2,
    // marginTop: 10,
  },
  CommentTextRoot: {},
  CommentTextSecondary: {
    fontSize: 14,
    color: "#909090",
  },
  CommentListItemAvatar: {
    marginTop: 0,
    minWidth: "unset",
  },
  CommentUserAvatar: {
    width: "37px",
    height: "37px",
    backgroundColor: "#7B87C6",
    boxShadow: "0px 3px 6px #00000029",
    margin: "7px 20px 5px 0px",
    color: "#FFF",
    fontSize: 14,
  },
  CommentBadge: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: "#D9D9D9",
    marginBottom: 7,
    color: "#FFF !important",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  CommentListItemSecondary: {
    flexDirection: "column",
    alignItems: "flex-end",
    display: "flex",
    marginTop: 18,
  },
  documentListTable: {
    "& .MuiTableCell-paddingNone": {
      paddingLeft: 30,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
  filledSelect: {
    "& .MuiInput-root": {
      borderColor: "#f4f4f4",
      background: "#f4f4f4",
    },
  },
  uploadButton: {
    borderColor: "#B7B7B7",
  },
  uploadOptionButton: {
    width: "80%",
    borderColor: "#B7B7B7",
    marginTop: 20,
    marginBottom: 20,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  flex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  documentViewIconButton: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(0, 1),
    // backgroundColor: "#F00",
  },
  blueIconButton: {
    backgroundColor: "#436EE0",
    "&:hover": {
      backgroundColor: "#436EE080",
    },
  },
  redIconButton: {
    backgroundColor: "#FF3C00",
    "&:hover": {
      backgroundColor: "#FF3C0080",
    },
  },
  documentViewIcons: {
    color: "white",
    fontSize: 24,
  },
  documentViewText: {
    color: "white",
    fontSize: 16,
    width: "100%",
  },
  Inline: {
    display: "inline",
  },
  documentViewSubText: {
    color: "white",
    fontSize: 12,
  },
  documentViewHeaderPart: {
    display: "flex",
  },
  documentViewHeader: {
    height: "64px",
    backgroundColor: "rgba(112, 112, 112, 0.5)",
    // backgroundColor: "#707070",
    display: "flex",
    padding: theme.spacing(0, 3),
  },
  docViewContainer: {
    padding: theme.spacing(2),
    // height: "100%",
    // width: "100%",
    // display: "flex",
    // flex: 1,
    // overflow: "scroll",
    // height: 600,
    height: "100vh",
    backgroundColor: "#E7E8E9",
  },
  blackText: {
    color: "#2F2F2F",
  },
  userSuggestionListItem: {
    padding: "5px !important",
  },
  userSuggestionListAvatar: {
    minWidth: 40,
  },
  userSuggestionAvatar: {
    // border: "2px solid green",
    backgroundColor: "green",
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
  },
  userSuggestionAvatarExternal: {
    // border: "2px solid blue",
    backgroundColor: "blue",
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
  },
  userSuggestionListItemText: {
    fontSize: 16,
  },
})

export default styles
