import React, { useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
// import { Route, Switch } from "react-router-dom"
import PropTypes from "prop-types"
import _ from "lodash"
import withStyles from "@material-ui/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import clsx from "clsx"
import withPusher from "react-pusher-hoc"
// import { useAuth0 } from "@auth0/auth0-react"
// import { useAuth0 } from "../../common/react-auth0-spa"
import { PUBLIC_ROUTE } from "../AppContainer/route.constants"
import Header from "../../components/Header/Header"
import DrawerC from "../../components/Drawer/Drawer"
// import Loader from "../../components/Loader/Loader"
// import { privateRoutes } from "../AppContainer/Routes"
// import Loader from "../../components/Loader/Loader"
// import { privateRoutes } from "../AppContainer/Routes"
import SidePanel from "../../components/SidePanel/SidePanel"
import SimpleBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import authActions from "../../redux/reducers/auth/actions"
import documentActions from "../../redux/reducers/document/actions"
import appConfigActions from "../../redux/reducers/appConfig/actions"
import styles from "./Dashboard.styles"
import Handler from "../Notifications/Handler"
import DocumentPanel from "../../components/DocumentPanel/DocumentPanel"
// import AccessDenied from "../AccessDenied/AccessDenied"
import { mapEventsToProps } from "../../common/utils"
// import ErrorBoundary from "../AppContainer/ErrorBoundry"

const mapEvents = mapEventsToProps()

// eslint-disable-next-line no-unused-vars
const getCrumbs = ({ path, name }, props, privateRoutes) => {
  // console.log("getCrumbs", props.match)
  const crumbs = privateRoutes
    // Get all routes that contain the current one.
    // eslint-disable-next-line no-shadow
    .filter(({ path }) => {
      // console.log("crumbs path", path)
      if (
        props.match.path.includes("project") &&
        path === "dashboard/projects"
      ) {
        return true
      }

      if (
        props.match.path.includes("programme") &&
        path === "manage/programmes"
      ) {
        return true
      }
      return props.match.path.includes(path)
    })
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    // eslint-disable-next-line no-shadow
    .map(({ path, ...rest }) => {
      // console.log(path)
      return {
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce(
              // eslint-disable-next-line no-shadow
              (path, param) =>
                path.replace(`:${param}`, props.match.params[param]),
              path
            )
          : path,
        ...rest,
      }
    })
  // console.log(`Generated crumbs for ${props.match.path}`)
  // crumbs.map(({ name, path }) => console.log({ name, path }))
  /* old code */
  // return crumbs
  /* re-factor code to remove duplicates */
  // console.log("return crumbs", crumbs)
  return _.uniqBy(crumbs, (o) => o.name)
  // let crumbs2 = []
  // eslint-disable-next-line array-callback-return
  // crumbs.map((o, indexx) => {
  //   if (o.path === "dashboard" || o.path === "") {
  //     // return o.name
  //     crumbs2.push(o)
  //   }
  // })
  // return crumbs2
}

// const renderRoute = (route, match, privateRoutes) => (props) => {
//   const crumbs = getCrumbs(route, props, privateRoutes)
//   // console.log("crumbs", crumbs)
//   if (route.component) {
//     return (
//       <div>
//         <SimpleBreadcrumbs crumbs={crumbs} match={match} />
//         <route.component {...props} />
//       </div>
//     )
//   }
//   return null
// }

// const renderRoute = (route, match) => (props) => {
//   const crumbs = getCrumbs(route, props)
//   // console.log("crumbs", crumbs)
//   if (route.component) {
//     return (
//       <div>
//         <SimpleBreadcrumbs crumbs={crumbs} match={match} />
//         <route.component {...props} />
//       </div>
//     )
//   }
//   return null
// }

const Dashboard = (props) => {
  const { classes, children, route, routeProps } = props
  const { history, match } = routeProps
  const {
    panelOpen,
    document: { document: documentOpen },
    documentActions: { setDocument },
    appConfigActions: { getPrivateRoutes },
  } = props
  const privateRoutes = getPrivateRoutes()
  const [open, setOpen] = useState(true)
  // const [openR, setOpenR] = useState(false)
  // const theme = useTheme()

  // eslint-disable-next-line no-unused-vars
  // const { isAuthenticated, logout, user, loading, isLoading } = useAuth0()

  // if (isLoading || !user) {
  //   return <Loader />
  // }
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  // const handleDrawerCloseRight = () => {
  //   setOpenR(false)
  // }

  const handleLogout = () => {
    const {
      authActions: { setUserData },
    } = props
    setUserData("", "")
    // console.log(history);
    history.push(PUBLIC_ROUTE.SIGN_IN)
  }

  const navigate = (route1) => () => {
    const { url } = match
    setDocument(null)
    if (route1 === "") {
      history.push(`${url}`)
    } else {
      // const lastLetter = url.charAt(url.length - 1)
      // let fUrl = window.location.origin

      // // console.log(`${url}${lastLetter === "/" ? "" : "/"}${route}`)
      // history.push(`${fUrl}${lastLetter === "/" ? "" : "/"}${route1}`)

      // let furl = url
      // if (url && url.includes(path)) {
      //   furl = url.replace(path, "")
      // }

      // console.log(history)
      // console.log(match)
      // console.log(window.location)

      history.replace(`/${route1}`)
    }
  }

  const setMessage = () => {}
  const onSuccessDocPanel = () => {
    const {
      documentActions: { getProjectDocuments },
    } = props
    getProjectDocuments()
  }

  const renderDocumentDrawer = () => {
    if (panelOpen) {
      return null
    }
    const dO = _.isObject(documentOpen) && !_.isEmpty(documentOpen)
    return (
      <Drawer
        key="document_drawer"
        className={classes.drawerDC}
        variant="persistent"
        anchor="right"
        open={dO}
        classes={{
          paper: clsx(classes.drawerPaper, !dO && classes.drawerPaperCloseDC),
        }}
      >
        <DocumentPanel
          history={history}
          match={match}
          setMessage={setMessage}
          onSuccess={onSuccessDocPanel}
        />
      </Drawer>
    )
  }

  const renderDrawer = () => {
    return (
      <Drawer
        key="side_panel_drawer"
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={panelOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <SidePanel key="side_panel" history={history} match={match} />
      </Drawer>
    )
  }

  // const renderOldApp = () => {
  //   return (
  //     <Suspense fallback={<Loader />}>
  //       <Switch
  //       // atEnter={{ opacity: 0 }}
  //       // atLeave={{ opacity: 0 }}
  //       // atActive={{ opacity: 1 }}
  //       // className="switch-wrapper"
  //       >
  //         {privateRoutes.map((route, idx) => {
  //           return (
  //             <Route
  //               exact={route.exact}
  //               // eslint-disable-next-line react/no-array-index-key
  //               key={`route_${idx}`}
  //               // path={`${match.url}/${route.path}`}
  //               path={`/${route.path}`}
  //               render={renderRoute(route, match)}
  //             />
  //           )
  //         })}
  //         <Route path="/denied" component={AccessDenied}>
  //           {/* <Denied /> */}
  //         </Route>
  //         {/* <Redirect
  //             exact
  //             from={`${match.url}/admin/:projectId`}
  //             to={`${match.url}/project/:projectId`}
  //           /> */}
  //       </Switch>
  //     </Suspense>
  //   )
  // }

  const crumbs = getCrumbs(route, props, privateRoutes)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        history={history}
        match={match}
        handleDrawerOpen={handleDrawerOpen}
        handleLogout={handleLogout}
        open={open}
      />
      <DrawerC
        open={open}
        history={history}
        match={match}
        handleDrawerClose={handleDrawerClose}
        navigate={navigate}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: panelOpen,
        })}
      >
        <div className={classes.appBarSpacer} />
        {/* {this.renderOldApp()} */}
        <div>
          <SimpleBreadcrumbs crumbs={crumbs} match={match} />
          {children}
        </div>
        {/* <Footer /> */}
      </main>
      {/* <SidePanel history={history} match={match} /> */}
      {renderDrawer()}
      {renderDocumentDrawer()}
      <Handler />
    </div>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  authActions: PropTypes.objectOf(PropTypes.any),
  documentActions: PropTypes.objectOf(PropTypes.any),
  appConfigActions: PropTypes.objectOf(PropTypes.any),
  panelOpen: PropTypes.bool.isRequired,
  routeProps: PropTypes.objectOf(PropTypes.any),
  route: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
    PropTypes.any,
  ]),
}

Dashboard.defaultProps = {
  classes: {},
  history: {},
  match: {},
  authActions: {},
  document: {},
  documentActions: {},
  appConfigActions: {},
  routeProps: {},
  route: {},
  children: [],
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  panelOpen: state.task.open,
  document: state.document,
  appConfig: state.appConfig,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    appConfigActions: bindActionCreators(appConfigActions, dispatch),
  }
}

export default compose(
  withStyles(styles),
  withPusher(mapEvents),
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard)
