import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import clsx from "clsx"
import { Chip, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const capitalize = (s) => {
  if (!_.isString(s)) return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    width: 125,
    height: 30,
    borderRadius: 15,
  },
  label: {
    fontSize: 12,
  },
  rootDraft: {
    backgroundColor: "#e0e0e0",
  },
  labelDraft: {
    color: "#2f2f2f",
  },
  rootCompleted: {
    backgroundColor: "#5BB37E",
  },
  labelCompleted: {
    color: "#FFF",
  },
  rootSignedOff: {
    backgroundColor: "#436EE0",
  },
  labelSignedOff: {
    color: "#FFF",
  },
  rootRejected: {
    backgroundColor: "#F06F6F",
  },
  labelRejected: {
    color: "#FFF",
  },
})

class DocumentStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, status, documentStatusList, text, className } = this.props
    // console.log("DocumentStatus documentStatusList", documentStatusList)
    // console.log("status", status)
    if (_.isArray(documentStatusList) && _.isNumber(status)) {
      const sObj = _.find(documentStatusList, (e) => e.id === status)
      if (sObj) {
        // console.log("DocumentStatus sObj", sObj)
        if (text) {
          return <Typography className={className}>{sObj.name}</Typography>
        }
        const suffix = sObj.name
          ? capitalize(sObj.name.trim().replace(/\s/g, ""))
          : ""
        // console.log("suffix", suffix)
        const rootClass = clsx(classes.root, classes[`root${suffix}`])
        const labelClass = clsx(classes.label, classes[`label${suffix}`])

        const label = sObj.name
        // if (sObj.id === 4) {
        //   label = "Sign Off"
        // }
        return (
          <Chip
            label={label}
            classes={{
              root: rootClass,
              label: labelClass,
            }}
          />
        )
      }
    }
    return null
  }
}

DocumentStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  //   documents: PropTypes.arrayOf(PropTypes.any),
  //   orgData: PropTypes.objectOf(PropTypes.any),
  //   personData: PropTypes.objectOf(PropTypes.any),
  //   apiType: PropTypes.string,
  //   deleteDocument: PropTypes.func,
  //   getDocumentTypesList: PropTypes.func,
  //   getDocuments: PropTypes.func,
  //   getDocumentStatusList: PropTypes.func,
  documentStatusList: PropTypes.arrayOf(PropTypes.any),
  status: PropTypes.number,
  text: PropTypes.bool,
  className: PropTypes.string,
}

DocumentStatus.defaultProps = {
  classes: {},
  //   documents: [],
  //   orgData: {},
  //   personData: {},
  //   apiType: "",
  //   deleteDocument: () => {},
  //   getDocumentTypesList: () => {},
  //   getDocuments: () => {},
  //   getDocumentStatusList: () => {},
  documentStatusList: [],
  status: 0,
  text: false,
  className: "",
}

const mapStateToProps = (state) => {
  return {
    // documentTypes: state.orgReducer.documentTypes,
    // isLoadingData: state.orgReducer.isLoading,
    // error: state.orgReducer.error,
    // orgData: state.orgReducer.orgData,
    // documents: state.orgReducer.documents,
    // personData: state.personReducer.personData,
    documentStatusList: state.task.documentStatusList,
  }
}

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DocumentStatus)
)
