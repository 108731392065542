import _ from "lodash"
import Axios from "axios"
import settings from "../config/settings"
import { getTenantHeaders } from "./utils"

export const logApi = (arg) => {
  if (process.env.REACT_APP_API_LOGS === true) {
    // eslint-disable-next-line no-console
    console.log(arg)
  }
}

// eslint-disable-next-line
export const apiCall = (endpoint, method, data, headers = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    let query = ""
    let params
    if (_.isObject(data)) {
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map((key) => {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}&`
      })
    }

    const bool = method === "post" || method === "put" || method === "delete"

    if (_.isEmpty(headers) && bool) {
      // eslint-disable-next-line no-param-reassign
      headers["content-type"] = "application/json"
    }

    let str = ""
    if (
      bool &&
      headers["content-type"] &&
      headers["content-type"] === "application/json"
    ) {
      params = JSON.stringify(data)
      str = JSON.stringify(data)
    } else if (method === "get" && query !== "") {
      query = `?${query}`
    } else if (bool) {
      params = query
      if (_.isObject(data)) {
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key) => {
          str += `${key}:${data[key]}\n`
        })
      }
    }
    const tenantHeaders = getTenantHeaders()
    // eslint-disable-next-line array-callback-return
    Object.keys(tenantHeaders).map((key) => {
      // eslint-disable-next-line no-param-reassign
      headers[key] = tenantHeaders[key]
    })
    // console.log("tenantHeaders", tenantHeaders)
    // console.log("headers", headers)

    if (bool) {
      query = ""
    }
    const url = settings.baseUrl + settings.api + endpoint + query
    logApi(url)
    logApi(headers)
    logApi(str)
    const options = {
      method: method.toUpperCase(),
      // credentials: 'include',
    }
    if (bool) {
      options.body = params
    }
    options.headers = headers
    logApi(options)
    // const headers = response.headers.entries();
    try {
      logApi(`use proxy:${settings.useProxy}`)
      let fURL = url
      if (settings.useProxy === true) {
        fURL = settings.proxyUrl + url
      }
      const response = await fetch(fURL, options)
      logApi(response)
      if (response.status !== 200) {
        reject(response)
      } else {
        const responseJson = await response.json()
        logApi(responseJson)
        resolve(responseJson)
      }
    } catch (error) {
      logApi(error)
      reject(error)
    }
  })

export const getHeadersFromString = (headers) => {
  // Convert the header string into an array
  // of individual headers
  const arr = headers.trim().split(/[\r\n]+/)
  // Create a map of header names to values
  const headerMap = {}
  arr.forEach((line) => {
    const parts = line.split(": ")
    const header = parts.shift()
    const value = parts.join(": ")
    headerMap[header] = value
  })

  return headerMap
}

export const formDataReq = (
  endpoint,
  method,
  data,
  extraHeaders = {},
  onProgress
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const url = settings.baseUrl + settings.api + endpoint
    let str = ""
    if (data && Object.keys(data).length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map((dk) => {
        str += `${dk}:${data[dk]}\n`
      })
    }
    logApi(str)
    const query = new FormData()
    if (data && Object.keys(data).length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map((k) => {
        // console.log(`${k}: ${data[k]}`)
        query.append(k, data[k])
      })
    }
    const tenantHeaders = getTenantHeaders()
    try {
      const response = await Axios({
        url,
        method: method || "POST",
        headers: {
          "content-type": "multipart/form-data",
          ...tenantHeaders,
          ...extraHeaders,
        },
        data: query,
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded * 100) / event.total
            if (onProgress) {
              onProgress(progress)
            }
          } else {
            // Unable to compute progress information since the total size is unknown
          }
        },
      })
      logApi(response)
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(new Error(`Status Code: ${response.status}`))
      }
    } catch (error) {
      logApi(error)
      reject(error)
    }
  })
}

// eslint-disable-next-line
export const apiCallProgress = (
  endpoint,
  method,
  data,
  extraHeaders = {},
  onProgress,
  onHeaders = null
) =>
  new Promise((resolve, reject) => {
    logApi("apiCallProgress", data)
    const url = settings.baseUrl + settings.api + endpoint
    const oReq = new XMLHttpRequest()
    oReq.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded * 100) / event.total
        if (onProgress) {
          onProgress(progress)
        }
      } else {
        // Unable to compute progress information since the total size is unknown
      }
    })
    let str = ""
    if (data && Object.keys(data).length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map((dk) => {
        str += `${dk}:${data[dk]}\n`
      })
    }
    logApi(str)
    const query = new FormData()
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).map((k) => query.append(k, data[k]))
    }
    const params = query
    oReq.open(method, url, true)
    logApi(params)
    logApi(url)
    oReq.setRequestHeader("content-Type", "multipart/form-data")
    if (extraHeaders && Object.keys(extraHeaders).length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(extraHeaders).map((k) => {
        oReq.setRequestHeader(k, extraHeaders[k])
      })
    }
    oReq.send(params)
    oReq.onreadystatechange = () => {
      if (oReq.readyState === oReq.HEADERS_RECEIVED) {
        // Get the raw header string
        const headersR = oReq.getAllResponseHeaders()
        const hObj = getHeadersFromString(headersR)
        logApi(hObj)
        if (_.isFunction(onHeaders)) {
          onHeaders(hObj)
        }
      }
      if (oReq.readyState === XMLHttpRequest.DONE) {
        try {
          // logApi(oReq.responseText);
          const resposeJson = JSON.parse(oReq.responseText)
          resolve(resposeJson)
        } catch (error) {
          logApi(error)
          reject(error)
        }
      }
    }
    logApi(oReq)
  })
