import actions from "./actions"

// Features Configuration
const isAdmin = process.env.REACT_APP_ADMIN_ACTIVE === "true"
const isClientPortal = process.env.REACT_APP_CLIENT_PORTAL_ACTIVE === "true"

const defaultAppConfig = {
  id: 1,
  tenant_id: null,
  data: {
    features: {
      coreIDV: true,
      corePms: true,
      pmsAdmin: isAdmin,
      coreAdmin: true,
      coreAdminUsers: true,
      coreSalesForce: true,
      pmsClientPortal: isClientPortal,
    },
  },
  status: 1,
  created_at: null,
  updated_at: null,
}

const initialState = {
  appSettings: defaultAppConfig,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      }

    default:
      return state
  }
}
