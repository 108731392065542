import React from "react"

const DashBoardIcon = ({
  style = {},
  fill = "rgba(0, 0, 0, 0.25)",
  width = "100%",
  className = "navIcon",
  height = "100%",
  viewBox = "0 0 100 100",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      style={{ ...style }}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill={fill}
      x="0px"
      y="0px"
    >
      <title>dashboard</title>
      <g>
        <path d="M9,3a6,6,0,1,0,6,6A6,6,0,0,0,9,3ZM9,13a4,4,0,1,1,4-4A4,4,0,0,1,9,13Z" />
        <path d="M23,15a6,6,0,1,0-6-6A6,6,0,0,0,23,15ZM23,5a4,4,0,1,1-4,4A4,4,0,0,1,23,5Z" />
        <path d="M23,17a6,6,0,1,0,6,6A6,6,0,0,0,23,17Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,23,27Z" />
        <path d="M9,17a6,6,0,1,0,6,6A6,6,0,0,0,9,17ZM9,27a4,4,0,1,1,4-4A4,4,0,0,1,9,27Z" />
      </g>
    </svg>
  )
}

export default DashBoardIcon
