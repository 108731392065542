import React, { Component } from "react"
import _ from "lodash"
import moment from "moment"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import withStyles from "@material-ui/styles/withStyles"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import DateTimePickerComponent from "../DateTimePicker/DateTimePicker"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
import settings from "../../config/settings"
import Select from "../Select/ActionSelect"
import {
  // addBusinessDays,
  addBusinessHours,
  getBDiff,
  getNextWorkingTime,
} from "../../common/utils"

class DueDateSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      duration: 1,
      type: "days",
      startDate: moment(),
      endDate: moment(),
      reason: "",
      errors: {},
      open: false,
    }
  }

  componentDidMount() {
    const {
      defaultStartDate,
      defaultEndDate,
      defaultDuration,
      defaultDurationType,
    } = this.props
    this.setState({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      duration: defaultDuration,
      type: defaultDurationType,
    })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      defaultStartDate,
      defaultEndDate,
      defaultDuration,
      defaultDurationType,
    } = this.props
    if (!_.isEqual(nextProps.defaultStartDate, defaultStartDate)) {
      this.setState({ startDate: nextProps.defaultStartDate })
    }

    if (!_.isEqual(nextProps.defaultEndDate, defaultEndDate)) {
      this.setState({ endDate: nextProps.defaultEndDate })
    }

    if (!_.isEqual(nextProps.defaultDuration, defaultDuration)) {
      this.setState({ duration: nextProps.defaultDuration })
    }

    if (!_.isEqual(nextProps.defaultDurationType, defaultDurationType)) {
      this.setState({ type: nextProps.defaultDurationType })
    }
  }

  handleClick = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  renderDurationOption = () => {
    const { type } = this.state
    let max = 100
    if (type === "days") {
      max = 100
    }
    if (type === "hours") {
      max = 100
    }
    const ary = _.range(0, max, 1)
    return ary.map((ele) => {
      if (ele === 0) {
        return (
          <MenuItem key={`${type}_${ele}`} value={ele}>
            Select
          </MenuItem>
        )
      }
      return (
        <MenuItem key={`${type}_${ele}`} value={ele}>
          {ele}
        </MenuItem>
      )
    })
  }

  getWorkingHours = () => {
    return 8 // TODO - set via API Project working hours setting
  }

  getEndDate = () => {
    const { startDate, type, duration } = this.state
    let eD = ""
    if (startDate && startDate.isValid() && type !== "" && duration !== "") {
      const str = startDate.format()
      if (type === "days") {
        // eD = addBusinessDays(str, duration)
        eD = addBusinessHours(str, duration * this.getWorkingHours())
      } else if (type === "hours") {
        // Format hours to days to resolve end of day issue if duration is last hour of the day
        // if (duration % this.getWorkingHours() === 0) {
        //   eD = addBusinessDays(str, duration / this.getWorkingHours())
        // } else {
        eD = addBusinessHours(str, duration)
        // }
      }
    }
    // if (eD !== "" && eD.isValid()) {
    //   return moment(eD.sub.format())
    // }
    return eD
  }

  getDiff = (startDate, endDate) => {
    let dObj = null
    if (startDate && startDate.isValid() && endDate && endDate.isValid()) {
      const Str = startDate.format()
      const endStr = endDate.format()
      dObj = getBDiff(Str, endStr)
    }
    // console.log("dObj", dObj)
    return dObj
  }

  validate = () => {
    const { startDate, endDate, duration, type } = this.state
    // const endDate = this.getEndDate()
    let valid = true
    const errors = {}

    if (!startDate || !startDate.isValid || !startDate.isValid()) {
      valid = false
      errors.startDate = "Please select valid start date"
    }
    if (!endDate || !endDate.isValid || !endDate.isValid()) {
      valid = false
      errors.endDate = "Please select valid start date"
    }
    if (!duration) {
      valid = false
      errors.duration = "Please select valid duration in days/hours"
    }
    if (type !== "days" && type !== "hours") {
      valid = false
      errors.type = "Please select valid duration type"
    }

    // TODO - check and re-implement after demo
    // Remove date validation for demo
    // if (endDate.unix() < moment().startOf("day").unix()) {
    //  valid = false
    //  errors.endDate = "End date cannot be past date. it must be today or later"
    // }

    if (valid) {
      // this.updateDueDateApi()
      let dt = "D"
      if (type === "days") {
        dt = "D"
      }
      if (type === "hours") {
        dt = "H"
      }
      return {
        start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
        duration: parseInt(duration, 10),
        duration_type: dt,
        end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
      }
    }
    this.setState({ errors })
    return null
  }

  onChange = (key) => (e) => {
    const { duration } = this.state
    const obj = {}
    let value = e
    if (key !== "startDate") {
      value = e.target.value
    }

    if (key !== "startDate") {
      obj[key] = value
    } else {
      // console.log("object startdate", value)
      // eslint-disable-next-line no-lonely-if
      if (value && value.isValid && value.isValid()) {
        const nextW = getNextWorkingTime(value.format())
        obj[key] = nextW
      } else {
        obj[key] = value
      }
    }

    if (key === "type") {
      if (value === "days") {
        obj.duration = Math.ceil(duration / this.getWorkingHours()) // Round up days
      } else if (value === "hours") {
        obj.duration = Math.ceil(duration * this.getWorkingHours())
      }
      this.setState(obj)
    } else if (key !== "type") {
      // Do not update endDate on change of duration type
      this.setState(obj, () => {
        const endDate = this.getEndDate()
        if (endDate !== "") {
          this.setState({ endDate })
        }
      })
    }
  }

  onChangeEndDate = (key) => (e) => {
    const { startDate } = this.state
    const obj = {
      [key]: e,
    }
    const dObj = this.getDiff(startDate, e)
    if (dObj) {
      obj.type = dObj.type
      obj.duration = dObj.diff
    } else {
      // obj.type = dObj.type
      obj.duration = 0
    }
    this.setState(obj)
  }

  updateDueDateApi = async () => {
    const { reason, duration, type, startDate } = this.state
    const endDate = this.getEndDate()
    const {
      auth: { userdata },
      task: { task },
      apiActions: { apiCall },
      setMessage,
      addActivityApi,
    } = this.props

    let dt = "D"
    if (type === "days") {
      dt = "D"
    }
    if (type === "hours") {
      dt = "H"
    }
    try {
      const data = {
        update_type: "due_date",
        start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
        duration: parseInt(duration, 10),
        duration_type: dt,
        end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
        reason,
      }
      // const headers = {
      //   "content-type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // }
      const responseJson = await apiCall(
        settings.endpoints.updateTask(task ? task.id : 0),
        "put",
        data
        // headers
      )
      // console.log("updateDueDateApi", responseJson)
      // if (responseJson && responseJson.success) {
      // this.setState({ })
      // this.setDefaultView()
      setMessage({
        message: responseJson.message,
        open: true,
        type: responseJson.success ? "success" : "error",
      })

      if (responseJson && responseJson.success) {
        addActivityApi(
          `${userdata.name || ""} Updated due date to ${endDate.format(
            "DD/MM/YYYY"
          )} \n Reason: ${reason}`
        )
        // this.getTaskData()
      }
      // }
    } catch (error) {
      // console.log(error)
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  renderError = (key) => {
    const { errors } = this.state
    if (errors[key]) {
      return (
        <Typography color="error" style={{ fontSize: 12 }}>
          {errors[key]}
        </Typography>
      )
    }
    return null
  }

  renderStartDate = () => {
    const { classes, disabled } = this.props
    const { startDate } = this.state
    // const endDate = this.getEndDate()
    return (
      <FormControl fullWidth className={classes.inputRow}>
        <Typography className={classes.dateLabel}>Start Date</Typography>
        <DateTimePickerComponent
          variant="inline"
          ampm={false}
          format="DD/MM/YYYY HH:mm"
          // minDate={
          //   defaultStartDate && defaultStartDate.isValid()
          //     ? defaultStartDate
          //     : moment()
          // }
          id="start-date-picker-inline"
          value={startDate}
          disabled={disabled}
          onChange={this.onChange("startDate")}
        />
      </FormControl>
    )
  }

  renderDurationSelect = () => {
    const { classes, disabled } = this.props
    const { duration } = this.state
    return (
      <FormControl fullWidth className={classes.inputRow}>
        <Typography className={classes.dateLabel}>Duration</Typography>
        {/* <InputLabel className={classes.dateLabel}>Duration</InputLabel> */}
        <Select
          disableUnderline
          value={duration}
          fullWidth
          disabled={disabled}
          onChange={this.onChange("duration")}
          // inputProps={{ "aria-label": "Without label" }}
        >
          {this.renderDurationOption()}
        </Select>
      </FormControl>
    )
  }

  renderDurationType = () => {
    const { classes, disabled } = this.props
    const { type } = this.state
    // const endDate = this.getEndDate()
    return (
      <FormControl fullWidth className={classes.inputRow}>
        <Typography className={classes.dateLabel}>Duration Type</Typography>
        {/* <InputLabel className={classes.dateLabel}>Duration Type</InputLabel> */}
        <Select
          disableUnderline
          value={type}
          ampm={false}
          fullWidth
          disabled={disabled}
          onChange={this.onChange("type")}
          // inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="hours">Hours</MenuItem>
          <MenuItem value="days">Days</MenuItem>
        </Select>
      </FormControl>
    )
  }

  renderEndDate = () => {
    const { classes, disabled } = this.props
    const { endDate, startDate } = this.state
    return (
      <FormControl fullWidth className={classes.inputRow}>
        <Typography className={classes.dateLabel}>End Date</Typography>
        <DateTimePickerComponent
          variant="inline"
          // disabled
          format="DD/MM/YYYY HH:mm"
          id="end-date-picker-inline"
          value={endDate}
          ampm={false}
          minDate={startDate}
          disabled={disabled}
          onChange={this.onChangeEndDate("endDate")}
        />
        {this.renderError("endDate")}
      </FormControl>
    )
  }

  renderCollapse = () => {
    const { open } = this.state
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          flexDirection="column"
          style={{ padding: "16px 16px 8px" }}
        >
          <Grid container spacing={4} style={{ padding: 0 }}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              {this.renderStartDate()}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              {this.renderDurationType()}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              {this.renderDurationSelect()}
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ padding: 0 }}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              {this.renderEndDate()}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              {/* {this.renderStartDate()} */}
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    )
  }

  render() {
    const { open } = this.state
    return (
      <List disablePadding>
        <ListItem button divider onClick={this.handleClick}>
          <ListItemIcon>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </ListItemIcon>
          <ListItemText primary="Task Dates" />
        </ListItem>
        {this.renderCollapse()}
      </List>
    )
  }
}

DueDateSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func.isRequired,
  addActivityApi: PropTypes.func.isRequired,
  defaultStartDate: PropTypes.oneOfType([PropTypes.any]),
  defaultEndDate: PropTypes.oneOfType([PropTypes.any]),
  defaultDuration: PropTypes.oneOfType([PropTypes.any]),
  defaultDurationType: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
}

DueDateSection.defaultProps = {
  classes: {},
  task: {},
  auth: {},
  // authActions: {},
  // taskActions: {},
  apiActions: {},
  defaultStartDate: moment(),
  defaultEndDate: moment(),
  defaultDuration: 1,
  defaultDurationType: "days",
  disabled: false,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DueDateSection
  )
)
