import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Snackbar from "@material-ui/core/Snackbar"
import Slide from "@material-ui/core/Slide"
import Alert from "@material-ui/lab/Alert"

import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  WarningOutlined,
  InfoOutlined,
} from "@material-ui/icons"
import clsx from "clsx"
import styles from "./SnackBar.styles"
// import authActions from "../../redux/reducers/auth/actions"
// import apiActions from "../../redux/reducers/api/actions"
// import taskActions from "../../redux/reducers/task/actions"

class SnackBar extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleCloseSnackbar = () => {
    const { afterClose } = this.props
    if (afterClose) {
      afterClose()
    }
  }

  render() {
    const { message } = this.props
    const { classes } = this.props
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={message.open}
        classes={{
          root: classes.snackbar,
        }}
        autoHideDuration={2000}
        onClose={this.handleCloseSnackbar}
        transitionDuration={500}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      >
        <Alert
          elevation={6}
          className={classes.alertRoot}
          variant="filled"
          // onClose={this.handleCloseSnackbar}
          severity={message.type}
          iconMapping={{
            error: (
              <ErrorOutlineRounded
                className={clsx(classes.typeIcon, classes.error)}
              />
            ),
            success: (
              <CheckCircleOutlineRounded
                className={clsx(classes.typeIcon, classes.success)}
              />
            ),
            warning: (
              <WarningOutlined
                className={clsx(classes.typeIcon, classes.warning)}
              />
            ),
            info: (
              <InfoOutlined className={clsx(classes.typeIcon, classes.info)} />
            ),
          }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    )
  }
}

SnackBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  afterClose: PropTypes.func,
  //   task: PropTypes.objectOf(PropTypes.any),
  //   auth: PropTypes.objectOf(PropTypes.any),
  //   taskActions: PropTypes.objectOf(PropTypes.any),
  //   apiActions: PropTypes.objectOf(PropTypes.any),
  // handleClose: PropTypes.func,
}

SnackBar.defaultProps = {
  classes: {},
  afterClose: null,
  //   task: {},
  //   auth: {},
  //   taskActions: {},
  //   apiActions: {},
  // handleClose: () => {},
}

const mapStateToProps = (state) => ({
  //   auth: state.auth,
  //   task: state.task,
  //   api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    // authActions: bindActionCreators(authActions, dispatch),
    // taskActions: bindActionCreators(taskActions, dispatch),
    // apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SnackBar)
)
