import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
// import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
// import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { MentionsInput, Mention } from "react-mentions"
import SendIcon from "@material-ui/icons/Send"
import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"
import styles from "./SidePanel.styles"
import settings from "../../config/settings"
import { GetAcronym } from "../Drawer/Drawer"
import defaultStyle from "./defaultStyle"
import defaultMentionStyles from "./defaultMentionStyles"

class AddComment extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      comment: "",
      metadata: {},
      errors: {},
    }
  }

  addCommentApi = async () => {
    const { comment, metadata } = this.state
    const {
      auth: { userdata },
      task: { task },
      apiActions: { apiCall },
      setMessage,
      addActivityApi,
      // setDefaultView,
    } = this.props
    if (!task || !task.id) {
      return
    }
    try {
      const data = {
        project_task_id: task.id,
        type: "comment",
        message: comment,
        /* to be updated with logged in user */
        // updated_by: userdata.id,
        updated_by: userdata.auth_user_id,
        metadata,
      }
      // const headers = {
      //   "content-type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // }
      const responseJson = await apiCall(
        settings.endpoints.addActivity,
        "post",
        data
        // headers
      )
      // this.setDefaultView()
      if (responseJson.success) {
        // this.getAllComment()
        // setDefaultView()
        addActivityApi(
          `${userdata.name || ""} added comment: \n "${comment}"`,
          "comments"
        )
      }
      this.setState({ comment: "" }, () => {
        setMessage({
          message: responseJson.message,
          open: true,
          type: responseJson.success ? "success" : "error",
        })
      })
    } catch (error) {
      // console.log(error)
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  onChangeComment = (event, newValue, newPlainTextValue, mentions) => {
    const { errors } = this.state
    // console.log(event)
    // console.log(newValue)
    // console.log(newPlainTextValue)
    // console.log(mentions)
    this.setState({
      comment: event.target.value,
      metadata: {
        value: newValue,
        palainText: newPlainTextValue,
        mentions,
      },
      errors: {
        ...errors,
        comment: undefined,
      },
    })
  }

  onChange = (key) => (e) => {
    const { errors } = this.state
    this.setState({
      [key]: e.target.value,
      errors: {
        ...errors,
        [key]: undefined,
      },
    })
  }

  validate = () => {
    const { comment } = this.state
    let valid = true
    const errors = {}
    if (comment.trim() === "") {
      valid = false
      errors.comment = "Please add comment"
    }

    if (valid) {
      this.addCommentApi()
    } else {
      this.setState({ errors })
    }
  }

  renderError = (key) => {
    const { errors } = this.state
    if (errors[key]) {
      return (
        <Typography color="error" style={{ fontSize: 12 }}>
          {errors[key]}
        </Typography>
      )
    }
    return null
  }

  renderUserSuggestion = (user, search, highlightedDisplay, index, focused) => {
    const { classes } = this.props
    return (
      <ListItem
        key={`suggestion_item_${user.id}_${index}`}
        selected={focused}
        className={classes.userSuggestionListItem}
      >
        <ListItemAvatar className={classes.userSuggestionListAvatar}>
          <Avatar
            alt={user.display}
            si
            className={clsx(
              classes.userSuggestionAvatar,
              user.external && classes.userSuggestionAvatarExternal
            )}
          >
            <GetAcronym string={user.display} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={user.display}
          className={classes.userSuggestionListItemText}
        />
      </ListItem>
    )
  }

  getAssigneeList = () => {
    const {
      // classes,
      // auth: { userdata },
      task: { assigneeList, clientAssigneeList },
    } = this.props

    const retAry = []
    if (_.isArray(assigneeList)) {
      // eslint-disable-next-line array-callback-return
      assigneeList.map((ele) => {
        if (ele.id && ele.id !== "" && ele.id !== 0 && ele.id !== "0") {
          const obj = {
            id: ele.id,
            display: ele.name,
            external: false,
          }
          retAry.push(obj)
        }
      })
    }

    if (_.isArray(clientAssigneeList)) {
      // eslint-disable-next-line array-callback-return
      clientAssigneeList.map((ele) => {
        if (ele.id && ele.id !== "" && ele.id !== 0 && ele.id !== "0") {
          const obj = {
            id: ele.id,
            display: ele.name,
            external: true,
          }
          retAry.push(obj)
        }
      })
    }
    return retAry
  }

  renderCommentComponent = (props) => {
    const users = this.getAssigneeList()
    // console.log("renderCommentComponent", props)
    // console.log("renderCommentComponent", props.style)
    return (
      <MentionsInput
        // {...props}
        // inputRef={this.input}
        value={props.value}
        onChange={props.onChange}
        style={defaultStyle}
      >
        <Mention
          trigger="@"
          data={users}
          appendSpaceOnAdd
          renderSuggestion={this.renderUserSuggestion}
          style={defaultMentionStyles}
          markup="@{{__display__}}"
          displayTransform={(id, display) => {
            return `@${display}`
          }}
        />
      </MentionsInput>
    )
  }

  render() {
    const { comment } = this.state
    const {
      classes,
      auth: { userdata },
    } = this.props

    // let it = ""
    // if (userdata.name) {
    //   it = userdata.name
    //     .split(" ")
    //     .map((n) => n[0])
    //     .join("")
    // }

    const userName = userdata.name || ""
    const avatarInitials = userName
      .split(/\s/)
      // eslint-disable-next-line
      .reduce((response, word) => (response += word.slice(0, 1)), "")
    return (
      <Box
        // paddingTop={2}
        // paddingY={2}
        // paddingBottom={2}
        display="flex"
        flexDirection="row"
        style={{
          marginTop: 20,
          padding: "20px 30px 20px 25px",
          boxShadow: "2px -2px 19px 0px rgba(50, 49, 50, 0.28)",
        }}
      >
        {/* <InputLabel>Add Comment</InputLabel> */}
        <Avatar
          alt={userdata.name || ""}
          src="/static/images/avatar/2.jpg"
          className={classes.userAvatar2}
        >
          {avatarInitials}
        </Avatar>
        <TextField
          ref={this.input}
          id="outlined-multiline-static"
          placeholder="Comment"
          multiline
          rows={4}
          disableUnderline
          value={comment}
          onChange={this.onChangeComment}
          fullWidth
          // autoFocus
          InputProps={{
            inputComponent: this.renderCommentComponent,
            fullWidth: true,
            disableUnderline: true,
            classes: {
              root: classes.addComment,
              inputMultiline: classes.addCommentInput,
            },
            endAdornment: (
              <Tooltip title="Send Comment" aria-label="send-comment-tooltip">
                <IconButton
                  onClick={this.validate}
                  // color="primary"
                  disableElevation
                  aria-label="send-comment"
                  className={classes.addCommentButton}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            ),
            // endAdornment: (
            //   <Button
            //     onClick={this.validate}
            //     size="small"
            //     color="primary"
            //     disableElevation
            //     className={classes.addCommentButton}
            //   >
            //     Add Comment
            //   </Button>
            // ),
          }}
        />
        {this.renderError("comment")}
      </Box>
    )
  }
}

AddComment.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  setMessage: PropTypes.func.isRequired,
  addActivityApi: PropTypes.func.isRequired,
  // setDefaultView: PropTypes.func.isRequired,
}

AddComment.defaultProps = {
  classes: {},
  task: {},
  auth: {},
  // authActions: {},
  // taskActions: {},
  apiActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AddComment
  )
)
