import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
// import ListSubheader from "@material-ui/core/ListSubheader"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import Badge from "@material-ui/core/Badge"
// import InboxIcon from "@material-ui/icons/MoveToInbox"
// import DraftsIcon from "@material-ui/icons/Drafts"
// import SendIcon from "@material-ui/icons/Send"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
// import StarBorder from "@material-ui/icons/StarBorder"
import { bindActionCreators } from "redux"
import { withStyles } from "@material-ui/styles"
import { connect } from "react-redux"
import styles from "./Drawer.styles"
import Can from "../Can"
import Components from "../NavIcon/Components"
import authActions from "../../redux/reducers/auth/actions"

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
// }))

function DrawerItem(props) {
  const {
    classes,
    route,
    navigate,
    auth: { unreadCount },
  } = props
  const [open, setOpen] = React.useState(!!route.expand)

  const handleClick = () => {
    setOpen(!open)
  }

  const renderListItem = (route2, expand = false) => {
    if (!route2 || !route2.drawer) {
      return null
    }
    const Icon = (
      <ListItemIcon
        className={route2.sub ? classes.subMenuItemIcon : classes.menuItemIcon}
      >
        {Components(route2.icon)}
      </ListItemIcon>
    )

    let exIcon = null
    if (_.isArray(route2.children) && route2.children.length) {
      if (open) {
        exIcon = <ExpandLess style={{ fill: "rgba(0, 0, 0, 0.54)" }} />
      } else {
        exIcon = <ExpandMore style={{ fill: "rgba(0, 0, 0, 0.54)" }} />
      }
    }

    let onClick = null
    if (!route2.disable) {
      onClick = navigate(route2.path)
    }
    if (expand) {
      onClick = handleClick
    }
    return (
      <ListItem
        key={`route_${route2.path}`}
        className={route2.sub ? classes.nested : classes.menuItem}
        button
        // selected={selected}
        onClick={onClick}
      >
        {route2.path === "notifications" ? (
          <Badge
            badgeContent={unreadCount}
            color="error"
            classes={{
              anchorOriginTopRightRectangle: classes.badgeAnchorOrigin,
            }}
          >
            {Icon}
          </Badge>
        ) : (
          Icon
        )}
        <ListItemText
          primary={route2.name}
          className={
            route2.sub ? classes.subMenuItemText : classes.menuItemText
          }
        />
        {exIcon}
      </ListItem>
    )
  }

  if (_.isArray(route.children) && route.children.length) {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {renderListItem(route, true)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {route.children.map((subRoute) => renderListItem(subRoute))}
          </List>
        </Collapse>
      </List>
    )
  }

  return renderListItem(route)
}

DrawerItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  route: PropTypes.objectOf(PropTypes.any),
  navigate: PropTypes.func,
  // userRoles: PropTypes.arrayOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
}

DrawerItem.defaultProps = {
  classes: {},
  route: {},
  navigate: () => {},
  // userRoles: [],
  auth: {},
}

function DrawerNavItem(props) {
  const { route, userRoles } = props
  if (!route.drawer) {
    return null
  }

  if (route.admin || route.set) {
    return (
      <Box style={{ marginTop: "20px" }}>
        <Can
          roleLevel={route.roleLevel}
          role={userRoles[0]}
          yes={() => (
            <>
              <Divider style={{ marginLeft: "10px", marginBottom: "20px" }} />
              <DrawerItem {...props} />
            </>
          )}
        />
      </Box>
    )
  }

  return (
    <Can
      roleLevel={route.roleLevel}
      role={userRoles[0]}
      yes={() => <DrawerItem {...props} />}
    />
  )
}

DrawerNavItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  route: PropTypes.objectOf(PropTypes.any),
  userRoles: PropTypes.arrayOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
}

DrawerNavItem.defaultProps = {
  classes: {},
  route: {},
  userRoles: [],
  // auth: {},
}

const mapStateToProps = (state) => ({ auth: state.auth })

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DrawerNavItem)
)
