import { canAccess } from "../common/authorization"

// eslint-disable-next-line no-unused-vars
const checkBk = (rules, role, action, data) => {
  const permissions = rules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }

    return permissionCondition(data)
  }
  return false
}

// eslint-disable-next-line no-unused-vars
const basicCheck = (roleLevel, role, action = null, data = null) => {
  // console.log("_ROLE CHECK", [roleLevel, role])
  // console.log("_ROLE CHECK RESULT", [canAccess(roleLevel, role)])

  return canAccess(roleLevel, role)
}

const Can = (props) =>
  // check(rules, props.role, props.perform, props.data) ? props.yes() : props.no()
  basicCheck(props.roleLevel, props.role, props.perform, props.data)
    ? props.yes()
    : props.no()
Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default Can
