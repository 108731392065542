import { createMuiTheme, colors } from "@material-ui/core"
import {
  orange,
  indigo,
  blue,
  grey,
  red,
  green,
} from "@material-ui/core/colors"
import AppColors from "./colors"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.primary,
    },
    secondary: indigo,
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Circular Pro",
      "Nunito",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  status: {
    danger: orange[500],
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        // minWidth: 400,
      },
    },
    MuiToolbar: {
      root: {},
      regular: {},
      toolbar: {},
      gutters: {},
    },
    MuiPickerDTToolbar: {
      toolbar: {},
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: AppColors.primary,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: "#F004",
        // color: "white",
      },
    },
    MuiPickersDay: {
      // day: {
      //   color: AppColors.primary,
      // },
      // daySelected: {
      //   backgroundColor: AppColors.primary,
      // },
      // dayDisabled: {
      //   color: "#CCC",
      // },
      // current: {
      //   color: "#FFF",
      // },
    },
    MuiPickersModal: {
      // dialogAction: {
      //   color: AppColors.primary,
      // },
    },
    MuiTab: {
      root: {
        minWidth: "auto !important",
        padding: "6px 10px",
        // paddingBottom: 12,
        marginRight: 10,
        color: "#2F2F2F80",
        "&$selected": {
          color: "#2F2F2F",
        },
      },
      wrapper: {
        textTransform: "capitalize",
        fontSize: 16,
        fontWeight: "normal",
      },
    },
    MuiGrid: {
      item: {
        flex: "0 0 auto",
      },
    },
    MuiDropzoneArea: {
      textContainer: {
        marginBottom: 30,
      },
    },
    MuiDropzonePreviewList: {
      root: {
        padding: "20px 30px 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      imageContainer: {
        maxWidth: "unset",
        flexBasis: "unset",
        width: "100%",
        "& .MuiTypography-root": {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      image: {
        height: "50px",
      },
      removeButton: {
        right: "35%",
      },
    },
    MuiToggleButton: {
      root: {
        // backgroundColor: "#00D",
        height: 40,
        "&$active": {
          backgroundColor: `${AppColors.primary}10`,
        },
      },
    },
    MuiChip: {
      outlinedSecondary: {
        color: "#F00",
        border: "1px solid #F00",
      },
    },
    MuiTableCell: {
      paddingCheckbox: {
        width: 64,
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: `${AppColors.primary}10`,
        },
      },
    },
    MuiListItem: {
      secondaryAction: {
        paddingRight: 100,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 15,
      },
    },
    MuiListItemAvatar: {},
    MuiCard: {
      root: {
        boxShadow: "0px 2px 6px #0000000A",
      },
    },
    MuiAvatar: {
      root: {
        width: 52,
        height: 52,
        borderRadius: 26,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 56,
      },
    },
    MuiExpansionPanel: {
      root: {
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 64,
      },
    },
    MuiSelect: {
      // root: {
      //   background: colors.common.white,
      //   borderWidth: 1,
      //   borderColor: AppColors.inputBorder,
      //   borderStyle: "solid",
      //   borderRadius: 4,
      //   height: 24,
      //   padding: "5px 10px",
      //   color: AppColors.primary,
      //   fontSize: 14,
      //   "&:focus": {
      //     borderRadius: 4,
      //   },
      // },
      // select: {
      //   background: colors.common.white,
      //   borderWidth: 1,
      //   borderColor: AppColors.inputBorder,
      //   borderStyle: "solid",
      //   borderRadius: 4,
      //   height: 24,
      //   padding: "5px 10px",
      //   color: AppColors.primary,
      //   fontSize: 14,
      //   "&:focus": {
      //     borderRadius: 4,
      //   },
      // },
      selectMenu: {
        height: 24,
        display: "flex",
        alignItems: "center",
      },
    },
    MuiAppBar: {
      colorDefault: {
        color: "#2F2F2F",
        backgroundColor: "#fafafa",
      },
    },
    MuiTypography: {
      h6: {
        fontSize: "20px",
        fontWeight: "normal",
      },
    },
    MuiInput: {
      root: {
        background: colors.common.white,
        borderWidth: 1,
        borderColor: AppColors.inputBorder,
        borderStyle: "solid",
        borderRadius: 4,
        height: 50,
        padding: "5px 10px",
        fontSize: 14,
        // margin: "8px 0",
      },
      multiline: {
        height: 70,
      },
      inputMultiline: {
        height: 60,
      },
    },
  },
  header: {
    color: "#A5A5A5",
    background: blue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    // color: "#2F2F2F",
    color: "#A5A5A5",
    background: "#FFF",
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF",
      },
      logo: {
        color: "#2F2F2F",
      },
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: "#FFF",
      background: green[600],
    },
    avatar: {
      color: "#FFF",
      background: orange[600],
    },
    icon: {},
  },
  body: {
    background: "#F7F9FC",
  },
})

export default theme
