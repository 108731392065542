import _ from "lodash"
// import Axios from "axios"
import authActions from "../auth/actions"
import apiActions from "../api/actions"
import settings from "../../../config/settings"

const actions = {
  GET_PROGRAMME_STATUS_REQUEST: "programme/GET_PROGRAMME_STATUS_REQUEST",
  GET_PROGRAMME_STATUS_SUCCESS: "programme/GET_PROGRAMME_STATUS_SUCCESS",
  GET_PROGRAMME_STATUS_FAILED: "programme/GET_PROGRAMME_STATUS_FAILED",

  GET_COMPANY_LIST_REQUEST: "programme/GET_COMPANY_LIST_REQUEST",
  GET_COMPANY_LIST_SUCCESS: "programme/GET_COMPANY_LIST_SUCCESS",
  GET_COMPANY_LIST_FAILED: "programme/GET_COMPANY_LIST_FAILED",

  GET_PROGRAMME_REQUEST: "programme/GET_PROGRAMME_REQUEST",
  GET_PROGRAMME_SUCCESS: "programme/GET_PROGRAMME_SUCCESS",
  GET_PROGRAMME_FAILED: "programme/GET_PROGRAMME_FAILED",

  GET_PROGRAMME_PROJECTS_REQUEST: "programme/GET_PROGRAMME_PROJECTS_REQUEST",
  GET_PROGRAMME_PROJECTS_SUCCESS: "programme/GET_PROGRAMME_PROJECTS_SUCCESS",
  GET_PROGRAMME_PROJECTS_FAILED: "programme/GET_PROGRAMME_PROJECTS_FAILED",

  setProjectStatusList: (payload) => (dispatch) => {
    return dispatch({
      type: actions.GET_PROGRAMME_STATUS_SUCCESS,
      payload,
    })
  },

  getProgramStatusList: () => async (dispatch) => {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.getProgramStatusList, "get", {})
      )
      // console.log("getProgramStatusList", responseJson)
      if (responseJson && responseJson.success) {
        return dispatch(actions.setProjectStatusList(responseJson.data))
      }
      return dispatch(actions.setProjectStatusList([]))
    } catch (error) {
      // console.log("getProgramStatusList", error)
      return dispatch(actions.setProjectStatusList([]))
    }
  },

  setCompanyList: (payload) => (dispatch) => {
    return dispatch({
      type: actions.GET_COMPANY_LIST_SUCCESS,
      payload,
    })
  },

  getCompanyList: () => async (dispatch) => {
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(settings.endpoints.organizations.list, "get", {})
      )
      // console.log("getCompanyList", responseJson)
      if (responseJson && responseJson.success) {
        const ary = []
        // eslint-disable-next-line array-callback-return
        responseJson.data.map((ele) => {
          let first = ""
          if (_.isString(ele.legal_name)) {
            first = ele.legal_name.charAt(0)
            const obj = {
              id: ele.id,
              legal_name: ele.legal_name,
              first: _.isString(first) ? first : "",
            }
            ary.push(obj)
          }
        })
        return dispatch(actions.setCompanyList(ary))
      }
      return dispatch(actions.setCompanyList([]))
    } catch (error) {
      // console.log("getCompanyList", error)
      return dispatch(actions.setCompanyList([]))
    }
  },

  setProgrammeProjects: (payload, filter) => (dispatch) => {
    return dispatch({
      type: actions.GET_PROGRAMME_PROJECTS_SUCCESS,
      payload,
      filter,
    })
  },

  refreshPrograms: () => (dispatch, getState) => {
    const { program, currentFilter } = getState().programme
    return dispatch(actions.getProgrammeProjects(program.id, currentFilter))
  },

  getProgrammeProjects: (programmeId = "", data = {}) => async (
    dispatch,
    getState
  ) => {
    const { program } = getState().programme
    dispatch({ type: actions.GET_PROGRAMME_PROJECTS_REQUEST })
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProgrammeProjects(programmeId || program.id),
          "get",
          data
        )
      )
      // console.log("getProgrammeProjects", responseJson)
      if (responseJson && responseJson.success) {
        if (responseJson.data && responseJson.data.length) {
          return dispatch(actions.setProgrammeProjects(responseJson.data, data))
        }
        return dispatch({
          type: actions.GET_PROGRAMME_PROJECTS_FAILED,
          payload: "No Projects found",
          filter: data,
        })
      }
      return dispatch({
        type: actions.GET_PROGRAMME_PROJECTS_FAILED,
        payload: responseJson?.message || "No Projects found",
      })
    } catch (error) {
      // console.log("getProgrammeProjects", error)
      return dispatch({
        type: actions.GET_PROGRAMME_PROJECTS_FAILED,
        payload: error?.message || "Error getting projects",
      })
    }
  },

  setProgramme: (payload) => (dispatch) => {
    // console.log("setProgramme", payload)
    const companyName =
      payload && payload.company_name && payload.company_name !== ""
        ? `${payload.company_name} : `
        : ""
    dispatch(
      authActions.setRouteName(
        payload && payload.name ? companyName + payload.name : ""
      )
    )
    return dispatch({
      type: actions.GET_PROGRAMME_SUCCESS,
      payload,
    })
  },

  getProgramme: (programmeId = "") => async (dispatch) => {
    dispatch({ type: actions.GET_PROGRAMME_REQUEST })
    try {
      const responseJson = await dispatch(
        apiActions.apiCall(
          settings.endpoints.getProgramme(programmeId),
          "get",
          {}
        )
      )
      // console.log("getProgramme", responseJson)
      if (responseJson && responseJson.success) {
        return dispatch(actions.setProgramme(responseJson.data))
      }
      return dispatch({
        type: actions.GET_PROGRAMME_FAILED,
        payload: responseJson?.message || "Error getting projects",
      })
    } catch (error) {
      // console.log("getCompanyList", error)
      return dispatch({
        type: actions.GET_PROGRAMME_FAILED,
        payload: error?.message || "Error getting projects",
      })
    }
  },
}

export default actions
