import React, { Component } from "react"
import { connect } from "react-redux"
import { CircularProgress, Container, Box } from "@material-ui/core"

class Loader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Container style={{ height: "100vh" }}>
        <Box
          // height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ width: "100%", height: "100%" }}
        >
          <CircularProgress variant="indeterminate" color="primary" />
        </Box>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Loader)
