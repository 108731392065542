import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import authActions from "../../redux/reducers/auth/actions"
import styles from "./styles"
import EnhancedTableToolbar from "./EnhancedTableToolbar"
import EnhancedTableHead from "./EnhancedTableHead"

class TableComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: "asc",
      orderBy: "",
      orderByObj: null,
      selected: [],
      page: 0,
      // dense: false,
      rowsPerPage: 20,
    }
  }

  descendingComparator = (a, b, orderBy) => {
    const { orderByObj } = this.state
    let compareA
    let compareB
    let sortFunc
    if (orderByObj && _.isFunction(orderByObj.getSortVal)) {
      sortFunc = orderByObj.getSortVal
    }
    if (_.isFunction(sortFunc)) {
      compareA = sortFunc(a)
    } else {
      compareA = _.get(a, orderBy, 0)
    }
    if (_.isFunction(sortFunc)) {
      compareB = sortFunc(b)
    } else {
      compareB = _.get(b, orderBy, 0)
    }
    // console.log("orderByObj", orderByObj)
    // console.log("sortFunc", sortFunc)
    // console.log(compareA, compareB)
    if (compareB < compareA) {
      return -1
    }
    if (compareB > compareA) {
      return 1
    }
    return 0
  }

  getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy)
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  getSelected = () => {
    const { selected } = this.state
    return selected
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const { columns } = this.props
    const isAsc = orderBy === property && order === "asc"
    const orderByObj = _.find(columns, (i) => i.id === property)
    // console.log(orderByObj)
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
      orderByObj,
    })
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      //   setSelected(newSelecteds);
      this.setState({ selected: newSelecteds })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, name) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  handleChangeDense = (event) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ dense: event.target.checked })
  }

  handleTableRowClick = (row) => (event) => {
    const { onRowClick, checkboxes } = this.props
    if (_.isFunction(onRowClick)) {
      onRowClick(event, row)
    } else if (checkboxes) {
      this.handleClick(event, row.id)
    }
  }

  isSelected = (name) => {
    const { selected } = this.state
    return selected.indexOf(name) !== -1
  }

  renderCell = (data) => {
    const { renderCell } = this.props
    const {
      row,
      column: { id },
    } = data
    if (_.isFunction(renderCell)) {
      return renderCell(data)
    }
    return row[id]
  }

  renderItem = (row, index) => {
    const { renderItem } = this.props
    if (_.isFunction(renderItem)) {
      return renderItem(row, index)
    }
    const isItemSelected = this.isSelected(row.id)
    const labelId = `enhanced-table-checkbox-${index}`
    const { columns, checkboxes } = this.props
    return (
      <TableRow
        hover
        onClick={this.handleTableRowClick(row)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        style={{
          cursor: "pointer",
        }}
        selected={checkboxes ? isItemSelected : false}
      >
        {checkboxes ? (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(event) => this.handleClick(event, row.id)}
            />
          </TableCell>
        ) : null}
        {columns.map((column, cIndex) => {
          const ele = this.renderCell({
            row,
            index,
            column,
            cIndex,
          })
          // if (cIndex === 0) {
          //   return (
          //     <TableCell component="th" id={labelId} scope="row" padding="none">
          //       {ele}
          //     </TableCell>
          //   )
          // }
          // console.log("column.width", column.width)
          if (cIndex === 0) {
            return (
              <TableCell
                component="th"
                id={labelId}
                // eslint-disable-next-line react/no-array-index-key
                key={`${row.id}_${cIndex}`}
                scope="row"
                style={column.width ? { width: `${column.width}%` } : undefined}
              >
                {ele}
              </TableCell>
            )
          }
          return (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={`${row.id}_${cIndex}`}
              align={column.numeric ? "right" : "left"}
              style={column.width ? { width: `${column.width}%` } : undefined}
            >
              {ele}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }

  renderTableContent = () => {
    const { rows } = this.props
    const { order, orderBy, page, rowsPerPage } = this.state
    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    if (_.isArray(rows) && rows.length > 0) {
      return (
        <TableBody>
          {this.stableSort(rows, this.getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => this.renderItem(row, index))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
      )
    }

    return null
  }

  renderNoData = () => {
    const {
      classes,
      loadingError,
      loading,
      task: { open },
      panel,
    } = this.props
    if (loading) {
      return null
    }
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={clsx(
          classes.loadingView,
          !panel && open && classes.loadingViewOpen
        )}
      >
        <Typography variant="body1">{loadingError}</Typography>
      </Grid>
    )
  }

  renderLoading = () => {
    const {
      classes,
      loading,
      task: { open },
      panel,
    } = this.props
    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={clsx(
            classes.loadingView,
            !panel && open && classes.loadingViewOpen
          )}
        >
          <CircularProgress size={50} />
        </Grid>
      )
    }
    return null
  }

  renderTable = () => {
    const {
      classes,
      rows,
      columns,
      dense,
      checkboxes,
      loading,
      stickyHeader,
    } = this.props
    const { order, orderBy, selected } = this.state
    if (loading) {
      return null
    }
    if (_.isArray(rows) && rows.length > 0) {
      return (
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            stickyHeader={stickyHeader}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
              checkboxes={checkboxes}
            />
            {this.renderTableContent()}
          </Table>
        </TableContainer>
      )
    }
    return this.renderNoData()
  }

  render() {
    const {
      classes,
      rows,
      // columns,
      // dense,
      filter,
      // checkboxes,
      renderFilter,
      loading,
    } = this.props
    // eslint-disable-next-line no-unused-vars
    const { order, orderBy, selected, page, rowsPerPage } = this.state
    // const emptyRows =
    // rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          {filter && (
            <EnhancedTableToolbar
              numSelected={selected.length}
              renderFilter={renderFilter}
            />
          )}
          {this.renderLoading()}
          {this.renderTable()}
          {!loading && _.isArray(rows) && rows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={this.handleChangeDense} />}
          label="Dense padding"
        /> */}
      </div>
    )
  }
}

TableComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  rows: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  loadingError: PropTypes.string,
  renderCell: PropTypes.func,
  onRowClick: PropTypes.func,
  checkboxes: PropTypes.bool,
  dense: PropTypes.bool,
  filter: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  // history: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  renderFilter: PropTypes.func,
  renderItem: PropTypes.func,
  task: PropTypes.objectOf(PropTypes.any),
  panel: PropTypes.bool,
}

TableComponent.defaultProps = {
  classes: {},
  rows: [],
  columns: [],
  loading: false,
  loadingError: "No data found",
  renderCell: null,
  onRowClick: null,
  checkboxes: true,
  dense: false,
  filter: true,
  stickyHeader: false,
  // history: {},
  // authActions: {},
  renderFilter: null,
  renderItem: null,
  task: {},
  panel: false,
}

const mapStateToProps = (state) => ({ auth: state.auth, task: state.task })

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TableComponent
  )
)
