import React from "react"
// import { useGA4React } from "ga-4-react"
import "./App.css"
import AppContainer from "./containers/AppContainer/App"

// class App extends Component {
//   constructor(props) {
//     super(props)

//     this.state = {}
//   }

//   render() {
//     return <AppContainer />
//   }
// }

function App() {
  // eslint-disable-next-line no-unused-vars
  // const ga = useGA4React()
  // console.log("useGA4React", ga)
  return <AppContainer />
}

export default App
