import React, { Component } from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/styles/withStyles"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import styles from "./AccessDenied.styles"
import PoweredBy from "../../components/PoweredBy/PoweredBy"
import authActions from "../../redux/reducers/auth/actions"
import apiActions from "../../redux/reducers/api/actions"
import taskActions from "../../redux/reducers/task/actions"

class AccessDenied extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const {
      authActions: { setRouteName },
      taskActions: { setProject },
    } = this.props
    setRouteName("")
    setProject({})
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Container maxWidth={false} className={classes.container}>
          <Paper className={classes.form} elevation={3}>
            <Grid
              container
              justify="center"
              style={{ flexDirection: "column" }}
            >
              <Grid
                container
                style={{ marginBottom: 20, marginTop: 30 }}
                alignItems="center"
                justify="center"
              >
                <Typography
                  variant="h1"
                  style={{ fontSize: "30px", color: "#2F2F2F80" }}
                >
                  Access Denied
                </Typography>
              </Grid>
              <Grid>
                <Typography align="center" style={{ color: "#2F2F2F80" }}>
                  <div>You do not have enough rights to access this page</div>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <PoweredBy />
        </Container>
      </div>
    )
  }
}

AccessDenied.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // history: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  // task: PropTypes.objectOf(PropTypes.any),
  taskActions: PropTypes.objectOf(PropTypes.any),
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
  // apiActions: PropTypes.objectOf(PropTypes.any),
  authActions: PropTypes.objectOf(PropTypes.any),
}

AccessDenied.defaultProps = {
  classes: {},
  // history: {},
  // auth: {},
  // task: {},
  taskActions: {},
  // apiActions: {},
  authActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  task: state.task,
  api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccessDenied)
)
