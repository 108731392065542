const styles = (theme) => ({
  container: {
    maxHeight: 600,
    overflowY: "scroll",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  loadingView: {
    height: 300,
    width: "100%",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  loadingViewOpen: {
    // width: "calc(100% - 754px)",
  },
})

export default styles
