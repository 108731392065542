import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"
import moment from "moment"
import clsx from "clsx"
import withStyles from "@material-ui/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Avatar from "@material-ui/core/Avatar"
import Badge from "@material-ui/core/Badge"
import IconButton from "@material-ui/core/IconButton"
import CloseRounded from "@material-ui/icons/CloseRounded"
import CheckRounded from "@material-ui/icons/CheckRounded"
import TextField from "@material-ui/core/TextField"
// import MenuItem from "@material-ui/core/MenuItem"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Switch from "@material-ui/core/Switch"
import { DropzoneArea } from "material-ui-dropzone"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import InputAdornment from "@material-ui/core/InputAdornment"
// import Select from "../../../components/Select/ActionSelect"
import authActions from "../../../redux/reducers/auth/actions"
import apiActions from "../../../redux/reducers/api/actions"
import taskActions from "../../../redux/reducers/task/actions"
import programmeActions from "../../../redux/reducers/programme/actions"
import templateActions from "../../../redux/reducers/template/actions"
import styles from "./styles"
import settings from "../../../config/settings"
import { formDataReq } from "../../../common/apiHelper"
// import { capitalizeFirstLetter } from "../../../common/utils"

const maxFileSize = 25 * 1000000

const initialState = {
  name: "",
  description: "",
  notes: "",
  project: "",
  startDate: moment(),
  endDate: moment().add(30, "days"),
  errors: {},
  logoFile: null,
  imageUrl: null,
  templateType: "1",
  templateStatus: "1",
  isUpload: false,
  files: [],
  nameCheckResponse: {},
}

class CreateTemplateModal extends Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
    this.state = {
      ...initialState,
      open: false,
      loading: false,
      tError: "",
    }
  }

  componentDidMount() {
    // this.getData();
  }

  handleOpen = () => {
    this.setState({ ...initialState, open: true }, this.getData)
  }

  getData = () => {
    const {
      templateActions: {
        getTemplateTypeList,
        getTemplateStatusList,
        getProjects,
      },
    } = this.props
    getTemplateTypeList()
    getTemplateStatusList()
    getProjects()
  }

  afterList = () => {
    const { edit } = this.props
    if (edit) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    this.setState({
      ...initialState,
    })
  }

  handleTemplateNameCheck = async () => {
    const { name } = this.state
    const {
      apiActions: { apiCall },
      // project,
      // edit,
    } = this.props
    if (_.isString(name) && name.trim().length >= 4) {
      // if (project && edit && _.isEqual(project.project_name, name)) {
      //   this.setState({ nameCheckResponse: {} })
      //   return
      // }
      try {
        const responseJson = await apiCall(
          settings.endpoints.searchTemplate(name),
          "get",
          {}
        )
        if (responseJson.success) {
          this.setState({ nameCheckResponse: responseJson.data })
        } else {
          this.setState({ nameCheckResponse: {} })
        }
      } catch (error) {
        // console.log(error, JSON.stringify(error))
        this.setState({ nameCheckResponse: {} })
        // this.setState({ nameCheckResponse: responseJson.data })
      }
    }
  }

  handleClose = (success = false) => {
    this.setState({ loading: false, open: false }, () => {
      const { onClose } = this.props
      if (_.isFunction(onClose)) {
        onClose(success)
      }
    })
  }

  handleOk = () => {
    this.validate()
  }

  getNameError = () => {
    const { name, errors, nameCheckResponse } = this.state
    let error = false
    let msg = ""
    const isCheck = _.isString(name) && name.trim().length >= 4
    if (nameCheckResponse && isCheck) {
      if (_.isEmpty(nameCheckResponse)) {
        error = false
        msg = "Template name available"
      } else if (nameCheckResponse.id) {
        error = true
        msg = "Template name already taken"
      }
    }
    if (!error) {
      error = !!errors.name
    }
    if (errors.name) {
      msg = errors.name
    }
    return {
      error,
      msg,
    }
  }

  validate = () => {
    const {
      name,
      templateType,
      templateStatus,
      isUpload,
      files,
      description,
      project,
    } = this.state
    const errors = {}
    let valid = true
    if (!name || name.trim() === "") {
      valid = false
      errors.name = "Please Enter program name"
    }

    if (!description || description.trim() === "") {
      valid = false
      errors.description = "Please Enter program description"
    }

    if (templateType === "") {
      valid = false
      errors.templateType = "Please Select Template Type"
    }

    if (templateStatus === "") {
      valid = false
      errors.templateStatus = "Please Select Template Status"
    }

    if (isUpload && _.isEmpty(files)) {
      valid = false
      errors.files = "Please upload file"
    }

    if (!isUpload && (project === "" || !project.id)) {
      valid = false
      errors.project = "Please Select project"
    }

    const { error: nameError, msg: nameMsg } = this.getNameError()

    if (nameError) {
      valid = false
      errors.name = nameMsg
    }

    if (valid) {
      this.templateImport()
    } else {
      this.setState({ errors })
    }
  }

  // eslint-disable-next-line no-unused-vars
  onProgessUpload = (progress) => {
    // eslint-disable-next-line no-console
    console.log("progress", progress)
  }

  templateImport = async () => {
    const {
      auth: { token },
    } = this.props
    const {
      name,
      files,
      templateType,
      templateStatus,
      description,
      project,
      isUpload,
      logoFile,
      imageUrl,
    } = this.state
    this.setState({ loading: true })
    try {
      const data = {
        // file: files,
        name,
        description,
        temp_type_id: templateType,
        temp_status: templateStatus,
        is_active: 1,
        is_repeatable: 1,
      }
      if (!isUpload && project) {
        data.project_id = project.id
      }
      if (isUpload && files) {
        data.file = files
      }
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const responseJson = await formDataReq(
        settings.endpoints.templateImport,
        "POST",
        data,
        headers,
        this.onProgessUpload
      )
      this.setState({ loading: false }, () => {
        if (responseJson && responseJson.success) {
          // console.log("CT-RESPONSE:", responseJson)
          if (logoFile && imageUrl) {
            this.createTemplateLogo(responseJson)
          } else {
            this.handleSuccess(responseJson)
          }
        } else {
          // console.log("CT-RESPONSE1:", responseJson)
          this.handleError(responseJson.message)
        }
      })
    } catch (error) {
      // console.log("CT-RESPONSE:", error)
      this.setState({ loading: false }, () => {
        this.handleError()
      })
    }
  }

  createTemplateLogo = async (cResponse) => {
    const {
      auth: { token, userdata },
    } = this.props
    const { logoFile } = this.state
    // console.log("logofile:", logoFile)
    try {
      const data = {
        template_id: cResponse.data.id,
        user_id: userdata.id,
        request_user_id: userdata.auth_user_id,
        logo_file: logoFile,
      }
      const headers = {
        // "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
      const responseJson = await formDataReq(
        settings.endpoints.templateLogo,
        "POST",
        data,
        headers,
        this.onProgessUpload
      )

      if (responseJson && responseJson.success) {
        this.handleSuccess(cResponse)
      } else {
        this.handleError(responseJson.message)
      }
    } catch (error) {
      // console.log(error)
      this.handleError()
    }
  }

  handleSuccess = (responseJson) => {
    const { setMessage } = this.props
    setMessage({
      message: responseJson.message,
      type: "success",
      open: true,
    })
    this.handleClose(true)
  }

  handleError = (message = null) => {
    const { setMessage } = this.props
    const msg = message || "Unable to Load Due to Network / Server Error"
    const sObj = {
      message: msg,
      type: "error",
      open: true,
    }
    this.setState(
      {
        loading: false,
        tError: msg,
      },
      () => {
        setMessage(sObj)
      }
    )
  }

  onChange = (key) => (e) => {
    const { errors } = this.state
    this.setState(
      {
        [key]: e.target.value,
        errors: {
          ...errors,
          [key]: undefined,
        },
      },
      () => {
        if (key === "name") {
          this.handleTemplateNameCheck()
        }
      }
    )
  }

  onChangeAutocomplete = (key) => (event, newValue) => {
    const { errors } = this.state
    this.setState({
      [key]: newValue,
      errors: {
        ...errors,
        [key]: undefined,
      },
    })
  }

  renderError = (key) => {
    const { errors } = this.state
    if (errors[key]) {
      return <FormHelperText color="error">{errors[key]}</FormHelperText>
    }
    return null
  }

  onFileChange = (event) => {
    this.setState({ logoFile: event.target.files[0] }, () => {
      const { logoFile: file } = this.state
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({
          imageUrl: reader.result,
        })
      }
      if (file) {
        reader.readAsDataURL(file)
        this.setState({
          imageUrl: reader.result,
        })
      } else {
        this.setState({
          imageUrl: null,
        })
      }
    })
  }

  removeLogo = () => {
    this.setState(
      {
        imageUrl: null,
        logoFile: null,
      },
      this.clearFileInput
    )
  }

  clearFileInput = () => {
    if (this.fileInput) {
      this.fileInput.value = ""
    }
  }

  renderCreateLogo = () => {
    const { classes } = this.props
    const { logoFile, imageUrl } = this.state
    return (
      <div className={classes.addLogo}>
        <input
          accept="image/*"
          id="contained-button-file"
          // multiple
          type="file"
          ref={(ref) => {
            this.fileInput = ref
          }}
          style={{ display: "none" }}
          onChange={this.onFileChange}
        />
        {!logoFile && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label htmlFor="contained-button-file">
            <Button variant="text" color="primary" component="span">
              Add Logo
            </Button>
          </label>
        )}

        {logoFile && (
          <Badge
            overlap="circle"
            color="error"
            classes={{
              badge: classes.closeBadge,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={
              <IconButton
                onClick={this.removeLogo}
                color="inherit"
                className={clsx(classes.closeBadge, classes.closeIconButton)}
              >
                <CloseRounded />
              </IconButton>
            }
          >
            <Avatar alt="Add logo" src={imageUrl} className={classes.addLogo} />
          </Badge>
        )}
      </div>
    )
  }

  renderLogo = () => {
    const { edit } = this.props
    if (edit) {
      return this.renderCreateLogo()
    }
    return this.renderCreateLogo()
  }

  onChangeFiles = (files) => {
    const { errors } = this.state
    const file = files && files[0] ? files[0] : null
    // console.log("onChangeFiles", file)
    if (file) {
      this.setState({
        files: file,
        errors: {
          ...errors,
          files: undefined,
        },
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  onFileDelete = (file) => {
    // console.log(file)
  }

  renderNameIcon = () => {
    const { name, nameCheckResponse } = this.state
    let icon = null
    const isCheck = _.isString(name) && name.trim().length >= 4
    if (nameCheckResponse && isCheck) {
      if (_.isEmpty(nameCheckResponse)) {
        icon = <CheckRounded style={{ marginRight: 8, color: "#0A0" }} />
      } else if (nameCheckResponse.id) {
        icon = <CloseRounded style={{ marginRight: 8, color: "#F00" }} />
      }
    }
    return icon
  }

  render() {
    const {
      classes,
      edit,
      template: { projectsList },
    } = this.props
    const {
      open,
      loading,
      name,
      errors,
      tError,
      description,
      // templateType,
      // templateStatus,
      isUpload,
      project,
    } = this.state
    const { error: nameError, msg: nameMsg } = this.getNameError()
    const NameIcon = this.renderNameIcon()
    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        title=""
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent dividers className={classes.dialogContent}>
          <div className={classes.modalContainer}>
            {this.renderLogo()}
            <div className={classes.formTop}>
              <Typography className={classes.formTitle}>
                {`${edit ? "Update" : "Create"} Template`}
              </Typography>
            </div>
            <div className={edit ? classes.editForm : classes.form}>
              <div className={classes.formWidth}>
                <FormControl
                  fullWidth
                  className={classes.inputRow}
                  error={!!errors.name}
                >
                  <TextField
                    error={nameError}
                    InputProps={{
                      disableUnderline: true,
                      fullWidth: true,
                      endAdornment: NameIcon && (
                        <InputAdornment position="end">
                          {NameIcon}
                        </InputAdornment>
                      ),
                    }}
                    id="Name"
                    type="Name"
                    value={name}
                    onChange={this.onChange("name")}
                    placeholder="Template Name"
                    helperText={nameMsg}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.inputRow}
                  error={!!errors.description}
                >
                  <TextField
                    error={!!errors.description}
                    InputProps={{
                      disableUnderline: true,
                      fullWidth: true,
                    }}
                    id="description"
                    type="description"
                    value={description}
                    onChange={this.onChange("description")}
                    placeholder="Template description"
                    helperText={errors.description ? errors.description : ""}
                  />
                </FormControl>
                {/*
                <FormControl
                  fullWidth
                  className={classes.inputRow}
                  error={!!errors.templateType}
                >
                  <Select
                    disableUnderline
                    value={templateType}
                    defaultValue={templateType}
                    onChange={this.onChange("templateType")}
                    displayEmpty
                    fullWidth
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        margin: "5px 0",
                      },
                    }}
                  >
                    <MenuItem value="">Template Type</MenuItem>
                    {typeList.map((mItem) => {
                      return (
                        <MenuItem
                          key={`template_type_id_${mItem.id}`}
                          value={mItem.id}
                        >
                          {capitalizeFirstLetter(mItem.name)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {this.renderError("templateType")}
                </FormControl> */}

                {/*
                <FormControl
                  fullWidth
                  className={classes.inputRow}
                  error={!!errors.templateStatus}
                >

                  <Select
                    disableUnderline
                    value={templateStatus}
                    defaultValue={templateStatus}
                    onChange={this.onChange("templateStatus")}
                    displayEmpty
                    fullWidth
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        margin: "5px 0",
                      },
                    }}
                  >
                    <MenuItem value="">Template Status</MenuItem>
                    {statusList.map((mItem) => {
                      return (
                        <MenuItem
                          key={`template_type_id_${mItem.id}`}
                          value={mItem.id}
                        >
                          {capitalizeFirstLetter(mItem.name)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {this.renderError("templateStatus")}
                </FormControl> */}
                <FormControl
                  fullWidth
                  className={classes.switchContainer}
                  error={!!errors.isUpload}
                >
                  <Typography className={classes.switchText}>
                    Select a Project
                  </Typography>
                  <Switch
                    checked={isUpload}
                    onChange={() => {
                      this.setState({ isUpload: !isUpload })
                    }}
                    color="primary"
                    name="isUpload"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography className={classes.switchText}>
                    Upload A Template
                  </Typography>
                </FormControl>
                {!isUpload && (
                  <FormControl
                    fullWidth
                    className={classes.inputRow}
                    error={!!errors.project}
                  >
                    <Autocomplete
                      id="Company-name-autocomplete"
                      options={projectsList.sort(
                        (a, b) => -b.first.localeCompare(a.first)
                      )}
                      groupBy={(option) => option.first}
                      getOptionLabel={(option) => option.project_name || ""}
                      value={project}
                      onChange={this.onChangeAutocomplete("project")}
                      classes={{
                        popper: classes.autocompletePopper,
                        paper: classes.autocompletePaper,
                        option: classes.autocompleteOption,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.project}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            fullWidth: true,
                          }}
                          id="Project-name"
                          type="Name"
                          placeholder="Project Name"
                          helperText={errors.project ? errors.project : ""}
                        />
                      )}
                    />
                    {this.renderError("project")}
                  </FormControl>
                )}

                {isUpload && (
                  <DropzoneArea
                    onChange={this.onChangeFiles}
                    onDrop={this.onChangeFiles}
                    onDelete={this.onFileDelete}
                    filesLimit={1}
                    disabled={loading}
                    key="upload_excel"
                    acceptedFiles={[
                      "application/excel",
                      "application/x-excel",
                      "application/x-msexcel",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                    showPreviews={false}
                    showPreviewsInDropzone
                    dropzoneClass={classes.dropZone}
                    dropzoneParagraphClass={classes.dropZoneText}
                    dropzoneText="Drag and drop here or click to browse files"
                    showFileNamesInPreview
                    showFileNames
                    maxFileSize={maxFileSize}
                  />
                )}
              </div>
              {this.renderError("files")}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            className={classes.footerViewDialog}
          >
            <Button
              variant="text"
              disabled={loading}
              onClick={() => this.handleClose(false)}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={this.handleOk}
              classes={{
                root: classes.okButton,
              }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 20, color: "#FFF" }}
                  color="inherit"
                />
              )}
              <Typography variant="button">
                {`${edit ? "Update" : "Create"} Template`}
              </Typography>
            </Button>
          </Grid>
          <Typography color="error">{tError}</Typography>
        </DialogActions>
      </Dialog>
    )
  }
}

CreateTemplateModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  apiActions: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  setMessage: PropTypes.func,
  setTab: PropTypes.func,
  edit: PropTypes.bool,
  program: PropTypes.objectOf(PropTypes.any),
  programme: PropTypes.objectOf(PropTypes.any),
  programmeActions: PropTypes.objectOf(PropTypes.any),
  template: PropTypes.objectOf(PropTypes.any),
  templateActions: PropTypes.objectOf(PropTypes.any),
}

CreateTemplateModal.defaultProps = {
  classes: {},
  // authActions: {},
  apiActions: {},
  auth: {},
  task: {},
  onClose: () => {},
  setMessage: () => {},
  setTab: () => {},
  edit: false,
  program: {},
  programme: {},
  programmeActions: {},
  template: {},
  templateActions: {},
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  api: state.api,
  task: state.task,
  programme: state.programme,
  template: state.template,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    programmeActions: bindActionCreators(programmeActions, dispatch),
    templateActions: bindActionCreators(templateActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CreateTemplateModal
  )
)
