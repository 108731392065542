import React, { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import withStyles from "@material-ui/styles/withStyles"
import Icon from "@mdi/react"
import {
  mdiFilePdf,
  mdiFileExcel,
  mdiFileWord,
  mdiFileImage,
  mdiFile,
} from "@mdi/js"
import authActions from "../../redux/reducers/auth/actions"
// import apiActions from "../../redux/reducers/api/actions"
// import taskActions from "../../redux/reducers/task/actions"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  fileIcon: {
    marginLeft: "10px",
  },
})

class FileIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // TODO - This should be switched out as document type should be provided by the server rather than by extension
  // best practice and security
  getExtension = () => {
    const { fileData } = this.props
    const { path } = fileData
    if (!_.isString(path)) {
      return ""
    }
    const extension = path.substring(path.lastIndexOf(".") + 1)
    // console.log("getExtension", extension)
    return extension
  }

  getIconComponent = (extension) => {
    let icon = mdiFile
    let iconColor = "rgba(0, 0, 0, 0.35)"
    if (
      extension.toUpperCase() === "JPEG" ||
      extension.toUpperCase() === "JPG" ||
      extension.toUpperCase() === "PNG"
    ) {
      icon = mdiFileImage
      iconColor = "rgba(0, 0, 0, 0.35)"
    } else if (extension.toUpperCase() === "PDF") {
      icon = mdiFilePdf
      iconColor = "#e71a23"
    } else if (
      extension.toUpperCase() === "DOC" ||
      extension.toUpperCase() === "DOCX"
    ) {
      icon = mdiFileWord
      iconColor = "#2b5594"
    } else if (
      extension.toUpperCase() === "XLS" ||
      extension.toUpperCase() === "XLSX"
    ) {
      icon = mdiFileExcel
      iconColor = "#216f43"
    }
    return {
      icon,
      iconColor,
    }
  }

  render() {
    const { classes, size } = this.props
    const extension = this.getExtension()
    const { icon, iconColor } = this.getIconComponent(extension)
    return (
      <Icon
        path={icon}
        size={size}
        color={iconColor}
        className={classes.fileIcon}
      />
    )
  }
}

FileIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // task: PropTypes.objectOf(PropTypes.any),
  // auth: PropTypes.objectOf(PropTypes.any),
  // authActions: PropTypes.objectOf(PropTypes.any),
  // taskActions: PropTypes.objectOf(PropTypes.any),
  // apiActions: PropTypes.objectOf(PropTypes.any),
  fileData: PropTypes.objectOf(PropTypes.any),
  size: PropTypes.number,
}

FileIcon.defaultProps = {
  classes: {},
  // task: {},
  // auth: {},
  // authActions: {},
  // taskActions: {},
  // apiActions: {},
  fileData: {},
  size: 1,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  // task: state.task,
  // api: state.api,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    // taskActions: bindActionCreators(taskActions, dispatch),
    // apiActions: bindActionCreators(apiActions, dispatch),
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FileIcon
  )
)
