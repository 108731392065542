// const drawerWidth = 240;

import { colors } from "@material-ui/core"
import AppColors from "../../config/colors"

const styles = (theme) => {
//   console.log("Error Styles", theme)
  return {
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: colors.common.white,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      //  height: "100vh",
      backgroundColor: AppColors.background,
    },
    logo: {
      width: 138,
      height: 40,
    },
    form: {
      // height: 400,
      // width: 450,
      padding: 50,
    },
    input: {
      background: "#FFF",
      borderWidth: 1,
      borderColor: "#CCC",
      borderStyle: "solid",
      borderRadius: 5,
      height: 30,
    },
    right: {
      float: "right",
      opacity: 0.5,
      color: colors.common.black,
    },
    loginButton: {
      marginTop: 20,
      // marginBottom: 40,
      // height: 46,
    },
    mt10: {
      marginTop: 10,
    },
  }
}

export default styles
