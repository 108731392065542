import React from "react"

const CheckIcon = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "",
  height = "100%",
  viewBox = "0 0 20 20",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      style={{ height: 30, width: 30, ...style }}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      <path
        fill="#436ee0"
        d="M58,48A10,10,0,1,0,68,58,10,10,0,0,0,58,48Zm5.12,7.236-6.428,6.457h0a.868.868,0,0,1-.558.264.842.842,0,0,1-.562-.274L52.875,58.99a.192.192,0,0,1,0-.274l.856-.856a.186.186,0,0,1,.269,0L56.135,60,62,54.087a.19.19,0,0,1,.135-.058.175.175,0,0,1,.135.058l.841.87A.19.19,0,0,1,63.12,55.236Z"
        transform="translate(-48 -48)"
      />
    </svg>
  )
}

export default CheckIcon
