// import { getPusherChannelList } from "../../../common/utils"

const types = {
  SET_DATA: "auth/SET_DATA",
  SET_USER: "auth/SET_USER",
  SET_SCREEN: "auth/SET_SCREEN",
  SET_NETWORK_STATUS: "auth/SET_NETWORK_STATUS",
  SET_DEVICE_INFO: "auth/SET_DEVICE_INFO",
  SET_ROUTE_NAME: "auth/SET_ROUTE_NAME",
  SET_UNREAD_COUNT: "auth/SET_UNREAD_COUNT",
  SET_NOTIFICATION_LIST: "auth/SET_NOTIFICATION_LIST",
  ON_NEW_NOTIFICATION: "auth/ON_NEW_NOTIFICATION",

  setUserData: (token, data) => (dispatch) => {
    let tok = ""
    let udata = {}
    if (token !== undefined && token !== null && token !== "") {
      tok = token
    }
    if (data !== undefined && data !== null && Object.keys(data).length > 0) {
      udata = data
    }
    // console.log("_SETTING_USER_DATA_")
    return dispatch({
      type: types.SET_DATA,
      token: tok,
      userdata: udata,
    })
  },

  setUser: (data) => (dispatch) => {
    // console.log("_SET_USER_")
    dispatch({
      type: types.SET_USER,
      userdata: data,
    })
  },

  setScreen: (screen) => (dispatch) =>
    dispatch({
      type: types.SET_SCREEN,
      screen,
    }),

  setDeviceInfo: (deviceInfo) => (dispatch) =>
    dispatch({
      type: types.SET_DEVICE_INFO,
      deviceInfo,
    }),

  onConnectionChange: (isConnected) => (dispatch) =>
    dispatch({
      type: types.SET_NETWORK_STATUS,
      connected: !!isConnected,
    }),

  setRouteName: (routeName) => (dispatch) =>
    dispatch({
      type: types.SET_ROUTE_NAME,
      routeName,
    }),

  setUnreadCount: (count) => (dispatch) =>
    dispatch({
      type: types.SET_UNREAD_COUNT,
      count,
    }),

  setNotificationList: (payload) => (dispatch) => {
    return dispatch({
      type: types.SET_NOTIFICATION_LIST,
      payload,
    })
  },
  onNewNotification: (item) => (dispatch, getState) => {
    const { notificationList = [] } = getState().auth
    return dispatch({
      type: types.ON_NEW_NOTIFICATION,
      payload: [item].concat(notificationList),
      lastNoti: { ...item },
    })
  },

  // getPusherChannelList: () => (dispatch, getState) => {
  //   const events = getPusherChannelList(getState)
  //   return events
  // },
}

export default types
