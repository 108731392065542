import React, { Component } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/styles/withStyles"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const styles = (theme) => ({
  formControl: {
    "& .MuiSelect-select": {
      "&:focus": {
        background: "none",
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  listItem: {
    padding: 0,
    height: 40,
  },
  popoverPaper: {
    marginTop: "-5px",
    width: 180,
    "& .MuiMenuItem-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-selected": {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#F1F3F9",
      },
    },
  },
})

class SelectComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, getOptionLabel, ...rest } = this.props
    const { label, inputProps } = rest
    const inputP = inputProps || {}
    return (
      <FormControl className={classes.formControl} fullWidth>
        {label && (
          <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
        )}
        <Select
          {...rest}
          input={<Input disableUnderline {...inputP} />}
          MenuProps={{
            PopoverClasses: {
              root: classes.popoverRoot,
              paper: classes.popoverPaper,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          // classes={classes}
        />
      </FormControl>
    )
  }
}

SelectComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
  multiple: PropTypes.bool,
  label: PropTypes.string,
  getOptionLabel: PropTypes.func,
  inputProps: PropTypes.objectOf(PropTypes.any),
}

SelectComponent.defaultProps = {
  classes: {},
  options: [],
  value: "",
  label: "",
  multiple: false,
  getOptionLabel: () => "",
  inputProps: {},
}

export default withStyles(styles)(SelectComponent)
