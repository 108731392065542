import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import Axios from "axios"
import { connect } from "react-redux"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import withStyles from "@material-ui/styles/withStyles"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import AddRounded from "@material-ui/icons/AddRounded"
import MoreHoriz from "@material-ui/icons/MoreHoriz"
import AddRevisionModal from "./AddRevisionModal"
import settings from "../../config/settings"
import LoaderModal from "../LoaderModal/LoaderModal"
import { Tooltip } from "@material-ui/core"
import { getTenantHeaders } from "../../common/utils"

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  container: {},
  button: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  menu: {
    // backgroundColor: "#f5f5f5",
    ".MuiMenuItem-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-selected": {
      borderLeft: "2px solid #436EE0",
      // backgroundColor: "#fff",
      backgroundColor: "#7B87C616",
    },
    "& .MuiListItem-button": {
      borderLeft: "2px solid #0000",
      "&:hover": {
        borderLeft: "2px solid #436EE0",
        backgroundColor: "#7B87C616",
      },
    },
  },
  menuItem: {
    minWidth: 250,
    display: "flex",
    justifyContent: "space-between",
  },
  menuItemIcon: {
    minWidth: "unset",
    marginRight: "15px",
  },
})

class DocumentActions extends Component {
  constructor(props) {
    super(props)
    this.AddRevisionModal = React.createRef()
    this.state = {
      anchorEl: null,
      dsOpen: false,
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.setAnchorEl(event.currentTarget)
  }

  handleClose = () => {
    this.setAnchorEl(null)
  }

  setAnchorEl = (anchorEl) => {
    this.setState({ anchorEl })
  }

  openAddRevisionModal = () => {
    // console.log("openAddRevisionModal", this.AddRevisionModal)
    this.handleClose()
    if (this.AddRevisionModal) {
      this.AddRevisionModal.handleOpen()
    }
  }

  openLoader = () => {
    if (this.LoaderModal) {
      this.LoaderModal.handleOpen()
    }
  }

  closeLoader = () => {
    if (this.LoaderModal) {
      this.LoaderModal.handleClose()
    }
  }

  updateDocumentStatus = (status) => async () => {
    const {
      data,
      setMessage,
      onSuccess,
      auth: { userdata },
    } = this.props
    this.handleClose()
    // url: http://saffron-document-service.test/api/document/revision/status

    // PUT

    /* 
    {
      "document_revision_id": "1",
      "document_status_id": "2"
    }
    */

    // const { data } = this.props
    let revisionId = 0
    // console.log("data.revisions", data.revisions)
    if (_.isArray(data.revisions)) {
      const sObj = _.find(
        data.revisions,
        (i) => i.document_id === data.document_id
      )
      // console.log("sObj", sObj)
      if (sObj) {
        revisionId = sObj.id
      }
    }
    this.openLoader()
    try {
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.put(
        settings.endpoints.updateDocumentStatus,
        {
          document_revision_id: revisionId,
          document_status_id: status,
          request_user_id: userdata.auth_user_id,
        },
        {
          headers: tenantHeaders,
        }
      )
      const responseJson = response.data
      // console.log("updateDocumentStatus", responseJson)
      this.closeLoader()
      if (responseJson) {
        setMessage({
          message: responseJson.message || "",
          open: true,
          type: responseJson.success ? "success" : "error",
        })
        if (responseJson.success) {
          onSuccess()
        }
      } else {
        setMessage({
          message: "Unable to process Due to Network / Server Error",
          type: "error",
          open: true,
        })
      }
    } catch (error) {
      // console.log("updateDocumentStatus", error)
      this.closeLoader()
      setMessage({
        message: "Unable to process Due to Network / Server Error",
        type: "error",
        open: true,
      })
    }
  }

  getDocumentStatusId = () => {
    const { data } = this.props
    let status = 0
    // console.log("data.revisions", data.revisions)
    if (_.isArray(data.revisions)) {
      const sObj = _.find(
        data.revisions,
        (i) => i.document_id === data.document_id
      )
      // console.log("sObj", sObj)
      if (sObj) {
        status = sObj.status_id
      }
    }
    return status
  }

  handleChangeStatus = () => {
    const { dsOpen } = this.state
    this.setState({ dsOpen: !dsOpen })
  }

  renderIcon = () => {
    const { panel, classes, buttonClass } = this.props
    if (panel) {
      return (
        <Tooltip title="Document Actions" aria-label="document-actions">
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            size="medium"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={buttonClass}
            onClick={this.handleClick}
          >
            <MoreHoriz style={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
      )
    }

    return (
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        disableElevation
        className={classes.button}
        onClick={this.handleClick}
      >
        <MoreHoriz fontSize="small" />
      </IconButton>
    )
  }

  render() {
    const { anchorEl, dsOpen } = this.state
    const { classes, data, onSuccess, documentStatusList } = this.props
    // console.log("DActions")
    // console.log('id === "status"', data)
    const documentStatusId = this.getDocumentStatusId()
    return (
      <>
        {this.renderIcon()}
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id={`simple-menu-version-info-${data.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          className={classes.menu}
          onClose={this.handleClose}
        >
          <MenuItem
            key={`add_new_revision_${data.id}`}
            className={classes.menuItem}
            onClick={this.openAddRevisionModal}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AddRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Upload New Version" />
          </MenuItem>

          <MenuItem
            key={`add_new_revision_${data.id}`}
            className={classes.menuItem}
            onClick={this.handleChangeStatus}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              {/* <AddRounded fontSize="small" /> */}
            </ListItemIcon>
            <ListItemText primary="Change Status" />
            {dsOpen ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>

          <Collapse in={dsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {_.isArray(documentStatusList) && documentStatusList.length
                ? documentStatusList.map((dS, index) => {
                    if (dS.id === documentStatusId) {
                      return null
                    }
                    if (dS.id > 4 && documentStatusId < 4) {
                      return null
                    }

                    let label = dS.name
                    if (dS.id === 4) {
                      label = "Sign Off"
                    }
                    return (
                      <MenuItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`document_status_${dS.id}_${index}`}
                        className={classes.menuItem}
                        onClick={this.updateDocumentStatus(dS.id)}
                      >
                        <ListItemText primary={label} />
                      </MenuItem>
                    )
                  })
                : null}
            </List>
          </Collapse>
        </Menu>
        <AddRevisionModal
          key={`add_new_revision_modal_${data.id}`}
          data={data}
          ref={(o) => {
            this.AddRevisionModal = o
          }}
          onSuccess={onSuccess}
        />
        <LoaderModal
          ref={(o) => {
            this.LoaderModal = o
          }}
        />
      </>
    )
  }
}

DocumentActions.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.any),
  onSuccess: PropTypes.func,
  documentStatusList: PropTypes.arrayOf(PropTypes.any),
  setMessage: PropTypes.func,
  buttonClass: PropTypes.string,
  panel: PropTypes.bool,
}

DocumentActions.defaultProps = {
  data: {},
  auth: {},
  classes: {},
  onSuccess: () => {},
  documentStatusList: [],
  setMessage: () => {},
  buttonClass: "",
  panel: false,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  auth: state.auth,
  documentStatusList: state.task.documentStatusList,
})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DocumentActions
  )
)
